import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { UserService } from "src/app/services/user/user.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { MasterTypes } from "src/app-settings/master_types";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EngagementService } from "src/app/services/engagement/engagement.service";
import { NgbActiveModal, NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbDateFRParserFormatter } from "../offer-form/dateformat";
import * as moment from "moment";
import { EngageTempModalComponent } from "../engage-temp-modal/engage-temp-modal.component";
import { EngagementTemplateService } from "../../../services/engagementTemplate/engagement-template.service";
import { StageService } from "../../../services/stage/stage.service";
@Component({
  selector: "app-candidate-engagement-configuration",
  templateUrl: "./candidate-engagement-configuration.component.html",
  styleUrls: ["./candidate-engagement-configuration.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class CandidateEngagementConfigurationComponent
  implements OnInit, OnChanges {
  items;
  public users: any;
  templateStages: any[] = [];
  engamentMasterData: any[] = [];
  engamentForm: FormGroup;
  @Input() public offerData;
  @Input() public action; //action start or edit
  @Output() change = new EventEmitter();
  @Output() backButton = new EventEmitter();
  @Input("templateId") templateId: any;
  engTemplateId: any = "";
  checkedArray: any[] = [];
  restructuredData: any[] = [];
  checkAssignList: any[] = [];
  engagementData: any = [];
  addNewEngagmentData: any;
  actionFromOpeningModal: any;
  addNewEngagmentArray: any[];
  randomCode: string;
  engagementModel = {};
  finalEngagmentData: any;
  startButton: string;
  othersMail: any;

  allusers:any;

  invalidEmail = true;
  submitButtonFlag: boolean;
  templateData: any;
  roles: any = [];
  stages: any[] = [];
  stageMaster: any[] = [];
  constructor(
    public userService: UserService,
    private sharedService: SharedService,
    private engagementService: EngagementService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private templateService: EngagementTemplateService,
    private stageService: StageService
  ) {
    this.addNewEngagmentArray = [];
    this.startButton = "start";
  }

  ngOnInit() { }

  ngOnChanges(changes: any): void {
    console.log(this.offerData)

    if (changes && changes.offerData && changes.offerData.currentValue) {
     this.allusers = changes.offerData
      // console.log('allusers >>',this.allusers);

      this.offerData = changes.offerData.currentValue;
      this.users = Array.from(new Set(this.offerData.usersAssigned));
    }
    if (changes && changes.action && changes.action.currentValue) {
      console.log(this.action)
      this.actionFromOpeningModal = this.action;
      if (this.actionFromOpeningModal == "edit") {
        this.startButton = "update";
        setTimeout(() => {
          this.gettingAlreadyExistingEngagementData();
        }, 500);
      }
    }
    //assigning tempate id that is coming in
    if (changes && changes.templateId && changes.templateId.currentValue) {
      this.engTemplateId = this.templateId;
      if (this.engTemplateId) {
        //getting user data before getting template
        this.userService.getUser((res) => {
          this.getTemplateData();
        })
      }
    }
  }


  // get master data for engagment
  getEngamentMasterData() {
    this.engamentMasterData = this.sharedService.engagementTemplateToMasterMapper(JSON.parse(JSON.stringify(this.templateData.stages)));
    // console.log('engamentMasterData >>', this.templateData)
    //adding extra fields to master data from exusting template
    if (this.actionFromOpeningModal === 'edit') {
      this.engamentMasterData = this.sharedService.remapperForUpdatedDataEngagement(this.engamentMasterData, this.engagementData);
      this.templateData.stages = this.sharedService.mapMasterToStages(JSON.parse(JSON.stringify(this.templateData.stages)), this.engagementData);
    }
    // console.log('data >>', this.engamentMasterData, this.templateData)
    this.createModel();
  }

  //check selected items
  isSelected(itemCode, itemGroup) {
    if (itemCode && this.engagementData.length > 0) {
      let group = this.engagementData.filter(eachEngData => {
        return eachEngData.groupName === itemGroup.code;
      });

      if (group[0]) {
        let item = group[0].items.filter(eachItem => {
          return eachItem.code === itemCode;
        });

        if (item[0]) {
          return item[0].applicable;
        } else {
          return false;
        }
      }
    }
  }

  createModel() {
    console.log(this.engamentMasterData);
    this.engamentMasterData.forEach(eachGroup => {
      if (!this.engagementModel[eachGroup.code]) {
        this.engagementModel[eachGroup.code] = {};
      }
      eachGroup.data.items.forEach(eachItem => {
        // console.log(eachItem)
        // when creating the engagement for the first time the rules will apply
        let ruleData = {} as any;
        if (this.actionFromOpeningModal != "edit") {
          if (eachItem.rules){
            ruleData = this.sharedService.ruleGeneratorForEngagement(eachItem.rules[0], this.offerData);
          }
        }
        // if no object exists then only create new otherwise leave the old one
        // console.log('eachItem >>',eachItem);

        if (!this.engagementModel[eachGroup.code][eachItem.code]) {
          var itemObj = {
            applicable: false,
            assignedTo: ruleData.user ? ruleData.user : "",
            completed: false,
            other: false,
            otherEmail: "",
            code: eachItem.code,
            taskName: eachItem.title,
            activityType: eachItem.activityType,
            interactionType: eachItem.interactionType,
            description : eachItem.description,

            date: ruleData.date ? ruleData.date : null,
            time: ruleData.time ? ruleData.time : ""

          };
          // console.log('itemObj >>',itemObj);
          //for create, set applicable to true
          if (this.actionFromOpeningModal != 'edit') {
            itemObj.applicable = true;
          }
          this.engagementModel[eachGroup.code][eachItem.code] = itemObj;
        }

      });
    });

  }

  updateEngagementModel() {
    // console.log(this.engagementData, this.engagementModel);
    this.engagementData.forEach(eachGroup => {
      eachGroup.items.forEach(eachItem => {
        // console.log(eachItem)
        eachItem.date = moment(eachItem.date).toISOString()
        eachItem.date = this.sharedService.convertDateStringToObject(eachItem.date);
        if (this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem) {
          this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem;
        }
        else {
          this.engagementModel[eachGroup.groupName] = {};
          this.engagementModel[eachGroup.groupName][eachItem.code] = this.createSingleModelForExtraData();
          this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem;
        }
      });
    });
  }

  // extra data creaatino onn engagement template
  createSingleModelForExtraData() {
    var itemObj = {
      applicable: false,
      assignedTo: "",
      completed: false,
      other: false,
      otherEmail: "",
      code: "",
      taskName: "",
      date: null,
      time: ""
    };
    return itemObj;
  }

  checkOthers(event, groupCode, itemCode) {
    if (event === "other") {
      this.engagementModel[groupCode][itemCode].other = true;
      this.submitButtonFlag = true;
    } else {
      this.submitButtonFlag = false;
      this.engagementModel[groupCode][itemCode].other = false;
      this.engagementModel[groupCode][itemCode].otherEmail = "";
    }
  }

  // mapping the data according to formate
  getUpdateEngagamentData() {
    let finalArr = [];
    // tslint:disable-next-line: forin
    // console.log(this.engagementModel)
    for (var key in this.engagementModel) {
      console.log(this.engagementModel)
      let arr = {
        groupName: key,
        items: []
      };
      if (this.engagementModel.hasOwnProperty(key)) {
        const tempD = this.engagementModel[key];
        for (var keys in tempD) {
          if (tempD.hasOwnProperty(keys)) {
            arr.items.push(tempD[keys]);
          }
        }
        finalArr.push(arr);
      }
      this.finalEngagmentData = finalArr;
      console.log(this.finalEngagmentData)
    }
  }

  checkEmail(email, groupCode, itemCode) {
    const isValid = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
      email
    );
    if (isValid === true) {
      this.submitButtonFlag = false;
    } else {
      this.submitButtonFlag = true;
    }
  }

  //getting already existing engagement data
  gettingAlreadyExistingEngagementData() {
    // console.log(this.offerData)
    this.engagementService.getEngagementData(this.offerData.offerId).subscribe(
      res => {
        this.templateId = res.body.templateId; //added later
        //   code for new template based data merge
        // console.log(this.userService, res)
        this.userService.getUser(() => {
          this.engagementData = res.body.engagementData;
          this.getTemplateData(() => {
            this.getStagesMaster(() => {
              this.updateEngagementModel();
            })
          })
        })
      },
      error => {
        this.toaster.error("Something went wrong");
      }
    );
  }
  _keyDate(event: any) {
    const pattern = /[0-9-]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
    else {
      return false;
    }
  }

  //
  selectDate(date, groupCode, itemCode) {
    if (date) {
      this.engagementModel[groupCode][itemCode].time = "11:00";
    }
  }
  // start engagement submit function
  submit() {
    this.getUpdateEngagamentData();
    const finalEnagementData = [];
    // console.log(this.finalEngagmentData)
    this.finalEngagmentData.forEach(eachGroup => {
      let arr = [];
      eachGroup.items.forEach(eachItem => {
        if (eachItem.applicable === true) {
          if (eachItem.otherEmail) {
            eachItem.assignedTo = eachItem.otherEmail;
            arr.push(eachItem);
          } else {
            arr.push(eachItem);
          }
        }
      });

      if (arr.length > 0) {
        finalEnagementData.push({
          groupName: eachGroup.groupName,
          items: arr
        });
      }
      console.log(arr)
    });
    // validate everything
    if (this.validateEverything()) {
      const tempStartEngagementData = JSON.parse(JSON.stringify(finalEnagementData));
      console.log('tempStartEngagementData >>>',tempStartEngagementData)
      tempStartEngagementData.forEach((eachItem: any) => {
        eachItem.items.forEach((eachElement) => {
          var dateFormatConversion = this.sharedService.convertObjectToDateString(eachElement.date);
          dateFormatConversion = moment(dateFormatConversion).format("YYYY-MM-DD")
          eachElement.date = dateFormatConversion
        });
      })
      const name = this.offerData.candidateData.firstName + " " + this.offerData.candidateData.lastName;

      const startEngagement = {
        offerId: this.offerData.offerId,
        templateId: this.templateId,//added later
        candidateName: name,
        updatedAt: new Date().getTime(),
        imageLink: this.offerData.candidateData.profileImage,
        engagementData: tempStartEngagementData
      };

      console.log(startEngagement)
      if (this.actionFromOpeningModal == "edit") {
        const updateEngagment = {
          engagementData: tempStartEngagementData
        };
        this.loader.start();
        this.engagementService
          .updateEngMeta(this.offerData.offerId, name, updateEngagment)
          .subscribe(
            res => {
              this.loader.stop();
              this.change.emit(res);
              this.activeModal.close("Cross click");
              this.toaster.success("Engagement task updated sucessfully");
            },
            error => {
              this.loader.stop();
              this.toaster.error("something went wrong.");
            }
          );
      } else {
        this.loader.start();
        this.engagementService.startEngagement(startEngagement).subscribe(
          res => {
            this.activeModal.close("Cross click");
            this.toaster.success("success");
            this.loader.stop();
          },
          err => {
            this.loader.stop();
            this.toaster.error("something went wrong.");
          }
        );
      }
    }
  }

  // go back to templates
  goBackToTemplates() {
    this.backButton.emit(this.templateId);
  }

  //get template
  getTemplateData(callback?) {
    console.log(this.templateId)
    const payload = { id: this.templateId }
    this.templateService.getTemplate(payload).subscribe((res) => {
      console.log('res >>>',res.body);

      // console.log('stagesAndActivites >>>',res.body.stagesAndActivites);

      this.templateData = res.body.template;
      this.stages = JSON.parse(JSON.stringify(res.body.template.stages))
      this.combineData(res.body);
      this.getEngamentMasterData();
      if (callback) {
        callback();
      }
    }, error => {
      this.toaster.error("Something went wrong while getting the template")
    })
  }

  // combine template data on arrival
  combineData(data) {
    if (this.templateData.stages && this.templateData.stages.length > 0) {
      this.templateData.stages.forEach((stage => {
        data.stagesAndActivites.forEach((stAc) => {
          if (stage.id === stAc._id) {
            stage._data = stAc;
          }
          stage.activities.forEach((activity) => {
            if (activity.id === stAc._id) {
              activity._data = stAc;
            }
          });
        });
      }));
    }
  }

  //validate on create and update
  private validateEverything() {
    let problem = false;
    let applicableCount = 0;
    // console.log(this.engagementModel)
    let _engagementModel = JSON.parse(JSON.stringify(this.engagementModel))
    // tslint:disable-next-line: forin
    for (let key in _engagementModel) {
      this.templateData.stages.forEach((stage) => {
        if (stage.code === key) {
          _engagementModel[key]["name"] = stage._data.name;
        }
      })
      // tslint:disable-next-line: forin
      for (let k in _engagementModel[key]) {
        if (_engagementModel[key][k] && _engagementModel[key][k].applicable) {
          applicableCount++;
          for (let _key in _engagementModel[key][k]) {
            if (_key == "assignedTo" || _key === "date" || _key === "time") {
              if (_engagementModel[key][k][_key] == "" || _engagementModel[key][k][_key] == null) {
                problem = true;
                this.toaster.error(`Missing "${_key}" in activity "${_engagementModel[key][k]["taskName"]}" at stage "${_engagementModel[key]["name"]}"`)
              }
            }
          }
        }
      }
    }
    if (problem) { return false }
    else if (applicableCount == 0) {
      this.toaster.error("Select atleast one activity");
      return false;
    }
    else return true;
  }


  // go to acitivity modal
  openActivityModal(stageCode) {
    const modalRef = this.modalService.open(EngageTempModalComponent, {
      windowClass: "engage-edit-modal",
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.type = "activity";
    let stage = null;
    // console.log(this.templateData)
    this.templateData.stages.forEach((_stage) => {
      if (_stage.code === stageCode) {
        stage = _stage
      }
    })
    // console.log(stage)
    modalRef.componentInstance.activityData = stage.activities;
    // console.log(modalRef.componentInstance.activityData);
    modalRef.result.then((_activities) => {
      // console.log(_activities)
      this.remapDataToStages("activity", _activities, stageCode);
      // get engagement data again stages  to refresh stages
      this.getEngamentMasterData()
    }).catch((err) => { console.log("NO_ACTION"); });
  }

  openStagesModal() {
    const modalRef = this.modalService.open(EngageTempModalComponent, {
      windowClass: "engage-edit-modal",
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.type = "stage";
    modalRef.componentInstance.engagementData = this.templateData.stages;
    modalRef.result.then((_stages) => {
      this.remapDataToStages("stage", _stages);
      // get engagement data again to refresh stages
      this.getEngamentMasterData();
    }).catch((err) => { console.log("NO_ACTION"); });
  }


  private remapDataToStages(type, data, code?) {
    // console.log(this.templateData.stages)
    if (type === "stage") {
      this.stages = data;
      this.templateData.stages = data
    }
    else {
      this.templateData.stages.forEach((_stage) => {
        // console.log(_stage)
        if (_stage.code === code) {
          _stage.activities = data;
        }
      });
      this.stages.forEach((_stage) => {
        if (_stage.code === code) {
          _stage.activities = data;
        }
      });
    }
  }

  //get stages master
  private getStagesMaster(callback?) {
    const payload = { type: 'stage' };
    this.stageService.getStages(payload).subscribe((res) => {
      // console.log(res)
      this.stageMaster = res.body;
      if (callback) { callback() }
    }, err => {
      this.toaster.error("Error getting stages")
    })
  }

  // getting names for stages and tasks
  public getNamesFromCodesForStagesAndTasks(code) {
    let nameToReturn = "";
    this.stageMaster.forEach((stage) => {
      if (stage.code === code) {
        nameToReturn = stage.name;
      }
    })
    return nameToReturn;
  }

}
