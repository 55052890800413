import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EngagementTemplateService {
  apiName: string;
  path: string;
  orgHandle: string;

  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.template;
    this.orgHandle = localStorage.getItem("orgHandle");
  }


  // creating template
  public createTemplate(body) {
    const request = this.initRequests.initCreateEngagementTemplate({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // updating template
  public updateTemplate(body) {
    const request = this.initRequests.initEngagementTemplateUpdate({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // get template
  public getTemplate(body) {
    const request = this.initRequests.initEngagementTemplateGet({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // get all template
  public getAllTemplates() {
    const request = this.initRequests.initEngagementTemplateGet({
      _action: "GET_ALL",
      orgHandle: this.orgHandle,
      queryBody: {}
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
}
