import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { OfferService } from 'src/app/services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GenerateOfferServiceService } from '../generate-offers/generate-offer-service/generate-offer-service.service';
import { UserService } from 'src/app/services/user/user.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { tick } from '@angular/core/src/render3';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-recruiter-offers',
  templateUrl: './recruiter-offers.component.html',
  styleUrls: ['./recruiter-offers.component.scss']
})
export class RecruiterOffersComponent implements OnInit, OnDestroy {
  offerData: any;
  sub: any;
  offerId: any;
  offerForm: FormGroup;
  masters: any;
  users: any;
  formData: any;
  updateMailData: any;
  updateOfferDeatils: any;
  s3fileData: any;
  updateOfferPayload: any = {
    candidateData: {
      id: '',
      emailId: '',
      firstName: '',
      lastName: '',
      profileImage: '',
      mobile: '',
      skills: '',
      nameAsInPassport: '',
      resumeSource: '',
      title: '',
      dob: ''
    },
    offerMetaData: {
      title: '',
      noticePeriod: '',
      date: '',
      joiningDate: '',
      offerDate: '',
      expiryDate: '',
      designation: '',
      salary: '',
      currency: '',
      campusHire: '',
      activity: [
        {
          statusCode: '',
          date: ''
        }
      ],
      status: []
    },
    file: {
      URL: ''
    },
    downloadConfig: {
      status: ''
    },
    criDisclaimer: '',
    organizationData: {
      businessUnit: '',
      division: '',
      department: '',
      hiringManager: '',
      recruiter: '',
      reportingManager: '',
      taManager: '',
      hrManager: '',
      location: ''
    },
    emailTemplate: {
      mailSubject: '',
      body: '',
      otherAttachment: '',
      offerLetterAttached: ''
    },
    revisedOfferTemplate: {
      mailSubject: '',
      body: '',
      otherAttachment: '',
      offerLetterAttached: ''
    },
    createdAt: '',
    updatedAt: ''
  };

  isRoleBased:boolean=false;
  fileUrl: any;
  offerSubmittedState = false;
  emailTempData: any;
  businessUnit: any;
  getOfferStatus: any;
  candidateId: any;
  updateOfferStatus: any;
  disablePage: any;
  offerDataObj: any;
  subscription: Subscription;
  getRevisedMailData: any;
  getRevisedMailSubject: any;
  msgNotificationFlag: any;
  sentEmailNotify: boolean;
  constructor(
    private location: Location,
    private offerService: OfferService,
    private userService: UserService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private localService: GenerateOfferServiceService,
    public shared: SharedService,
    private toaster: ToastrService,
    private router: Router,
    private loader: NgxUiLoaderService,
    private auth: AuthService
  ) {
    this.disablePage = false;
  }

  ngOnInit() {
    
    if(this.auth.getAuthRole() === 'DEV'){
      this.isRoleBased = true;
    } else {
      this.isRoleBased = false;
    }
    
    console.log(this.auth.getAuthRole())
    // to get project id from route
    this.sub = this.route.params.subscribe(params => {
      this.offerId = params.id;
    });
    this.getOfferDetailsByOferID();
    this.updateOfferForm();
    this.getMasters();
    this.getUsers();
    // this.getMailTemplate();
    //handling role
    this.getOfferData();
  }

 
  //handle role statements
  handleRole() {
    this.disablePage = this.auth.roleViewMapping(
      this.auth.getAuthRole(),
      this.router.url.substring(1)
      
    ); //skipping first /
    console.log('disablePage >>',this.disablePage)
  }

  // init update form
  updateOfferForm() {
    this.offerForm = this.fb.group({
      detailsForm: this.fb.group({
        id: [null],
        firstName: [
          {
            value: '',
            disabled: false
          },
          Validators.required
        ],
        lastName: [
          {
            value: '',
            disabled: false
          }
        ],
        email: [
          {
            value: '',
            disabled: false
          },
          [Validators.required, Validators.email]
        ],
        dob: [null], // not mandatory
        profileImage: [''],
        contactNumber: [
          {
            value: '',
            disabled: false
          },
          Validators.compose([
            Validators.minLength(13),
            Validators.pattern('^([+][9][1])([0-9]{1})([0-9]{9})$')
            // Validators.pattern("^([+][9][1])([7-9]{1})([0-9]{9})$")
          ])
        ],
        candidateTitle: [
          {
            value: '',
            disabled: false
          }
        ],
        offerDesignation: [''], // not mandatory
        businessUnit: [''],
        division: [''],
        department: [''],
        noticePeriod: [''], // not mandatory
        currency: [''],
        salary: [''],
        joiningDate: [null, Validators.required],
        offerDate: [null, Validators.required],
        location: [''], // not mandatory
        reportingManager: [''],
        hrManager: [''],
        taManager: [''],
        recruiter: ['', Validators.required],
        hiringManager: [''],
        offerStatus: [
          {
            value: '',
            disabled: false
          },
          Validators.required
        ],
        offerCreationDate: [
          {
            value: '',
            disabled: false
          },
          Validators.required
        ],
        offerDocument: [''], // not mandatory
        downloadConfig: [''],
        criDisclaimer: [''],
        campusHire: [''],
        expiryDate: [null],
        skills: [''],
        nameAsInPassport: [''],// not mandatory
        resumeSource: ['']
      }),
      mailTemplateForm: this.fb.group({
        subject: ['', Validators.required],
        content: ['', Validators.required],
        attachement: [''],
        attachedToMail: ['']
      })
    });
  }

  backgenerate() {
    this.location.back();
  }
  // GET ENGAGEMENT DATA FOR OFFER
  getOfferDetailsByOferID() {
    let payload = {
      offerId: this.offerId
    };
    this.loader.start();
    this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
      res => {
        if (res.body) {
          this.offerData = res.body;
          if (this.offerData.revisedOfferTemplate === undefined) {
            this.getMailTemplate();
          } else {
            this.offerForm.get('mailTemplateForm').patchValue({
              subject: this.offerData.revisedOfferTemplate.mailSubject,
              content: this.offerData.revisedOfferTemplate.templateBody,
              attachement: '',
              attachedToMail: ''
            });
          }
          this.loader.stop();
          this.shared.setOfferFormData(this.offerData);
          this.offerForm.get('detailsForm').patchValue({
            id: this.offerData.candidateData.id,
            firstName: this.offerData.candidateData.firstName,
            lastName: this.offerData.candidateData.lastName,
            email: this.offerData.candidateData.emailId,
            dob: this.shared.convertDateStringToObject(
              this.offerData.candidateData.dob
            ),
            contactNumber: this.offerData.candidateData.mobile,
            nameAsInPassport: this.offerData.candidateData.nameAsInPassport,
            resumeSource: this.offerData.candidateData.resumeSource,
            skills: this.offerData.candidateData.skills,
            candidateTitle: this.offerData.offerMetaData.title,
            offerDesignation: this.offerData.offerMetaData.designation,
            businessUnit: this.offerData.organizationData.businessUnit,
            division: this.offerData.organizationData.division,
            profileImage: this.offerData.candidateData.profileImage,
            department: this.offerData.organizationData.department,
            noticePeriod: this.offerData.offerMetaData.noticePeriod,
            currency: this.offerData.offerMetaData.currency,
            salary: this.offerData.offerMetaData.salary,
            joiningDate: this.shared.convertDateStringToObject(
              this.offerData.offerMetaData.joiningDate
            ),
            offerDate: this.shared.convertDateStringToObject(
              this.offerData.offerMetaData.offerDate
            ),
            expiryDate: this.shared.convertDateStringToObject(
              this.offerData.offerMetaData.expiryDate
            ),
            location: this.offerData.organizationData.location,
            reportingManager: this.offerData.organizationData.reportingManager,
            taManager: this.offerData.organizationData.taManager, //new
            hrManager: this.offerData.organizationData.hrManager, //new
            recruiter: this.offerData.organizationData.recruiter,
            hiringManager: this.offerData.organizationData.hiringManager,
            offerStatus: this.offerData.collectiveStatus[0].statusCode,
            offerCreationDate: this.shared.convertDateStringToObject(
              this.offerData.offerMetaData.date
            ),
            offerDocument: (this.offerData.file && this.offerData.file.URL) ? this.offerData.file.URL : "",
            downloadConfig: this.offerData.downloadConfig.status,
            campusHire: this.offerData.offerMetaData.campusHire
          });
          this.candidateId = this.offerData.candidateData.id;
          this.s3fileData = this.offerForm
            .get('detailsForm')
            .get('offerDocument').value;
          const businessUnit = this.offerData.organizationData.businessUnit;
          const divisionData = this.offerData.organizationData.division;
          this.businessUnit = {
            businessUnit: businessUnit,
            divisionData: divisionData
          };
          this.updateOfferStatus = {
            campusHire: this.offerData.offerMetaData.campusHire,
            downloadConfig: this.offerData.downloadConfig.status,
            criDisclaimer: this.offerData.criDisclaimer
          };
          this.checkStatusToDisablePage();
          this.handleRole();
        }
      },
      err => {
        this.loader.stop();
        this.toaster.error('something went wrong.');
      }
    );
  }
  //
  getOfferChangeStatus(event) {
    this.shared.setOfferFormData(this.offerForm.get('detailsForm').value);
  }

  // getting mail template for preloading the offer mail template
  getMailTemplate() {
    this.localService.getRevisedOfferTemplate(res => {
      this.getRevisedMailData = res.templateBody;
      this.getRevisedMailSubject = res.mailSubject;
      this.offerForm.get('mailTemplateForm').patchValue({
        subject: res.mailSubject,
        content: res.templateBody,
        attachement: '',
        attachedToMail: ''
      });
      this.formData = this.offerForm.get('mailTemplateForm').value;
    });
  }

  //check for statuses  to disable page

  checkStatusToDisablePage() {
    let offerFlag = this.offerData.offerFlag ? this.offerData.offerFlag : null;
    if (offerFlag) {
      this.disablePage = true;
    } else {
      this.disablePage = false;
    }
  }

  //close offer

  closeOffer() {
    const payload = {
      offerFlag: true // offer flag if true means offer closed else it means offer is inactive
    };
    this.offerService.offerMetaUpdate(this.offerId, payload).subscribe(
      res => {
        this.toaster.success('Offer closed');
        this.router.navigate(['offers']);
      },
      error => {
        this.toaster.error('Something went wrong');
      }
    );
  }

  // getting services
  getMasters() {
    this.localService.getMasters(res => {
      this.masters = res;
    });
  }

  // get users for masters in gen offer
  getUsers() {
    this.userService.getUser(res => {
      this.users = res;
    });
  }
  // get candidate data by candidate id
  getCandidateData(id) {
    let payload = {
      candidateId: id
    };
    this.offerService.getCandidate(payload).subscribe(res => {
      if (res.body) {
        const candidateDetails = res.body;
        this.offerForm.get('detailsForm').patchValue({
          id: candidateDetails.candidateId,
          firstName: candidateDetails.personalInfo.firstName,
          lastName: candidateDetails.personalInfo.lastName,
          email: candidateDetails.email,
          dob: candidateDetails.personalInfo.dob,
          contactNumber: candidateDetails.contactInfo.mobile
        });
      }
    });
  }

  // get updated form value
  // submiUpdateOffer(notify) {
  //   this.offerSubmittedState = true;
  //   if (this.offerForm.get('detailsForm').valid) {
  //     if (notify === true) {
  //       this.sentEmailNotify = true;
  //       if (this.updateMailData != null) {
  //         this.emailTempData = this.updateMailData;
  //         if (this.emailTempData) {
  //           this.uploadUpateOfferFiles('callback', this.sentEmailNotify);
  //         }
  //       } else {
  //         this.toaster.error('Please add & preview Email body ');
  //         this.loader.stop();
  //       }
  //     } else {
  //       this.sentEmailNotify = false;
  //       this.getMailDataOnUpdate();
  //     }
  //   } else {
  //     this.toaster.error('something went wrong.');
  //   }
  // }

  //Nikhil Code
  submiUpdateOffer(notify) {
    this.offerSubmittedState = true;
    if (this.offerForm.get('detailsForm').valid) {
      if (notify === false) {
        this.sentEmailNotify = true;
        // if (this.updateMailData != null) {
        //   this.emailTempData = this.updateMailData;
        //   if (this.emailTempData) {
        //     this.uploadUpateOfferFiles('callback', this.sentEmailNotify);
        //   }
        // } else {
        //   this.toaster.error('Please add & preview Email body ');
        //   this.loader.stop();
        // }
        this.uploadUpateOfferFiles('callback', this.sentEmailNotify);
      } else {
        this.sentEmailNotify = false;
        this.getMailDataOnUpdate();
      }
    } else {
      this.toaster.error('something went wrong.');
    }
  }

  // on update offer only
  getMailDataOnUpdate() {
    // this.getOfferData();
    this.replaceMailTempText();
  }
  // get offer data objects for replace the mail templ
  getOfferData() {
    this.subscription = this.shared
      .getofferFormData()
      .subscribe((offerDataObj: any) => {
        this.offerDataObj = offerDataObj;
      });
  }

  // replace the mail template
  replaceMailTempText() {
    if (this.getRevisedMailData) {
      for (var key in this.offerDataObj) {
        if (this.offerDataObj.hasOwnProperty(key)) {
          let value = this.offerDataObj[key];
          let replacekey = '%' + key + '%';
          let updatedTemplate = this.getRevisedMailData.replace(
            new RegExp(this.escapeRegExp(replacekey), 'g'),
            value
          );
          this.getRevisedMailData = updatedTemplate;
        }
      }
    }
    if (this.getRevisedMailData) {
      this.emailTempData = {
        subject: this.getRevisedMailSubject,
        content: this.getRevisedMailData,
        attachement: '',
        attachedToMail: ''
      };
      this.uploadUpateOfferFiles('callback', this.sentEmailNotify);
    }
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  // updated  file  upload function
  // uploadUpateOfferFiles(callback, notify) {
  //   this.loader.start();
  //   if ((this.offerData.file && this.offerData.file.URL) && this.offerData.file.URL ===
  //     this.offerForm.get('detailsForm').get('offerDocument').value
  //   ) {
  //     this.fileUrl = this.offerForm
  //       .get('detailsForm')
  //       .get('offerDocument').value;
  //     this.updateOfferFunction('res', callback, notify);
  //   } else if ((this.offerData.file && !this.offerData.file.URL) && this.offerForm.get('detailsForm').get('offerDocument').value) {
  //     let offerFileData = this.offerForm.get('detailsForm').get('offerDocument')
  //       .value;
  //     let uploadFilepayload = {
  //       fileData: offerFileData[0].fileData,
  //       folder: offerFileData[0].folder,
  //       dataType: offerFileData[0].dataType,
  //       fileFormat: offerFileData[0].fileFormat
  //     };
  //     this.offerService.uploadFile(uploadFilepayload).subscribe(
  //       res => {
  //         if (res) {
  //           this.fileUrl = res.fileLink;
  //           this.toaster.success('file uploaded successfully');
  //           this.loader.stop();
  //           this.updateOfferFunction(res, callback, notify);
  //         }
  //       },
  //       err => {
  //         this.loader.stop();
  //         this.toaster.error('something went wrong');
  //       }
  //     );
  //   }
  //   else {
  //     this.updateOfferFunction('res', callback, notify);
  //   }
  // }

  //Nikhil Code
  // updated  file  upload function
  uploadUpateOfferFiles(callback, notify) {
    this.loader.start();
    if ((this.offerData.file && this.offerData.file.URL) && this.offerData.file.URL === this.offerForm.get('detailsForm').get('offerDocument').value) {
      console.log('great1')
      this.fileUrl = this.offerForm.get('detailsForm').get('offerDocument').value;
      this.updateOfferFunction('res', callback, notify);
    } else if ((this.offerData.file && !this.offerData.file.URL) && this.offerForm.get('detailsForm').get('offerDocument').value) {
      console.log('great2')
      let offerFileData = this.offerForm.get('detailsForm').get('offerDocument').value;
      let uploadFilepayload = {
        fileData: offerFileData[0].fileData,
        folder: offerFileData[0].folder,
        dataType: offerFileData[0].dataType,
        fileFormat: offerFileData[0].fileFormat
      };
      this.offerService.uploadFile(uploadFilepayload).subscribe(
        res => {
          if (res) {
            this.fileUrl = res.fileLink;
            this.toaster.success('file uploaded successfully');
            this.loader.stop();
            this.updateOfferFunction(res, callback, notify);
          }
        },
        err => {
          this.loader.stop();
          this.toaster.error('something went wrong');
        }
      );
    }
    else {
      console.log('great3')
      this.updateOfferFunction('res', callback, notify);
    }
  }
  // get updated offerstatus

  getUpdateOfferStatus(value) {
    this.getOfferStatus = value;
  }
  // call the gererate offer function
  updateOfferFunction(data, callback, notify) {
    this.loader.start();
    this.updateOfferPayload.candidateData.id = this.offerForm
      .get('detailsForm')
      .get('id').value;
    this.updateOfferPayload.candidateData.title = this.offerForm
      .get('detailsForm')
      .get('candidateTitle').value;
    this.updateOfferPayload.candidateData.mobile = this.offerForm
      .get('detailsForm')
      .get('contactNumber').value;
    this.updateOfferPayload.candidateData.nameAsInPassport = this.offerForm
      .get('detailsForm')
      .get('nameAsInPassport').value;
    this.updateOfferPayload.candidateData.resumeSource = this.offerForm
      .get('detailsForm')
      .get('resumeSource').value;
    this.updateOfferPayload.candidateData.dob = this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('dob').value
    );
    this.updateOfferPayload.candidateData.skills = this.offerForm
      .get('detailsForm')
      .get('skills').value;
    this.updateOfferPayload.candidateData.emailId = this.offerForm
      .get('detailsForm')
      .get('email').value;
    this.updateOfferPayload.candidateData.profileImage = this.offerForm
      .get('detailsForm')
      .get('profileImage').value;
    this.updateOfferPayload.candidateData.firstName = this.offerForm
      .get('detailsForm')
      .get('firstName').value;
    this.updateOfferPayload.candidateData.lastName = this.offerForm
      .get('detailsForm')
      .get('lastName').value;
    this.updateOfferPayload.offerMetaData.title = this.offerForm
      .get('detailsForm')
      .get('candidateTitle').value;
    this.updateOfferPayload.offerMetaData.noticePeriod = this.offerForm
      .get('detailsForm')
      .get('noticePeriod').value;
    this.updateOfferPayload.offerMetaData.date = this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('offerCreationDate').value
    );
    this.updateOfferPayload.offerMetaData.joiningDate = this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('joiningDate').value
    );
    this.updateOfferPayload.offerMetaData.offerDate = this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('offerDate').value
    );
    this.updateOfferPayload.offerMetaData.expiryDate = this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('expiryDate').value
    );

    this.updateOfferPayload.offerMetaData.designation = this.offerForm
      .get('detailsForm')
      .get('offerDesignation').value;
    this.updateOfferPayload.offerMetaData.salary = this.offerForm
      .get('detailsForm')
      .get('salary').value;
    this.updateOfferPayload.offerMetaData.currency = this.offerForm
      .get('detailsForm')
      .get('currency').value;
    if (
      this.offerForm.get('detailsForm').get('campusHire').value ===
      this.offerData.offerMetaData.campusHire
    ) {
      this.updateOfferPayload.offerMetaData.campusHire = this.offerData.offerMetaData.campusHire;
    } else {
      this.updateOfferPayload.offerMetaData.campusHire = this.offerForm
        .get('detailsForm')
        .get('campusHire').value;
    }
    const tempDate = new Date();
    const date = tempDate.toISOString();
    const getOfferStatus = this.getOfferStatus
      ? this.getOfferStatus
      : this.offerData.offerMetaData.activity[0].statusCode;
    if (
      getOfferStatus !== this.offerData.offerMetaData.activity[0].statusCode
    ) {
      this.offerData.offerMetaData.activity.push({
        statusCode: getOfferStatus,
        date: date
      });
    }
    this.updateOfferPayload.offerMetaData.activity = this.offerData.offerMetaData.activity;
    this.updateOfferPayload.file.URL = this.fileUrl;
    if (
      this.offerForm.get('detailsForm').get('downloadConfig').value ===
      this.offerData.downloadConfig.status
    ) {
      this.updateOfferPayload.downloadConfig.status = this.offerData.downloadConfig.status;
    } else {
      this.updateOfferPayload.downloadConfig.status = this.offerForm
        .get('detailsForm')
        .get('downloadConfig').value;
    }
    if (
      this.offerForm.get('detailsForm').get('criDisclaimer').value ===
      this.offerData.criDisclaimer
    ) {
      this.updateOfferPayload.criDisclaimer = this.offerData.criDisclaimer;
    } else {
      this.updateOfferPayload.criDisclaimer = this.offerForm
        .get('detailsForm')
        .get('criDisclaimer').value;
    }
    this.updateOfferPayload.organizationData.businessUnit = this.offerForm
      .get('detailsForm')
      .get('businessUnit').value;
    this.updateOfferPayload.organizationData.division = this.offerForm
      .get('detailsForm')
      .get('division').value;
    this.updateOfferPayload.organizationData.department = this.offerForm
      .get('detailsForm')
      .get('department').value;
    this.updateOfferPayload.organizationData.hiringManager = this.offerForm
      .get('detailsForm')
      .get('hiringManager').value;
    this.updateOfferPayload.organizationData.recruiter = this.offerForm
      .get('detailsForm')
      .get('recruiter').value;
    this.updateOfferPayload.organizationData.reportingManager = this.offerForm
      .get('detailsForm')
      .get('reportingManager').value;
    this.updateOfferPayload.organizationData.taManager = this.offerForm
      .get('detailsForm')
      .get('taManager').value;
    this.updateOfferPayload.organizationData.hrManager = this.offerForm
      .get('detailsForm')
      .get('hrManager').value;
    this.updateOfferPayload.organizationData.location = this.offerForm
      .get('detailsForm')
      .get('location').value;

    // this.updateOfferPayload.revisedOfferTemplate.mailSubject = this.emailTempData.subject;
    // this.updateOfferPayload.revisedOfferTemplate.body = this.emailTempData.content;
    // this.updateOfferPayload.revisedOfferTemplate.otherAttachment = this.emailTempData.attachement;
    // this.updateOfferPayload.revisedOfferTemplate.offerLetterAttached = this.emailTempData.attachedToMail;

    // this.updateOfferPayload.emailTemplate.mailSubject = this.offerData.emailTemplate.mailSubject;
    // this.updateOfferPayload.emailTemplate.body = this.offerData.emailTemplate.body;
    this.updateOfferPayload.emailTemplate.otherAttachment = '';
    this.updateOfferPayload.emailTemplate.offerLetterAttached = '';
    this.updateOfferPayload.createdAt = this.offerData.createdAt;
    this.updateOfferPayload.updatedAt = new Date().getTime();
    let payload = {
      offerId: this.offerId,
      data: this.updateOfferPayload
    };
    this.offerService.updateOffer(payload, notify).subscribe(
      res => {
        if (res) {
          this.toaster.success('offer updated successfully');
          this.router.navigate(['offers']);
          this.loader.stop();
          // this.sendOffer(this.offerId, this.getOfferStatus)
        }
      },
      err => {
        this.toaster.error('something went wrong');
        this.loader.stop();
      }
    );
  }
  // get mail data
  getMailData(data) {
    this.updateMailData = data;
  }
  // send offer function
  sendOffer(offerId, statusCode) {
    this.loader.start();
    let tempDate = new Date();
    let date = tempDate.toISOString();
    let payload = {
      offerId: offerId,
      statusCode: statusCode,
      date: date
    };
    this.offerService.sendOffer(payload).subscribe(
      res => {
        this.loader.stop();
        this.toaster.success('Offer sent successfully');
      },
      err => {
        this.loader.stop();
        this.toaster.error('something went wrong.');
      }
    );
  }
  // status update
  statusUpdate(statusCode, activity) {
    let tempDate = new Date();
    let date = tempDate.toISOString();
    const payload = {
      updateType: activity,
      offerId: this.offerId,
      body: {
        statusCode: statusCode,
        date: date
      }
    };
    this.offerService.statusUpdate(payload).subscribe(
      res => {
        this.loader.stop();
        this.toaster.success('status updated succesfully');
        this.router.navigate(['offers']);
      },
      err => {
        this.loader.stop();
        this.toaster.error('something went wrong.');
      }
    );
  }
  // get reset or destroy the data when you leaving the page or component
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subscription.unsubscribe();
  }
}
