import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

import { EngagementService } from "../../../../services/engagement/engagement.service";
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss']
})
export class RatingModalComponent implements OnInit {
  @Input() public feedbackModalData: any;
  @Output() submitFeedbackFormData = new EventEmitter<any>();
  @Output() feedbackFormClosed = new EventEmitter<any>();
  @Input() staticModalData: any;

  public feedbackMaster: any = [];
  
  @ViewChild('modalratingbox') modal: any;
  feedbackPayload: { offerId: any; groupName: any; itemCode: any; emailId: any; date: any; feedbackData: {}; };
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private engService: EngagementService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService,
    private engagementService: EngagementService
  ) {
  }

  ngOnInit() {
    this.getFeedbackMasterConfig();
  }
  openratinggmodal(modalratingbox) {
    this.modalService.open(modalratingbox, { size: 'lg', centered: true });
  }

  submitdata() {
    this.submitFeedbackFormData.emit(this.feedbackModalData.value);
    this.activeModal.close();
  }

  closeModal(status) {
    this.feedbackFormClosed.emit(status);
    this.activeModal.close();
  }

  // getFeedbackMasterConfig
  getFeedbackMasterConfig() {
    this.engService.getEngFeedbackMaster().subscribe((res) => {
      this.feedbackMaster = res.body;
      // console.log('feedbackMaster >>',this.feedbackMaster);
      // console.log('staticModalData >>',this.staticModalData);

      if (this.staticModalData) {
        this.getFeedbackData(this.staticModalData);
      }
    }, error => {
      this.toastr.error("Error getting data");
    })
  }

  //create feedback payload
  public submitEngagementFeedback() {
    this.feedbackPayload = {
      offerId: this.feedbackModalData.offerId,
      groupName: this.feedbackModalData.item,
      itemCode: this.feedbackModalData.data,
      emailId: this.feedbackModalData.assigned,
      date: new Date().toISOString(),
      feedbackData: this.feedbackMaster
    };
    console.log(this.feedbackPayload);
    if (this.validateData()) {

      this.submitFeedbackData(this.feedbackPayload);
    }
  }

  // submit feedback payload
  public submitFeedbackData(feedback) {
    this.loader.start();
    this.engService.submitFeedback(feedback).subscribe(
      res => {
        this.loader.stop();
        this.toastr.success('Feedback submitted sucessfully');
        this.submitdata();
      },
      error => {
        this.loader.stop();
        this.toastr.error('something went wrong.');
      }
    );
  }
  // validate feedback data
  validateData() {
    let problem = false;
    this.feedbackMaster.forEach(element => {
      if (element.parameters && element.parameters.length > 0) {
        element.parameters.forEach((param) => {
          //! removing validation for  engagement feedback
          // if (typeof param.value === 'string') {
          //   if (param.value === '') {
          //     this.toastr.error("Please give feedback for all parameters.")
          //     problem = true;
          //   }
          // }
          // if cant rate is checked set value null
          if (param.cantRate) {
            param.value = null;
          }
        })
      }
    });
    if (problem) {
      return false;
    }
    else {
      return true;
    }
  }

  getFeedbackData(offerData) {
    this.engagementService
      .getEngagementFeedbackTable(offerData.offerId)
      .subscribe(
        res => {
          console.log(res);
          this.feedbackMaster = res.body[offerData.index].feedbackData;
          console.log('feedbackMaster >>>',this.feedbackMaster);
        }, error => {
          this.toastr.error("Something went wrong while getting data")
        })
  }
}



