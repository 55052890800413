import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "offerShortener"
})
export class OfferShortenerPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let splitted = value.split("_"); //spliited between _ //11,12,13
      let first = splitted[0].substr(splitted[1].length - 10);
      let middle = splitted[1];
      let last = splitted[2].substr(splitted[2].length - 5);
      return first + middle + last;
    } else {
      return null;
    }
  }
}
