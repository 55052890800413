import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { offer } from "../../models/offer";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class OfferService {
  private apiName: string;
  private candidateApiName: string;
  private candidatePath: string;
  private path: string;
  orgHandle: string;
  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.offer;
    this.candidateApiName = apis.hireloopService.hireloop.name; //candidate endpoints
    this.candidatePath = apis.hireloopService.hireloop.endpoints.candidate; //canidate endpoints
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // gnerating offer
  public generateOffer(body) {
    const request = this.initRequests.initCreate({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
  // update offer
  public updateOffer(body, notify) {
    const request = this.initRequests.initUpdate({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: {
        offerId: body.offerId,
        notify: notify,
        body: body.data
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // getting all offers for organization
  public getAllOffers(body) {
    const request = this.initRequests.initGet({
      _action: "GET_ALL",
      orgHandle: this.orgHandle,
      queryBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // Get all the dashboard data
  public getAllDashboard(body) {
    const request = this.initRequests.initGet({
      _action: "GET_DASHBOARD",
      orgHandle: this.orgHandle,
      queryBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // upload file for offer
  public uploadFile(data) {
    const request = this.initRequests.initUpload({
      _action: "UPLOAD",
      orgHandle: this.orgHandle,
      uploadBody: data
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  //search candidate
  public searchCandidate(data) {
    const request = this.initRequests.initSearch({
      _action: "SEARCH",
      queryBody: data
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }
  //get a;; candidate
  public getAllCandidates() {
    const request = this.initRequests.initGetAllCandidates({
      _action: "GET_ALL"
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }
  //GetOfferDeatilsByOferID
  public GetOfferDeatilsByOferID(body) {
    const request = this.initRequests.initGetOfferDeatilsByOferID({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  //get candidate
  public getCandidate(data) {
    const request = this.initRequests.initSearch({
      _action: "GET",
      queryBody: data
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }
  //send offer
  public sendOffer(data) {
    const request = this.initRequests.initSendOffer({
      orgHandle: this.orgHandle,
      _action: "SEND_OFFER",
      insertBody: {
        offerId: data.offerId,
        statusCode: data.statusCode,
        date: data.date
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
  //status update
  public statusUpdate(data) {
    const request = this.initRequests.initStatusUpdate({
      orgHandle: this.orgHandle,
      _action: "STATUS_UPDATE",
      updateBody: data
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public offerMetaUpdate(offerId, data) {
    const request = this.initRequests.initUpdateOfferMeta({
      orgHandle: this.orgHandle,
      _action: "UPDATE_META",
      updateBody: {
        offerId: offerId,
        body: data
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
}
