import { InitRequests } from './models/requestModel';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  NgbModule,
  NgbModalModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing';
import { SearchCandidateComponent } from './pages/generate-offers/components/search-candidate/search-candidate.component';
import { LoginComponent } from './pages/login/login.component';
import { OffersTableComponent } from './shared/components/offers-table/offers-table.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { GenerateOffersComponent } from './pages/generate-offers/generate-offers.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { OffersComponent } from './pages/offers/offers.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OfferFormComponent } from './shared/components/offer-form/offer-form.component';
import { CustomiseMailComponent } from './pages/generate-offers/components/customise-mail/customise-mail.component';
import { ProgressBarComponent } from './pages/generate-offers/components/progress-bar/progress-bar.component';
import { StartEngagementComponent } from './pages/start-engagement/start-engagement.component';
import { ResponseMailComponent } from './pages/recruiter-offers/components/response-mail/response-mail.component';
import { RecruiterOffersComponent } from './pages/recruiter-offers/recruiter-offers.component';
import { UsersComponent } from './pages/users/users.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SysAdminOrganizationComponent } from './pages/sys-admin-organization/sys-admin-organization.component';
import { environment } from './../environments/environment';
import Amplify from 'aws-amplify';
import { SettingsComponent } from './pages/users/component/settings/settings.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AddUserComponent } from './pages/users/component/add-user/add-user.component';
import { OfferModalComponent } from './pages/generate-offers/components/offer-modal/offer-modal.component';
import { EditmailModalComponent } from './pages/sys-admin-organization/components/editmail-modal/editmail-modal.component';
import { IntegrationComponent } from './pages/integration/integration.component';
import { OrganizationalEntitiesComponent } from './pages/organizational-entities/organizational-entities.component';
import { SettingsDashboardComponent } from './pages/settings-dashboard/settings-dashboard.component';
import { FormSettingsComponent } from './shared/components/form-settings/form-settings.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { RatingModalComponent } from './pages/start-engagement/components/rating-modal/rating-modal.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { MessageComponent } from './shared/components/message/message.component';
import { CandidateDetailComponent } from './shared/components/candidate-detail/candidate-detail.component';
import { CandidateEngageModalComponent } from './pages/start-engagement/components/candidate-engage-modal/candidate-engage-modal.component';
// import { TagInputModule } from 'ngx-chips';
// tslint:disable-next-line:max-line-length
import { CandidateEngagementConfigurationComponent } from './shared/components/candidate-engagement-configuration/candidate-engagement-configuration.component';
import { CandidateEngagementComponent } from './shared/components/candidate-engagement/candidate-engagement.component';
Amplify.configure(environment.awsConfig);
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OfferShortenerPipe } from './pipes/offer-shortener.pipe';
import { OfferfilterPipe } from './pipes/offerfilter.pipe';
import { CandidatenamePipe } from './pipes/candidatename.pipe';
import { LogoutComponent } from './pages/logout/logout.component';
import { PreviewTemplateComponent } from './pages/generate-offers/components/customise-mail/preview-template/preview-template.component';
import { CandidateDetailsComponent } from './pages/candidate-details/candidate-details.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { MasterDataComponent } from './pages/master-data/master-data.component';
import { OrgEntityModalComponent } from './pages/organizational-entities/org-entity-modal/org-entity-modal.component';
import { SortTableDirective } from './sort-table.directive';
import { UpdateMasterDataComponent } from './pages/master-data/update-master-data/update-master-data.component';
import { EngagementTemplateComponent } from './pages/master-data/components/engagement-template/engagement-template.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EngageListComponent } from './pages/master-data/components/engage-list/engage-list.component';
import { EngageTempModalComponent } from './shared/components/engage-temp-modal/engage-temp-modal.component';
import { EngageTaskListComponent } from './pages/master-data/components/engage-task-list/engage-task-list.component';
import { EngageStageListComponent } from './pages/master-data/components/engage-stage-list/engage-stage-list.component';
import { CreateStageModalComponent } from './pages/master-data/components/create-stage-modal/create-stage-modal.component';
import { CreateTaskModalComponent } from './pages/master-data/components/create-task-modal/create-task-modal.component';
import { CandidateEngagementTemplateComponent } from './shared/components/candidate-engagement-template/candidate-engagement-template.component';
import { EngageStageModalSearchPipe } from './pipes/engage-stage-modal-search.pipe';
import { EngageActivityModalSearchPipe } from './pipes/engage-activity-modal-search.pipe';
// For now we are configuriong the config here itself. but after that it will be done after login of particular org // TODO

// new compos
import { UserActivityModalComponent } from './pages/start-engagement/components/user-activity-modal/user-activity-modal.component';
import { EngagementAssosiateActivityComponent } from './pages/start-engagement/components/engagement-assosiate-activity/engagement-assosiate-activity.component';
import { UserActivityFeedbackModalComponent } from './pages/start-engagement/components/user-activity-feedback-modal/user-activity-feedback-modal.component';


// mat modeuls
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import { ShowDescriptionComponent } from './pages/start-engagement/components/description/show-description/show-description.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { DigitalActivityModalComponent } from './pages/start-engagement/components/digital-activity-modal/digital-activity-modal.component';
import { OtherActivityModalComponent } from './pages/start-engagement/components/other-activity-modal/other-activity-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgZorroAntdModule, NZ_I18N, en_US  } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en'
registerLocaleData(en);
import { DashboardTableComponent } from './shared/components/dashboard-table/dashboard-table.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { JoinersandDropoutComponent } from './pages/joinersand-dropout/joinersand-dropout.component';
import { JoinersandDropoutTableComponent } from './shared/components/joinersand-dropout-table/joinersand-dropout-table.component';
import { EngagementTrackerComponent } from './pages/engagement-tracker/engagement-tracker.component';
import { EngagmentTrackerTableComponent } from './pages/engagement-tracker/component/engagment-tracker-table/engagment-tracker-table.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OffersTableComponent,
    SidebarComponent,
    HeaderComponent,
    GenerateOffersComponent,
    CustomiseMailComponent,
    OffersComponent,
    SearchCandidateComponent,
    OfferFormComponent,
    ProgressBarComponent,
    StartEngagementComponent,
    ResponseMailComponent,
    RecruiterOffersComponent,
    UsersComponent,
    DashboardComponent,
    SysAdminOrganizationComponent,
    SettingsComponent,
    AddUserComponent,
    OfferModalComponent,
    CandidateEngagementConfigurationComponent,
    EditmailModalComponent,
    IntegrationComponent,
    OrganizationalEntitiesComponent,
    SettingsDashboardComponent,
    FormSettingsComponent,
    RatingModalComponent,
    MessageComponent,
    CandidateDetailComponent,
    CandidateEngageModalComponent,
    CandidateEngagementComponent,
    OfferShortenerPipe,
    OfferfilterPipe,
    CandidatenamePipe,
    LogoutComponent,
    PreviewTemplateComponent,
    CandidateDetailsComponent,
    MasterDataComponent,
    OrgEntityModalComponent,
    SortTableDirective,
    UpdateMasterDataComponent,
    EngagementTemplateComponent,
    EngageListComponent,
    EngageTempModalComponent,
    EngageTaskListComponent,
    EngageStageListComponent,
    CreateStageModalComponent,
    CreateTaskModalComponent,
    CandidateEngagementTemplateComponent,
    EngageStageModalSearchPipe,
    EngageActivityModalSearchPipe,
    UserActivityModalComponent,
    EngagementAssosiateActivityComponent,
    UserActivityFeedbackModalComponent,
    ShowDescriptionComponent,
    DigitalActivityModalComponent,
    OtherActivityModalComponent,
    DashboardTableComponent,
    ReportsComponent,
    JoinersandDropoutComponent,
    JoinersandDropoutTableComponent,
    EngagementTrackerComponent,
    EngagmentTrackerTableComponent,
  ],

  imports: [
    //ant design
    NzButtonModule,

    NzTableModule,
    NzIconModule,
    NgZorroAntdModule,

    // mat modules
    MatButtonModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,

    // ng prime modules

    NgbModule.forRoot(),
    BrowserModule,
    RouterModule.forRoot(routes),
    AngularEditorModule,
    FormsModule,
    HttpClientModule,
    UiSwitchModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbDropdownModule,
    BarRatingModule,
    NgxUiLoaderModule,
    // TagInputModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    NgJsonEditorModule,
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),

    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning'
      }
    })
  ],

  entryComponents: [
    OfferModalComponent,
    SearchCandidateComponent,
    EditmailModalComponent,
    CandidateEngageModalComponent,
    SettingsComponent,
    CandidateDetailComponent,
    RatingModalComponent,
    PreviewTemplateComponent,
    OrgEntityModalComponent,
    UpdateMasterDataComponent,
    EngageTempModalComponent,
    CreateStageModalComponent,
    CreateTaskModalComponent,
    ShowDescriptionComponent,
    DigitalActivityModalComponent,
    OtherActivityModalComponent,

    UserActivityModalComponent,
    UserActivityFeedbackModalComponent,
    EngagementAssosiateActivityComponent
  ],
  providers: [
    InitRequests,
    EngageStageModalSearchPipe,
    EngageActivityModalSearchPipe,
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
