// These are the master types that we will need to call master apis and also to create master lists for organization in later stages
export const MasterTypes = {
  offerStatus: "offerStatus",
  offerActivity: "offerActivity",
  orgEntities: "orgEntities",
  engagement: "engagement",
  mailTemplates: "mailTemplates",
  orgRoles: "orgRoles",
  locations: "locations",
  mailTempMaster: "templateMaster",
  resumeSources: "resumeSources"
};

export const templateCodes = {
  offerTemplate: "OFT",
  offerWaitingForAcceptance: "OFW",
  revisedOffer: "ROP",
  cancelledOffer: "CAN",
  eventInvite: "EVI",
  sevenDaysToGo: "DTG"
};

export const activityCodes = {
  generated: "GEN",
  sent: "ST",
  withdrawn: "WDN",
  viewed: "VWD",
  revised: "RVD"
};

export const statusCodes = {
  accepted: "ACT",
  declined: "DEC",
  joined: "JND",
  noshow: "NSW",
  lateRejection: "LREJ",
  noResponse: "NRES",
  abandonment: "ABN"
};

export const orgRoles = {
  "RECT": "recruiter",
  "HRM": "hiringManager",
  "HRMN": "hrManager",
  "TAM": "taManager", // this has to remain ta manager for the changes to work properly
  "RPM": "reportingManager", // this has to remain reporting manager for rules to apply properly.
  "RCTM": "recruiterManager" // this has to remain recruiter manager for rules to apply properly.
}
