import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('modalsettingbox') modal: any;
  @Input() public selectedUserData;

  imgURL: any;
  // imgPencilURL: any;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.imgURL = ['../../../assets/images/user.svg'];
    // this.imgPencilURL = ["../../../assets/images/pencil-edit-button.svg"];
  }
  preview(files) {
    if (files.length > 0) {
      const mimeType = files[0].type;
      //  this.message = "";

      if (mimeType.match(/image\/*/) == null) {
        //  this.message = "Upload Only .jpeg, .png, .jpg file";
        return;
      } else {
        //  this.uploadStatus = true;
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = event => {
        console.log('Image Load ', event);
        this.imgURL = reader.result;
        //  this.imageFile = files[0];
      };
    }
  }

  openUserSettingmodal(modalsettingbox) {
    this.modalService.open(modalsettingbox, { size: 'lg', centered: true });
  }

  // ngOnChanges(changes: any): void {
  //   if (changes && changes.userData && changes.userData.currentValue) {
  //     this.userData = changes.userData.currentValue;
  //     console.log(this.userData);
  //   }
  // }
}
