import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferModalComponent implements OnInit {
  @ViewChild('modaloffer') modal: any;

  reviewmail = false;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {}
  openselectedoffer(modaloffer) {
    this.modalService.open(modaloffer, {
      windowClass: 'generateoffer-modal',
      size: 'lg',
      centered: true
    });
  }
  opensendoffer() {
    this.reviewmail = true;
    this.activeModal.close('I am a modal that is closing');
  }
}
