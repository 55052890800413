import { NgxUiLoaderService } from "ngx-ui-loader";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SharedService } from "./../../services/shared/shared.service";
import { AfterViewChecked, Component, OnChanges, OnInit, SimpleChange } from "@angular/core";
import { AuthService } from 'src/app/services/auth/auth.service';
import { OfferService } from "./../../services/offer/offer.service";
import { EngagementService } from "src/app/services/engagement/engagement.service";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  charts: any[];
  authRole: string;
  currentUser: any;
  offers: any;
  dashboardData: any;
  offerCount: any = 0;
  generatedValue: any = 0;
  revisedValue: any = 0;
  dropoutValue: any = 0;
  notengagedValue: any = 0;
  joinedValue: any = 0;
  disableNext: any;
  preProcessOffer: any[] = [];
  engagmentData: any;
  totalActiveCandidates = 0;
  filterObj = {
    candidateName: "",
    date: "",
    offerId: "",
    businessUnit: "",
    division: "",
    department: "",
    noticePeriod: "",
    joiningDate: "",
    expiryDate: "",
    offerStatus: ""
  };
  sortObj = {};
  constructor(
    private shared: SharedService,
    private toaster: ToastrService,
    private sanitizer: DomSanitizer,
    private loader: NgxUiLoaderService,
    public authService: AuthService,
    private offerService: OfferService,
    public sharedService: SharedService,
    private engagementService: EngagementService,
    private router: Router,
  ) {
    this.charts = [];
    this.disableNext = false;
    this.sharedService.getMastersData();
  }

  async ngOnInit() {
    // this.render();
    await this.getUserInformation();
    await this.getAllOffers(1);
    await this.getDashboardData();
    await this.getEngagmemtData();
    await this.checkActiveCandidates();
    this.animateValue("totalnumber", 0, this.offerCount, 100);
    this.animateValue("engagingNumber", 0, this.generatedValue, 100);
    this.animateValue("joinedNumber", 0, this.joinedValue, 100);
    this.animateValue("dropedNumber", 0, this.dropoutValue, 100);
    this.shared.getMasterAccToType("charts").subscribe(
      res => {
        // console.log(res);
        this.charts = res.body[0].data[0].data.items;
        // console.log(this.charts);
        // this.charts = [];
        if (this.charts && this.charts.length > 0) {
          this.charts.forEach(el => {
            el.title = this.sanitizeUrl(el.title);
          });
          // this.loader.start();
        }
      },
      error => {
        this.loader.stop();
        this.toaster.error("Something went wrong");
      }
    );
  }

  sanitizeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  checkChartStatus() {
    this.loader.stop();
  }

  animateValue(id, start, end, duration) {
    if (start === end) return;
    let range = end - start;
    let current = start;
    let incdecStep = Math.abs(Math.floor(end/1000));
    let increment = end > start? incdecStep : -(incdecStep);
    let stepTime = Math.abs(Math.floor(duration / range));
    // console.log(stepTime, duration, range, incdecStep);
    let obj = document.getElementById(id);
    let timer = setInterval(function() {
        current += increment;
        obj.innerHTML = current.toLocaleString('en-IN');
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
  }

  //Getting the user deatils
  getUserInformation() {
    this.authRole = this.authService.getAuthRole();
    this.authService.getCurrentAuthUser().subscribe((res: any) => {
      // console.log(res, this.authRole);
      this.currentUser = res.attributes.email;
    }, (error) => {
      this.toaster.error(
        'something went wrong  while getting the user details.'
      );
    })
  }
  // getting all the candidates details
  getAllOffers(pageNumber, lastId?) {
    this.loader.start();
    let offerPayload = {
      pageNumber,
      lastId: lastId,
      limit: "",
      emailId: this.currentUser,
      role: this.authRole,
      filter: this.filterObj,
      sort: this.sortObj
    }
    this.offerService.getAllOffers(offerPayload).subscribe(
      res => {
        // console.log(res);
        this.offers = [];
        this.offers = res.body;
        this.offerCount = res.count;
        if (this.offers.length < 10) {
          this.disableNext = true;
        } else {
          this.disableNext = false;
        }
        this.preProcessData();
        this.loader.stop();
        this.getEngagmemtData();
      },
      err => {
        // this.offerCount = 0;
        this.toaster.error("No Data Found.");
        // this.preProcessOffer = [];
        this.loader.stop();
      }
    );
  }

  checkOfferStatus(x) {
    switch (x) {
      case 'Generated':
        this.generatedValue++;
        break;
      case 'Drop Out':
        this.dropoutValue++;
        break;
      case 'Early Exit':
        this.dropoutValue++;
        break;
      case 'Not engaged ':
        // this.notengagedValue++;
        // this.dropoutValue++;
        break;
      case 'Joined':
        this.joinedValue++;
        break;
      case 'Revised':
        this.revisedValue++;
        break;
      default:
        // console.log(x);
        break;
    }
  }

  preProcessData() {
    this.preProcessOffer = [];
    this.offers.forEach(offer => {
      const Offer = {
        name: offer.candidateData.firstName + ' ' + (offer.candidateData.lastName ? offer.candidateData.lastName : ""),
        // lastName: offer.candidateData.lastName,
        SortOfferId: this.sharedService.getOfferIDSort(offer.offerId),
        offerId: offer.offerId,
        businessUnit: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.businessUnit
        ),
        department: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.department
        ),
        division: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.division
        ),
        noticePeriod: offer.offerMetaData.noticePeriod ? offer.offerMetaData.noticePeriod + ' ' + 'days' : "",
        expiryDate: this.sharedService.getDateFormat(
          offer.offerMetaData.expiryDate
        ),
        joiningDate: this.sharedService.getDateFormat(
          offer.offerMetaData.joiningDate
        ),
        date: this.sharedService.getDateFormat(offer.offerMetaData.date),
        designation: offer.offerMetaData.designation,
        status: this.sharedService.getOfferActivityTitle(
          offer.collectiveStatus[0].statusCode
        ),
        activity: offer.offerMetaData.activity,
        offerData: offer,
        _id: offer._id
      };
      this.checkOfferStatus(Offer.status);
      // console.log(Offer.status);
      this.preProcessOffer.push(Offer);
    });
    // console.log(this.generatedValue, this.dropoutValue, this.notengagedValue, this.joinedValue, this.revisedValue);
    // console.log(this.preProcessOffer);
  }

  getDashboardData() {
    this.loader.start();
    let offerPayload = {
      limit: "",
      emailId: this.currentUser,
      role: this.authRole,
      filter: this.filterObj,
      sort: this.sortObj
    }
    this.offerService.getAllDashboard(offerPayload).subscribe(
      res => {
        // console.log(res);
        this.dashboardData = res.body
      },
      err => {
        this.loader.stop();
      }
    );
  }

  getEngagmemtData() {
    this.loader.start();
    this.engagementService.getAllEngagementData('').subscribe(
      res => {
        // console.log(res);
        this.engagmentData = res.body;
        this.loader.stop();
        this.checkActiveCandidates();
      },
      err => {
        this.loader.stop();
      }
    )
  }

  checkActiveCandidates() {
    // console.log('this is great');
    // console.log(this.preProcessOffer);
    this.totalActiveCandidates = this.preProcessOffer.length;
    // console.log(this.engagmentData);
    // this.totalActiveCandidates += this.revisedValue;
    // this.preProcessOffer.forEach(offer_item => {
    //   this.engagmentData.forEach( e_item => {
    //     if(offer_item.offerId == e_item.offerId){
    //       this.totalActiveCandidates++;
    //     }
    //   })
    // })
    // console.log(this.totalActiveCandidates);
  }

  viewTotalCandidatesDetails(event?) {
    if (event) event.stopPropagation();
    this.router.navigate(["report"]);
  }

  viewEngagmentTracker(event?) {
    if (event) event.stopPropagation();
    this.router.navigate(["engagmentTracker"]);
  }

  viewJoinerandDropout(roterName,event?) {
    // console.log(roterName);
    if (event) event.stopPropagation();
    this.router.navigate(["joinersandDropout", {roterName: roterName}]);
  }

}
