import { User } from './user';
import { offer, UploadFile } from './offer'; // every request related to apis of organization needs an orgHandle
// interfaces for requests
export interface getOffer {
  orgHandle: string;
  _action: string;
  queryBody?: {
    pageNumber?: number,
    offerId?: string;
    lastId?: string;
    limit?: string;
    filter?: any;
  };
}

// generate offer interface
export interface generateOffer {
  orgHandle: string;
  _action: string;
  insertBody: offer;
}

// update offer interface
export interface updateOffer {
  orgHandle: string;
  _action: string;
  updateBody: {
    offerId: string;
    notify: string;
    body: offer;
  };
}

// get mail template interface
export interface GetmailTemplate {
  orgHandle: string;
  _action: string;
  queryBody: {
    templateCode: string;
  };
}
// upload file interface
export interface UploadFileBody {
  orgHandle: string;
  _action: string;
  uploadBody: UploadFile;
}

// get masters according to type
export interface getMaster {
  orgHandle: string;
  _action: string;
  queryBody?: {
    masterType: string;
    code?: string;
  };
}

// search candidate interface
export interface SearchCandidate {
  _action: string;
  queryBody?: {
    MasterType: string;
  };
}

// get users
export interface GetUsers {
  _action: string;
  orgHandle: string;
  queryBody: {
    projection: any;
  };
}

// Get User By Id

export interface GetUserById {
  _action: string;
  orgHandle: string;
  queryBody: {
    emailId: any;
  };
}
// Get Mail templates

export interface GetMailTemplates {
  _action: string;
  orgHandle: string;
  queryBody: {
    masterType: string;
  };
}
// Get Mail templates

export interface GetMailTempData {
  _action: string;
  orgHandle: string;
  queryBody: {
    templateCode: string;
  };
}

// Get details by Id;
export interface GetCandidateDetails {
  _action: string;
  queryBody: {
    candidateId: string;
  };
}

// create data
export interface CreateUserBody {
  _action: string;
  orgHandle: string;
  insertBody: User;
}

export interface UpdateUserBody {
  _action: string;
  orgHandle: string;
  updateBody: User;
}
// Create Mail nTemplate
export interface CreateMailTemplateBody {
  _action: string;
  orgHandle: string;
  insertBody: any;
}
// Create Mail nTemplate
export interface UpdateMailTemplateBody {
  _action: string;
  orgHandle: string;
  updateBody: any;
}

// send offer
export interface SendOfferBody {
  orgHandle: string;
  _action: string;
  insertBody: {
    offerId: string;
    statusCode: string;
    date: string;
  };
}
// send offer
export interface GetAllCandidates {
  _action: string;
}
// Start Engagement
export interface StartEngagement {
  orgHandle: string;
  _action: string;
  insertBody: any;
}

// submit Feedback
export interface SubmitFeedback {
  orgHandle: string;
  _action: string;
  insertBody: any;
}

// Update Engagement Task
export interface UpdateEngagementTask {
  orgHandle: string;
  _action: string;
  updateBody: any;
}
export interface SubmitFeedback {
  orgHandle: string;
  _action: string;
  insertBody: any;
}

// get Engagement
export interface GetEngagement {
  orgHandle: string;
  _action: string;
  queryBody: any;
}
// get all the Engagement data
export interface GetAllEngagement {
  orgHandle: string;
  _action: string;
  queryBody?: {
    emailId: string;
  }
}
// Get Offer Deatils By OferID
export interface GetOfferDeatilsByOferID {
  orgHandle: string;
  _action: string;
  queryBody: any;
}
// create chat interface
export interface CreateChat {
  orgHandle: string;
  _action: string;
  insertBody: {
    offerId: any;
    chatDetails: any;
  };
}
// get chat interface
export interface GetChat {
  orgHandle: string;
  _action: string;
  queryBody: {
    offerId: any;
  };
}
export interface StatusUpdate {
  orgHandle: string;
  _action: string;
  updateBody: any;
}

//notification get moddel
export interface getNotification {
  _action: string;
  queryBody: {
    createdBy: string;
    orgHandle: string;
  };
}
// notification update status moddel
export interface updateNotification {
  _action: string;
  updateBody: {
    notificationIds: string[];
    clicked?: boolean;
    read?: boolean;
  };
}

//get org details
export interface getOrgDetails {
  _action: string;
  queryBody: {
    orgHandle: string;
  };
}

//gettting user in the org db
export interface getUserDetails {
  orgHandle: string;
  _action: string;
  queryBody: {
    emailId: string;
  };
}

//update user
export interface updateUserDetails {
  orgHandle: string;
  _action: string;
  updateBody: {
    emailId: string;
    body: any;
  };
}

export interface getOrgConfigDetails {
  _action: string;
  queryBody: {
    orgHandle: string;
  };
}

export interface updateOrgConfigDetails {
  _action: string;
  updateBody: {
    orgHandle: string;
    body: any;
  };
}

export interface uploadOrgConfigData {
  _action: string;
  uploadBody: {
    dataType: string;
    fileFormat: any;
    fileData: any;
  };
}

// updating meta data of engagement
export interface updateEngMeta {
  orgHandle: string;
  _action: string;
  updateBody: {
    offerId: string;
    candidateName?: string;
    body: any;
  };
}

//get engagement feedback table for offerid
export interface GetEngagementFeedbackTable {
  orgHandle: string;
  _action: string;
  queryBody: {
    offerId: string;
  };
}

export interface createMaster {
  orgHandle: string;
  _action: string;
  insertBody: {
    masterType: string;
    code: string;
    data: any;
  };
}

export interface updateMaster {
  orgHandle: string;
  _action: string;
  updateBody: {
    masterType: string;
    code: string;
    data: any;
  };
}

export interface GetMasterAccToType {
  orgHandle: string;
  _action: string;
  queryBody: {
    masterType: string;
  };
}
export interface updateOfferMeta {
  orgHandle: string;
  _action: string;
  updateBody: {
    offerId: string;
    body: any;
  };
}

export interface StageCreate {
  orgHandle: string;
  _action: string;
  insertBody: {
    type: string;
    name: string;
    description: string;
    digital: boolean;
  };
}

export interface StageGet {
  orgHandle: string;
  _action: string;
  queryBody: any
}

export interface UpdateStage {
  orgHandle: string;
  _action: string;
  updateBody: {
    id: string,
    body: {
      type: string;
      name: string;
      description: string;
      digital: boolean;
    }
  }
}


export interface EngagementTemplateCreate {
  orgHandle: string;
  _action: string;
  insertBody: {
    name: string;
    description: string;
    stages: [{
      id: string,
      code: string,
      activities: [
        {
          id: string,
          code: string,
          rules: [{
            condition: string,
            action: string,
            time: number
          }
          ],
          ruleAssociation?: string,
          time: string
        }
      ]
    }]
  };
}

export interface EngagementTemplateGet {
  orgHandle: string;
  _action: string;
  queryBody: any
}
export interface EngagementTemplateUpdate {
  orgHandle: string;
  _action: string;
  updateBody: {
    id: string,
    body: {
      name: string;
      description: string;
      stages: [{
        id: string,
        code: string,
        activities: [
          {
            id: string,
            code: string,
            rules: [{
              condition: string,
              action: string,
              time: number
            }
            ],
            ruleAssociation?: string,
            time: string
          }
        ]
      }]
    }
  }
}
//get eng feedback master for all
export interface EngagmentFeedbackMaster {
  orgHandle: string;
  _action: string;
}

//  Class to init requests
export interface requestBody {
  body: any;
  headers?: {
    "Authorization": any;
    "X-Api-Key"?: string //this we are using for orghandle
  }
}

// class for initializing requests
export class InitRequests {
  private body: requestBody;
  constructor() {
    this.body = {
      body: null,
      headers: { "Authorization": null }
    };
  }

  public initCreate(config: generateOffer) {
    this.body.body = config;
    return this.body;
  }
  public initGet(config: getOffer) {
    this.body.body = config;
    return this.body;
  }
  public initUpdate(config: updateOffer) {
    this.body.body = config;
    return this.body;
  }
  public initMaster(config: getMaster) {
    this.body.body = config;
    return this.body;
  }
  public initGetTemplates(config: GetmailTemplate) {
    this.body.body = config;
    return this.body;
  }
  public initUpload(config: UploadFileBody) {
    this.body.body = config;
    return this.body;
  }
  public initSearch(config: SearchCandidate) {
    this.body.body = config;
    return this.body;
  }
  public initCreateUser(config: CreateUserBody) {
    this.body.body = config;
    return this.body;
  }

  public initUpdateUser(config: UpdateUserBody) {
    this.body.body = config;
    return this.body;
  }

  public initCreateMailTemplate(config: CreateMailTemplateBody) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateMailTemplateBody(config: UpdateMailTemplateBody) {
    this.body.body = config;
    return this.body;
  }
  public initGetUser(config: GetUsers) {
    this.body.body = config;
    return this.body;
  }

  public initGetUserById(config: GetUserById) {
    this.body.body = config;
    return this.body;
  }

  public initGetMailTemplate(config: GetMailTemplates) {
    this.body.body = config;
    return this.body;
  }
  public initGetMailTemplateData(config: GetMailTempData) {
    this.body.body = config;
    return this.body;
  }
  public initGetCandidateDetails(config: GetCandidateDetails) {
    this.body.body = config;
    return this.body;
  }
  public initSendOffer(config: SendOfferBody) {
    this.body.body = config;
    return this.body;
  }
  public initGetAllCandidates(config: GetAllCandidates) {
    this.body.body = config;
    return this.body;
  }
  public initStartEngagement(config: StartEngagement) {
    this.body.body = config;
    return this.body;
  }

  public initSubmitFeedback(config: SubmitFeedback) {
    this.body.body = config;
    return this.body;
  }

  public initUpdateEngagementTask(config: UpdateEngagementTask) {
    this.body.body = config;
    return this.body;
  }

  public initGetEngagement(config: GetEngagement) {
    this.body.body = config;
    return this.body;
  }
  public initGetAllEngagement(config: GetAllEngagement) {
    this.body.body = config;
    return this.body;
  }
  public initGetOfferDeatilsByOferID(config: GetOfferDeatilsByOferID) {
    this.body.body = config;
    return this.body;
  }
  public initCreateChat(config: CreateChat) {
    this.body.body = config;
    return this.body;
  }
  public initGetChat(config: GetChat) {
    this.body.body = config;
    return this.body;
  }
  public initStatusUpdate(config: StatusUpdate) {
    this.body.body = config;
    return this.body;
  }
  public initGetNotification(config: getNotification) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateNotification(config: updateNotification) {
    this.body.body = config;
    return this.body;
  }
  public initGetOrgDetails(config: getOrgDetails) {
    this.body.body = config;
    return this.body;
  }
  public initGetUserDetails(config: getUserDetails) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateUserDetails(config: updateUserDetails) {
    this.body.body = config;
    return this.body;
  }

  public initGetOrgConfigDetails(config: getOrgConfigDetails) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateOrgConfigDetails(config: updateOrgConfigDetails) {
    this.body.body = config;
    return this.body;
  }

  public inituploadOrgConfigData(config: uploadOrgConfigData) {
    this.body.body = config;
    return this.body;
  }

  public initGetEngagementFeedbackTable(config: GetEngagementFeedbackTable) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateEngMeta(config: updateEngMeta) {
    this.body.body = config;
    return this.body;
  }
  public initCreateMaster(config: createMaster) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateMaster(config: updateMaster) {
    this.body.body = config;
    return this.body;
  }

  public initGetMasterAccToType(config: GetMasterAccToType) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateOfferMeta(config: updateOfferMeta) {
    this.body.body = config;
    return this.body;
  }
  public initCreateStage(config: StageCreate) {
    this.body.body = config;
    return this.body;
  }
  public initUpdateStage(config: UpdateStage) {
    this.body.body = config;
    return this.body;
  }
  public initGetStage(config: StageGet) {
    this.body.body = config;
    return this.body;
  }
  public initCreateEngagementTemplate(config: EngagementTemplateCreate) {
    this.body.body = config;
    return this.body;
  }
  public initEngagementTemplateUpdate(config: EngagementTemplateUpdate) {
    this.body.body = config;
    return this.body;
  }
  public initEngagementTemplateGet(config: EngagementTemplateGet) {
    this.body.body = config;
    return this.body;
  }
  public initFeedbackMaster(config: EngagmentFeedbackMaster) {
    this.body.body = config;
    return this.body;
  }
}
