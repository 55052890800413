import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent implements OnInit {
  @Input() public mailData: any;
  @Input() public offerFormData: any;
  @Input() public showBulkOffeTemp: any;
  @Output() submitMailData = new EventEmitter<any>();
  constructor(public activeModal: NgbActiveModal,
  ) {

  }

  ngOnInit() {
    this.replaceText();
  }

  replaceText() {
    if (this.mailData) {
      for (var key in this.offerFormData) {
        if (this.offerFormData.hasOwnProperty(key)) {
          let value = this.offerFormData[key];
          let replacekey = "%"+key+"%";
          let updatedTemplate = this.mailData.replace(new RegExp(this.escapeRegExp(replacekey), 'g'), value);
          this.mailData = updatedTemplate
        }
      }
    }
  };
  escapeRegExp(string){
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
  submitdata() {
      this.submitMailData.emit(this.mailData);
      this.activeModal.close();
  }
}
