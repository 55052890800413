import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StageService } from "src/app/services/stage/stage.service";
import { ToastrService } from "ngx-toastr";

export interface stageInterface {
  id: string,
  code: string,
  _data?: any
  activities?: []
}

export interface activityInterface {
  id: string;
  code: string;
  _data?: any;
  rules: [{
    condition: string,
    action: string,
    time: string,
    days: number,
    role: string
  }
  ],
  ruleAssociation?: string,
}


@Component({
  selector: "app-engage-temp-modal",
  templateUrl: "./engage-temp-modal.component.html",
  styleUrls: ["./engage-temp-modal.component.scss"]
})
export class EngageTempModalComponent implements OnInit {
  @Input() engagementData: stageInterface[] = [];
  @Input() activityData: activityInterface[] = [];
  @Input() type: any = "";
  searchStage = "";
  searchActivity = "";
  constructor(
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private stageService: StageService,
  ) { }

  engagementStageList: any[] = [];
  engagementActivityList: any[] = [];

  ngOnInit() {
    console.log(this.type, this.engagementData, this.activityData)
    this.getActvitiesAndStages(this.type)
  }

  // get activities and stages
  private getActvitiesAndStages(type) {
    const payload = { type }
    this.stageService.getStages(payload).subscribe((res) => {
      type === "stage" ? this.engagementStageList = res.body : this.engagementActivityList = res.body;
      this.engagementStageList = this.engagementStageList.map(el => {
        el.selected = false;
        return el;
      })
      this.initialize();
    }, error => {
      this.toaster.error("Error getting engagement stages");
    })
  }

  // init
  private initialize() {
    let dataToIterate = this.type === "stage" ? JSON.parse(JSON.stringify(this.engagementData)) : JSON.parse(JSON.stringify(this.activityData))
    let dataToCompareTo = this.type === "stage" ? this.engagementStageList : this.engagementActivityList;
    //if stages already exist
    if (dataToIterate.length > 0) {
      dataToIterate.forEach((stage) => {
        dataToCompareTo.forEach((master) => {
          if (stage.id === master._id || stage.code === master.code) {
            master.selected = true;
          }
        })
      })
    }
  }

  // check data before destroy
  public checkDataBeforeDestroy() {
    let newStage: stageInterface = { id: "", code: "", activities: [], _data: {} };
    let newActivity: activityInterface = { id: "", code: "", _data: {}, rules: [{ action: "", time: "11:00", days: 1, condition: "", role: "" }], ruleAssociation: "" }
    let dataToCompareTo = this.type === "stage" ? JSON.parse(JSON.stringify(this.engagementData)) : JSON.parse(JSON.stringify(this.activityData))
    let dataToIterate = this.type === "stage" ? this.engagementStageList : this.engagementActivityList;

    // console.log(dataToIterate, dataToCompareTo)
    dataToIterate.forEach((master) => {
      // console.log(master)
      const _newStage: stageInterface = JSON.parse(JSON.stringify(newStage));
      const _newActivity: activityInterface = JSON.parse(JSON.stringify(newActivity));
      // console.log(_newActivity);
      _newStage.id = master._id;
      _newStage.code = master.code;
      _newStage._data = { name: master.name, description: master.description, digital: master.digital, code: master.code, activityType: master.activityType ,interactionType: master.interactionType};
      _newActivity.id = master._id;
      _newActivity.code = master.code;
      _newActivity._data = { name: master.name, description: master.description, digital: master.digital, code: master.code, activityType: master.activityType,interactionType: master.interactionType};
      if (master.selected) {
        let present = false;
        if (dataToCompareTo.length > 0) {
          // console.log(dataToCompareTo)
          dataToCompareTo.forEach((stageOrActivity) => {
            if (master._id === stageOrActivity.id || master.code === stageOrActivity.code) {
              present = true;
            }
          })
          if (!present) {
            this.type === "stage" ? dataToCompareTo.push(_newStage) : dataToCompareTo.push(_newActivity);
          }
        } else {
          this.type === "stage" ? dataToCompareTo.push(_newStage) : dataToCompareTo.push(_newActivity);
        }
      } else {
        if (dataToCompareTo.length > 0) {
          dataToCompareTo.forEach((stageOrActivity) => {
            if (master._id === stageOrActivity.id || master.code === stageOrActivity.code) {
              stageOrActivity.deleted = true;
            }
          })
        }
      }
    });
    // filtering data out
    dataToCompareTo = dataToCompareTo.filter(data => {
      return !data.deleted;
    });
    // console.log(this.engagementData);
    // console.log(dataToCompareTo)
    this.closeModal(dataToCompareTo);
  }
  // close modal without data
  dismissModal() {
    this.activeModal.close(this.type === 'stage' ? this.engagementData : this.activityData);
  }
  // close modal with data
  closeModal(result) {
    this.activeModal.close(result);
  }

}
