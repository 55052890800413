import { SharedService } from "./../../../../services/shared/shared.service";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MasterTypes } from "src/app-settings/master_types";
import { UserService } from "src/app/services/user/user.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { routeNames } from "src/app-settings/routes";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"]
})
export class AddUserComponent implements OnInit {
  public userForm: FormGroup;
  public dataSubmitted = false;
  public roles: any;
  public users: any;
  public userId: any;
  userDetails: any;
  updateBtn: boolean;
  orgEntityData: any = {
    businessUnit: null,
    division: null,
    department: null
  };
  orgEntitiesData: any[];

  businessUnits: any[] = [];
  divisions: any[] = [];
  department: any[] = [];

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private userService: UserService,
    private loader: NgxUiLoaderService,
    private router: Router,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private shared: SharedService
  ) {
    this.userForm = this.fb.group({
      emailId: ["", [Validators.required, Validators.email]],
      displayName: ["", Validators.required],
      team: ["", Validators.required],
      role: ["", Validators.required],
      status: [true],
      reportsTo: ["", Validators.required],
      orgEntityData: this.fb.group({
        businessUnit: [""],
        division: [""],
        department: [""]
      })
    });
    this.updateBtn = false;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.userId = params.get("id");
      if (this.userId) {
        this.getUserById();
        this.updateBtn = true;
      } else {
        this.getOrgEntities();
      }
    });

    this.getAllRoles();
    const projection = {
      emailId: "1",
      _id: "0",
      displayName: "1"
    };
    this.getUser(projection);
  }

  backuser() {
    this.location.back();
  }

  submitUserData() {
    this.dataSubmitted = true;
    if (this.userForm.valid) {
      this.createUser(this.userForm.value);
    }
  }

  getAllRoles() {
    this.sharedService
      .getMasterAccToType(MasterTypes.orgRoles)
      .subscribe(res => {
        this.roles = res.body[0].data[0].data.items;
      });
  }

  createUser(InsertBody) {
    this.loader.start();
    this.userService.createUser(InsertBody).subscribe(
      res => {
        this.loader.stop();
        if (res.err && res.err === "USER_EXISTS") {
          this.toaster.error("User already exists");
        } else {
          this.router.navigate([routeNames.user]);
          this.toaster.success("User created sucessfully.");
        }
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error("something went wrong.");
      }
    );
  }

  getUser(projection) {
    this.loader.start();
    this.userService.getUsers(projection).subscribe(
      res => {
        this.users = res.body;
        this.loader.stop();
      },
      error => {
        this.toaster.error("something went wrong.");
        console.log(error);
      }
    );
  }

  getUserById() {
    this.loader.start();
    this.userService.getUsersById(this.userId).subscribe(
      res => {
        this.getOrgEntities(() => {
          this.userDetails = res.body;
          this.userForm.patchValue({
            emailId: this.userDetails.emailId,
            displayName: this.userDetails.displayName,
            team: this.userDetails.team,
            role: this.userDetails.role,
            status: this.userDetails.status,
            reportsTo: this.userDetails.reportsTo
          });
          if (this.userDetails.orgEntityData) {
            this.userForm
              .get("orgEntityData")
              .get("businessUnit")
              .patchValue(
                this.userDetails.orgEntityData.businessUnit
                  ? this.userDetails.orgEntityData.businessUnit
                  : ""
              );
            this.sortMasterOnchange(
              this.userDetails.orgEntityData.businessUnit,
              "bu"
            );
            this.userForm
              .get("orgEntityData")
              .get("division")
              .patchValue(
                this.userDetails.orgEntityData.division
                  ? this.userDetails.orgEntityData.division
                  : ""
              );
            this.sortMasterOnchange(
              this.userDetails.orgEntityData.division,
              "div",
              this.userDetails.orgEntityData.businessUnit
            );
            this.userForm
              .get("orgEntityData")
              .get("department")
              .patchValue(
                this.userDetails.orgEntityData.department
                  ? this.userDetails.orgEntityData.department
                  : ""
              );
          }

          this.loader.stop();
        });
      },
      error => {
        this.toaster.error("something went wrong.");
        console.log(error);
      }
    );
  }

  updateUser(updateBody) {
    this.loader.start();
    this.userService.updateUser(updateBody).subscribe(
      res => {
        this.loader.stop();
        this.router.navigate([routeNames.user]);
        this.toaster.success("User updated sucessfully.");
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error("something went wrong.");
      }
    );
  }
  updateSubmitedData() {
    const data = {
      emailId: this.userId,
      body: {
        emailId: this.userForm.get("emailId").value,
        displayName: this.userForm.get("displayName").value,
        team: this.userForm.get("team").value,
        role: this.userForm.get("role").value,
        status: this.userForm.get("status").value,
        reportsTo: this.userForm.get("reportsTo").value,
        orgEntityData: {
          businessUnit: this.userForm.get("orgEntityData").get("businessUnit")
            .value,
          division: this.userForm.get("orgEntityData").get("division").value,
          department: this.userForm.get("orgEntityData").get("department").value
        }
      }
    };
    this.dataSubmitted = true;
    if (this.userForm.valid) {
      this.updateUser(data);
    }
  }

  public getOrgEntities(callback?) {
    this.orgEntitiesData = [];
    this.loader.start();
    this.shared.getMasterAccToType(MasterTypes.orgEntities).subscribe(
      res => {
        (this.orgEntitiesData = this.shared.sortMasterDataForEntities(
          res.body[0].data
        )),
          (this.businessUnits = []);
        this.orgEntitiesData.forEach(el => {
          let obj = {
            code: el.code,
            title: el.title
          };
          this.businessUnits.push(obj);
        });
        this.loader.stop();
        console.log(this.orgEntitiesData);
        if (callback) {
          callback();
        }
      },
      error => {
        this.toaster.error("something went wrong.");
        console.log(error);
      }
    );
  }

  //on click of buiness unit , division this should change alse will be used in get user data
  //actoin:bu/div trigger:get/change
  sortMasterOnchange(value, action, bu?) {
    console.log(value, action, bu);
    // let value = event.target.value;
    if (action == "bu") {
      this.divisions = [];
      this.department = [];
      this.userForm
        .get("orgEntityData")
        .get("division")
        .patchValue("");
      this.userForm
        .get("orgEntityData")
        .get("department")
        .patchValue("");
      this.orgEntitiesData.forEach(el => {
        if (el.code === value) {
          el.items.forEach(element => {
            let obj = {
              code: element.code,
              title: element.title
            };
            this.divisions.push(obj);
          });
        }
      });
    } else if (action == "div") {
      if (bu) {
        this.department = [];
        this.userForm
          .get("orgEntityData")
          .get("department")
          .patchValue("");
        this.orgEntitiesData.forEach(el => {
          if (el.code === bu) {
            el.items.forEach(element => {
              if (element.code === value) {
                element.items.forEach(elem => {
                  let obj = {
                    code: elem.code,
                    title: elem.title
                  };
                  this.department.push(obj);
                });
              }
            });
          }
        });
      }
    }
  }

  _keyName(event: any) {
    const pattern = /[a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
