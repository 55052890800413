import { Component, OnInit } from "@angular/core";
import { routeNames } from "src/app-settings/routes";
import { AuthService } from "./../../services/auth/auth.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.auth.federatedSignout();
    localStorage.clear();
  }
  // redirecting to login page
  redirectToLoginPage() {
    this.router.navigate([""]);
  }
}
