import { AuthService } from "../../services/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RatingModalComponent } from "./components/rating-modal/rating-modal.component";
import { Router } from "@angular/router";
import { OfferService } from "src/app/services/offer/offer.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { EngagementService } from "src/app/services/engagement/engagement.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-start-engagement",
  templateUrl: "./start-engagement.component.html",
  styleUrls: ["./start-engagement.component.scss"]
})
export class StartEngagementComponent implements OnInit {
  searchCandidatename: "";
  getCandidateIdData: any;
  candidateData: any[] = [];
  candidateEngagtementData: any;
  indexVlaue: any;
  candidateId: any;
  personRating: any;
  increaseInRating: any;
  incDec: any;
  dataToAppCandidate: any;
  totalActivity: any;
  engagmentfinalDate: any;
  disablePage: boolean;
  offers: any;
  tempCandidateData: any;
  optionSelected: any;
  constructor(
    private modalService: NgbModal,
    public sharedService: SharedService,
    private router: Router,
    private offerService: OfferService,
    private engagementService: EngagementService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    private auth: AuthService
  ) {
    this.getAllOffers(); // call the all candidates of org
    this.increaseInRating = false;
    this.dataToAppCandidate = {};
    this.personRating = "0.0";
    this.disablePage = false;
  }

  ngOnInit() {
    this.handleRole();
  }

  // handle role statements
  handleRole() {
    console.log(this.router.url.substring(1));
    this.disablePage = this.auth.roleViewMapping(
      this.auth.getAuthRole(),
      this.router.url.substring(1)
    ); // skipping first /
  }

  // getting all offers of the organization
  getAllOffers() {
    this.loader.start();
    this.auth.getCurrentAuthUser().subscribe((user) => {
      this.engagementService.getAllEngagementData((localStorage.getItem("userRole") === 'GM') ? '' : user.attributes.email).subscribe(
        res => {
          this.candidateData = res.body;
          this.offerService.getAllOffers('').subscribe(
            res => {
              this.loader.stop();
              this.offers = res.body;
              console.log(res);
              this.checkForoffers();
            },
            err => {
              this.loader.stop();
            }
          )
          console.log('candidateData >>',this.candidateData)
          // rating data
          // this.candidateData.forEach(el => {
          //   if (el.changeInRating) {
          //     let incDec = parseFloat(el.changeInRating);
          //     this.increaseInRating = incDec >= 0 ? true : false;
          //     el.increaseInRating = incDec >= 0 ? true : false;
          //     el.incDec = Math.abs(incDec).toString();
          //     this.incDec = el.incDec;
          //   }
          // });
          // if (this.candidateData.length > 0) {
          //   // call allways first candidate  data of candidates list
          //   this.candidatesDetails(this.candidateData[0], 0);
          // }
        },
        err => {
          this.loader.stop();
        }
      );
    }, error => {
      this.loader.stop();
      this.toaster.error("Error getting current user");
    })
  }

  checkForoffers() {
    this.offers.forEach(e => {
      console.log(e);
    });
    this.candidateData.forEach((e, index) => {
      this.offers.forEach(w => {
        if(e.offerId === w.offerId){
          this.candidateData[index]['offerStatus'] = w.latestStatus.statusCode;
        }
      });
    })
    if (this.candidateData.length > 0) {
      // call allways first candidate  data of candidates list
      this.candidatesDetails(this.candidateData[0], 0);
    }
    this.tempCandidateData = this.candidateData;
    console.log(this.tempCandidateData);
  }

  // get candidates engagement  details
  candidatesDetails(data, index) {
    this.candidateEngagtementData = data;
    console.log(this.candidateEngagtementData);
    this.getOfferDetailsByOferID(this.candidateEngagtementData.offerId);
    this.indexVlaue = index;
  }
  // GET ENGAGEMENT DATA FOR OFFER
  getOfferDetailsByOferID(data) {
    const payload = {
      offerId: data.offerId
    };
    this.offerService.GetOfferDeatilsByOferID(payload).subscribe(res => {
      if (res.body) {
        this.candidateId = res.body.candidateData.id;
      }
    });
  }
  // open ratings  model
  openratinggmodal() {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: "lg",
      backdrop: "static",
      centered: true
    });
    modalRef.componentInstance.modalData = {};
  }

  handleRating(e) {
    if (e.average && (e.incDec != 0 || e.incDec == "0.0")) {
      this.candidateData.forEach(el => {
        if (el.offerId === e.offerId) {
          console.log(el)
          el.ratingData = e.average.toString();
          let clonedIncDec = JSON.parse(JSON.stringify(e));
          let incDec = e.incDec;
          this.increaseInRating = incDec >= 0.0 ? true : false;
          el.increaseInRating = incDec >= 0.0 ? true : false;
          el.incDec = Math.abs(incDec).toString();
          this.incDec = el.incDec;
          // data to candidate page
          this.personRating = el.ratingData;
          this.dataToAppCandidate = el; // sending data to app candidate
          this.engagementService
            .updateEngMeta(
              e.offerId,
              null, //candidate name is optional
              {
                ratingData: e.average.toString(),
                changeInRating: clonedIncDec.incDec
              }
            )
            .subscribe(
              res => {
                // nothing to do
              },
              error => {
                this.toaster.error("Something went wrong");
              }
            );
        }
      });
    } else {
      this.personRating = "0";
      this.dataToAppCandidate = {}; // sending data to app candidate
    }
  }

  AddtoTotalData(e) {
    this.totalActivity = e
  }

  addengagmentfinalDate(e){
    this.engagmentfinalDate = e;
  }

  checkOfferStatus(x){
    switch (x) {
      case 'JND':
        return '#d9d9d9';
        break;
      case 'EEX':
        return '#d9d9d9';
        break;
      case 'WDN':
        return '#d9d9d9';
        break;
      default:
        return ;
        break;
    }
  }

  sortCandidates(x){
    switch (x) {
      case 'All Candidates':
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          this.candidateData.push(e);
        });
        break;
      case 'Very High':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.ratingData && e.ratingData == "Very high"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'High':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.ratingData && e.ratingData == "High"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'Medium':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.ratingData && e.ratingData == "Medium"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'Low':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.ratingData && e.ratingData == "Low"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'Joined':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.offerStatus && e.offerStatus == "JND"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'Dropout':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.offerStatus && e.offerStatus == "EEX"){
            this.candidateData.push(e);
          }
        });
        break;
      case 'Not Engaged':
        // console.log(this.tempCandidateData)
        this.candidateData = [];
        this.tempCandidateData.forEach(e => {
          if(e.offerStatus && e.offerStatus == "WDN"){
            this.candidateData.push(e);
          }
        });
        break;
      default:
        break;
    }
    if (this.candidateData.length > 0) {
      // call allways first candidate  data of candidates list
      this.candidatesDetails(this.candidateData[0], 0);
    }
    console.log(x);
    console.log(this.optionSelected);
  }

  checkOfferStatusVal(x){
    switch (x) {
      case 'JND':
        return 'Joined';
        break;
      case 'EEX':
        return 'Drop Out';
        break;
      case 'WDN':
        return 'Not Engaged';
        break;
      default:
        break;
    }
  }

  clearFilters() {
    this.searchCandidatename = '';
    this.optionSelected = '';
    this.candidateData = [];
    this.tempCandidateData.forEach(e => {
      this.candidateData.push(e);
    });
    if (this.candidateData.length > 0) {
      // call allways first candidate  data of candidates list
      this.candidatesDetails(this.candidateData[0], 0);
    }
  }
}
