import { MasterService } from "./../../services/master/master.service";
import { UserService } from "./../../services/user/user.service";
import { routeNames } from "../../../app-settings/routes";
import { AuthService } from "../../services/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { OrganizationService } from "../../services/organization/organization.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  noOrg: boolean;
  orgHandle: string;
  unAuthUser: boolean;
  organizationHandle: string;
  constructor(
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private toaster: ToastrService,
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private masterService: MasterService,
    private title: Title,
    private organizationService: OrganizationService,
    private loader: NgxUiLoaderService
  ) {
    this.orgHandle = null;
    this.noOrg = false;
    this.unAuthUser = false;
  }
  ngOnInit() {
    this.initialize();
  }

  //for now we are grtting orghandle by params later we will change it to *.tek4biz.in and configure in route53 and pick up data from path
  initialize() {
    //checking if there is a user that is authenticated previously

    this.route.params.subscribe(res => {
      if (!_.isEmpty(res)) {
        this.orgHandle = res.id; //getting orghandle
        localStorage.setItem("orgHandle", this.orgHandle);
        this.getOrgDetails("before");
      }
    });
    //handling token parameters getting from amplify
    this.route.fragment.subscribe(res => {
      //fragment because now it is the token auth
      if (localStorage.getItem("orgHandle") && _.isEmpty(res)) {
        this.orgHandle = localStorage.getItem("orgHandle");
        this.getOrgDetails("after", () => {
          this.checkIfSameUser(res => {
            //checking if user exists  with the orgHandle in localstorage
            if (!res) {
              this.unAuthUser = true;
              //navigating to same page but with parameters
              setTimeout(() => {
                this.router.navigate([routeNames.logout]);
              }, 5000);
            }
          });
        });
      } else if (!_.isEmpty(res)) {
        this.orgHandle = localStorage.getItem("orgHandle");
        this.getOrgDetails("after", () => {
          this.checkIfSameUser();
        });
      } else {
        //showing message if no org is in the system
        this.noOrg = true;
      }
    });
  }

  //api call to get org Details
  getOrgDetails(action, callback?) {
    this.orgService.getOrgDetails(this.orgHandle).subscribe(
      res => {
        let orgDetails = res.body;
        this.setTitleOfPage(orgDetails);
        if (action === "before") {
          this.setAwsConfig(orgDetails);
          this.openHostedUI();
        } else {
          this.setAwsConfig(orgDetails);
          callback();
        }
      },
      error => {
        this.toaster.error("Something went wrong");
      }
    );
  }

  //open hosted UI
  openHostedUI() {
    this.auth.federatedHostedUI().subscribe(
      res => {
        console.log(res); //automatic redirection of app after this
      },
      error => {
        this.toaster.error("Something went wrong");
      }
    );
  }

  //getting current user and verifying if it is the user that exists in the system or not so api to get user details
  checkIfSameUser(callback?) {
    this.auth.getCurrentAuthUser().subscribe(
      res => {
        let userEmail = res.attributes.email; //email of the oauth user
        this.userService.getUserDetails(userEmail).subscribe(
          res => {
            if (res.body && res.body.approvalStatus && res.body.status) {
              if (res.body.orgEntityData) {
                console.log(res.body);
                const userDetailsData: any = {
                  name: res.body.displayName,
                  email: res.body.emailId,
                  role: res.body.role,
                  businessUnit: res.body.orgEntityData.businessUnit,
                  division: res.body.orgEntityData.division
                };
                localStorage.setItem("_user_dim", JSON.stringify(userDetailsData));
              } else {
                const userDetailsData: any = {
                  name: res.nody.displayName,
                  email: res.body.emailId,
                  role: res.body.role,
                  businessUnit: '',
                  division: ''
                };
                localStorage.setItem("_user_dim", JSON.stringify(userDetailsData));
              }

              //checking if user is in the system and is approved
              this.auth.getOrgRoles().subscribe(
                res => {
                  this.auth.saveRolesLocally(res.body[0].data[0].data.items); //stoting roles locally
                },
                error => {
                  console.log(error);
                }
              );
              this.auth.setAuthRole(res.body.role); //setting auth role on signin
              const updateBody = {
                //update last signed in
                lastSignedIn: new Date().getTime()
              };
              this.userService
                .updateUserDetails(userEmail, updateBody)
                .subscribe(
                  res => {
                    this.navigateToRequiredRoute();
                  },
                  error => {
                    this.toaster.error("Something went wrong");
                  }
                );
            } else {
              this.auth.federatedSignout();
              if (callback) {
                callback(false);
              }
            }
          },
          error => {
            this.toaster.error("Something went wrong");
          }
        );
      },
      error => {
        if (callback) {
          callback(false);
        }
      }
    );
  }
  //implement whether to navigate or throw him out
  navigateToRequiredRoute() {
    this.getAllMasterDataInit(() => {
      //storing all master in locastorage
      let route = localStorage.getItem("route")
        ? localStorage.getItem("route")
        : "dashboard";
      let timestamp = parseInt(localStorage.getItem("routeTimeStamp"));
      let currentTime = moment();
      let timeDiff = currentTime.diff(moment(timestamp), "minutes");
      if (timeDiff < 30) {
        this.router.navigate([route]);
      } else {
        localStorage.removeItem("route");
        localStorage.removeItem("routeTimeStamp");
        this.router.navigate([routeNames.dashboard]);
      }
    });
  }

  //should be a service that does it
  setAwsConfig(orgDetails) {
    let oathDetails = {
      oauth_domain: orgDetails.otherDetails.oauth_domain,
      oauth_scope: orgDetails.otherDetails.oauth_scope,
      oauth_redirectedSignIn: orgDetails.otherDetails.oauth_redirectedSignIn,
      oauth_redirectedSignOut: orgDetails.otherDetails.oauth_redirectedSignOut,
      oauth_responseType: orgDetails.otherDetails.oauth_responseType
    };
    this.auth.setAwsConfigForOrg(
      orgDetails.otherDetails.userpoolId,
      orgDetails.otherDetails.appClientId,
      orgDetails.otherDetails.appClientSecret,
      oathDetails
    );
  }

  getAllMasterDataInit(callback) {
    this.masterService.getAllMaster().subscribe(
      res => {
        localStorage["_master"] = JSON.stringify(res); //storing all response in localstorage
        callback();
      },
      error => {
        console.log("");
      }
    );
  }

  setTitleOfPage(orgDetails) {
    this.title.setTitle(orgDetails.organizationName);
  }

  //redirect to login by putting org handle
  redirectToLogin() {
    if (this.organizationHandle) {
      // lookup in db to check if organization exists or not
      this.loader.start()
      this.organizationService.getOrgConfigDetails(this.organizationHandle).subscribe((res) => {
        this.loader.stop()
        if (res.body && res.body.orgHandle) {
          this.router.navigate([`login/${this.organizationHandle}`]);
        }
      }, error => {
        this.loader.stop()
        this.toaster.error("Error getting organization data. Please check the organization handle");
      })
    }
    else {
      this.toaster.error("Please enter organization handle");
    }
  }
}
