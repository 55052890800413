import { AuthGuard } from './guard/auth.guard';
import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { OffersComponent } from './pages/offers/offers.component';
import { GenerateOffersComponent } from './pages/generate-offers/generate-offers.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { EngagementTrackerComponent } from './pages/engagement-tracker/engagement-tracker.component'
import { JoinersandDropoutComponent } from  './pages/joinersand-dropout/joinersand-dropout.component';
import { StartEngagementComponent } from './pages/start-engagement/start-engagement.component';
import { RecruiterOffersComponent } from './pages/recruiter-offers/recruiter-offers.component';
import { UsersComponent } from './pages/users/users.component';
import { routeNames } from '../app-settings/routes';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SysAdminOrganizationComponent } from './pages/sys-admin-organization/sys-admin-organization.component';
import { SettingsComponent } from './pages/users/component/settings/settings.component';
import { AddUserComponent } from './pages/users/component/add-user/add-user.component';
import { OrganizationalEntitiesComponent } from './pages/organizational-entities/organizational-entities.component';
import { IntegrationComponent } from './pages/integration/integration.component';
import { SettingsDashboardComponent } from './pages/settings-dashboard/settings-dashboard.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { CandidateDetailsComponent } from './pages/candidate-details/candidate-details.component';
import { MasterDataComponent } from './pages/master-data/master-data.component';
import { EngageListComponent } from './pages/master-data/components/engage-list/engage-list.component';
import { EngagementTemplateComponent } from './pages/master-data/components/engagement-template/engagement-template.component';

export const routes: Routes = [
  { path: '', component: LoginComponent }, // login component
  { path: routeNames.login + '/:id', component: LoginComponent }, // login component with id
  { path: routeNames.logout, component: LogoutComponent }, // logout component
  {
    path: routeNames.offers,
    component: OffersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.report,
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.engagmentTracker,
    component: EngagementTrackerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.joinersandDropout,
    component: JoinersandDropoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.newOffer,
    component: GenerateOffersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.engagement,
    component: StartEngagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.adduser,
    component: AddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.updateUser,
    component: AddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.offer,
    component: RecruiterOffersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.user,
    component: UsersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.dashboard,
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.sysadminOrganization,
    component: SysAdminOrganizationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.usersettings,
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.organizationalentities,
    component: OrganizationalEntitiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.integration,
    component: IntegrationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.dashsettings,
    component: SettingsDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.candidateDetails + '/:id',
    component: CandidateDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: routeNames.masterData,
    component: MasterDataComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: routeNames.engagementListing.relativeRoute, pathMatch: 'full' },
      {
        path: routeNames.engagementListing.relativeRoute, component: EngageListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: routeNames.engagementTemplateSetup.relativeRoute, component: EngagementTemplateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: routeNames.engagementTemplateSetup.routeWithId, component: EngagementTemplateComponent,
        canActivate: [AuthGuard]
      }

    ]
  },


];
