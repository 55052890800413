import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MasterService } from 'src/app/services/master/master.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-master-data',
  templateUrl: './update-master-data.component.html',
  styleUrls: ['./update-master-data.component.scss']
})
export class UpdateMasterDataComponent implements OnInit {
  engagementForm: FormGroup;
  formElement: FormArray;
  @Input() itemMasterData: any;
  @Input() mode: any;
  @Output() editEngagementMasterResponse = new EventEmitter();
  constructor(private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private masterService: MasterService,
    private loader: NgxUiLoaderService) { }

  ngOnInit() {
    this.initForm();
    if (this.mode === 'edit') {
      this.engagementForm.get('code').setValue(this.itemMasterData.code);
      this.formUpdate();
    }
  }

  formUpdate() {
    if (this.itemMasterData && this.engagementForm) {
      for (let index = 1; index < this.itemMasterData.data.items.length; index++) {
        this.addSubItems();
      }
      this.engagementForm.get('items').patchValue(this.itemMasterData.data.items);
    }
  }


  initForm() {
    this.engagementForm = this.fb.group({
      code: ['', Validators.required],
      items: this.fb.array([this.createSubItems])
    });
  }

  get createSubItems(): FormGroup {
    return this.fb.group({
      code: ['', Validators.required],
      title: ['', Validators.required]
    });
  }
  get formArray(): FormArray {
    return this.engagementForm.get('items') as FormArray
  }

  public addSubItems(): void {
    this.formElement = this.engagementForm.get('items') as FormArray;
    this.formElement.push(this.createSubItems);
  }

  public removeSubItems(index) {
    if (this.formElement.length > 1) {
      this.formElement.removeAt(index);
    }
  }
  submittedData() {
    if (this.engagementForm.valid) {
      const submittedData = {
        masterType: 'engagement',
        code: this.engagementForm.get('code').value,
        data: {
          items: this.engagementForm.get('items').value
        }
      };
      if (this.mode === 'edit') {
        this.updateEngegementMasterData(submittedData)
      } else {
        this.createEngegementMasterData(submittedData);
      }
    } else {
      this.toaster.error('Please fill all the fields');
    }
  }

  createEngegementMasterData(data) {
    this.loader.start();
    this.masterService.createMaster(data).subscribe(
      res => {
        this.activeModal.close();
        this.loader.stop();
        this.editEngagementMasterResponse.emit(res);
        this.toaster.success('Data created sucessfully');
      },
      error => {
        this.loader.stop();
        this.toaster.error('Somthing went wrong');
      }
    );
  }

  updateEngegementMasterData(data) {
    this.loader.start();
    this.masterService.udpateMaster(data).subscribe(
      res => {
        this.loader.stop();
        this.activeModal.close();
        this.editEngagementMasterResponse.emit(res);
        this.toaster.success('Data updated sucessfully');
      },
      error => {
        this.loader.stop();
        this.toaster.error('Somthing went wrong');
      }
    );
  }
  closeModal() {
    this.activeModal.close();
  }
}
