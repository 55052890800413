import { SharedService } from './../../../services/shared/shared.service';
import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { NgbModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SearchCandidateComponent } from 'src/app/pages/generate-offers/components/search-candidate/search-candidate.component';
import { FormGroup } from '@angular/forms';
import { OfferService } from 'src/app/services/offer/offer.service';
import { NgbDateFRParserFormatter } from './dateformat';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class OfferFormComponent implements OnChanges, OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input() form: FormGroup;
  @Input() offerSubmittedState: string;
  @Input() masters: any;
  @Output() outputData = new EventEmitter();
  @Input() users: any;
  @Input() s3Files: any;
  @Input() businessUnitData: any;
  @Input() updateStatus: boolean;
  @Input() JoiningDateStatus: boolean;
  @Input() readOnlyFlag: boolean;
  @Input() updateOfferStatus: any;
  @Output() changeOfferStatus = new EventEmitter();
  @Output() offerFormDataInfo = new EventEmitter();
  nrSelect: string = 'Rs.';
  fileToOfferUpload: File = null;
  now: Date;
  uploadedOfferFileObject = {};
  toUploadOfferFiles: any = [];
  model1;
  fileData: any;
  dataType: any;
  fileFormat: any;
  submitted = 'false';
  outputDataObj: any;
  masterData: any;
  divisions: any;
  departments: any;
  locations: any;
  roles: any;
  status: any;
  isChecked: boolean;
  uploadedOfferFileData: any[] = [];
  fileDetails: any;
  file: any;
  s3FileDataArray: any[] = [];
  s3ViewFlag: boolean;
  businessUnit: any[] = [];
  divisionData: any[] = [];
  businessUnitValue: any;
  divisonValue: any;
  offerFormArray: any[] = [];
  offerFormObj = {};
  accectPdfOnly: boolean;
  minDobDate = { year: 1950, month: 1, day: 1 };
  minDate: any;
  resumeSource: any;
  currentUser: any;
  constructor(
    private modalService: NgbModal,
    private shared: SharedService,
    private offerService: OfferService,
    public userService: UserService,
    public authService: AuthService
  ) {
    this.outputDataObj = {
      candidateSearchData: null
    };
    (this.divisions = []), (this.departments = []);
    this.roles = [];
    this.status = [];
    this.accectPdfOnly = false;
    // this.userService.getUser();
    this.getCurrentUser();
    this.minDate = this.shared.setDateDefault();
  }

  ngOnChanges(changes: any): void {
    this.submitted = this.offerSubmittedState;
    if (changes && changes.masters && changes.masters.currentValue) {
      this.masters = changes.masters.currentValue;
      if (!Array.isArray(this.masters)) {
        this.masterData = this.shared.sortMasterDataForEntities(
          this.masters.entities
        );
        if (this.masters.locations.length > 0) {
          this.locations = this.masters.locations[0].data.items;
        }
        if (this.masters.roles.length > 0) {
          this.roles = this.masters.roles[0].data.items;
        }
        if (this.masters.activity.length > 0) {
          const commonStatus = this.masters.activity[0].data.items;
          const offerStatus = this.masters.offerStatus[0].data.items;
          this.status = commonStatus.concat(offerStatus);
        }
      }
      this.getMasterValues(); // call master data for all master data
      // this.getDivisionData(this.businessUnitValue); // get all the incomning divisions values
      // this.getDepartMent(this.divisonValue); // get all the incomning department  values
      this.getResumeData();
    }

    if (this.users) {
      this.sortUsersByRole();
    }
    if (changes && changes.s3Files && changes.s3Files.currentValue) {
      // getting the s3 uploaded files
      this.s3Files = changes.s3Files.currentValue;
      if (this.s3Files) {
        this.s3ViewFlag = true;
        this.s3FileDataArray.push({
          fileName: this.s3Files
        });
      }
    }
    if (
      changes &&
      changes.businessUnitData &&
      changes.businessUnitData.currentValue
    ) {
      // getting the divison Value and businessUnit Value
      this.businessUnitData = changes.businessUnitData.currentValue;
      this.businessUnitValue = this.businessUnitData.businessUnit;
      this.divisonValue = this.businessUnitData.divisionData;
      this.getDivisionData(this.businessUnitValue);
      this.getDepartMent(this.divisonValue);
    }
    if (
      changes &&
      changes.updateOfferStatus &&
      changes.updateOfferStatus.currentValue
    ) {
      const status = JSON.parse(this.updateOfferStatus.campusHire);
      const downloadStatus = JSON.parse(this.updateOfferStatus.downloadConfig);
      const criDisclaimer = JSON.parse(this.updateOfferStatus.criDisclaimer);
      this.campusHireCheck(status);
      this.candidateEngagementAcceptance(downloadStatus);
      this.getCriDisclaimer(criDisclaimer);
    }
  }
  ngOnInit() {
    this.s3ViewFlag = false; //  set flag for hide the  s3 files  show block
  }

  // search candidate modal
  searchCandidateModal() {
    const modalRef = this.modalService.open(SearchCandidateComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.modalData = {};
    modalRef.result.then(res => {
      if (typeof res === 'object') {
        this.outputDataObj.candidateSearchData = res;
        this.outputData.emit(this.outputDataObj); // sending data to parent component
      }
    });
  }
  // get location array
  getResumeData() {
    if (!Array.isArray(this.masters)) {
      this.resumeSource = this.masters.resumeSources[0].data.items;
    }
  }

  // get the master data
  getMasterValues() {
    if (this.masterData) {
      this.masterData.forEach(element => {
        this.businessUnit.push({
          // create business unit data
          code: element.code,
          title: element.title,
          items: element.items
        });
      });
    }
  }
  // get division Data call
  getDivisionData(code) {
    if (this.businessUnit.length > 0) {
      this.businessUnit.forEach(element => {
        if (element.code === code) {
          this.divisionData = element.items;
        }
      });
    }
  }
  // get departments data call
  getDepartMent(code) {
    this.divisionData.forEach(element => {
      if (element.code === code) {
        this.departments = element.items;
      }
    });
  }

  // sort users by roles
  sortUsersByRole() {
    // this.users.forEach(element => {
    //   console.log(element);
    // });
  }
  getCurrentUser() {
    this.authService.getCurrentAuthUser().subscribe(
      res => {
        this.currentUser = res.attributes.email;
        const currentUser = localStorage.getItem("userRole")
        if (currentUser === "RCTM") {
          this.form.get('recruiter').setValue(this.currentUser);
        }
      })
  }

  // select offer file upload
  onFileChanged(e) {
    if (
      e.target.value.search('pdf') === -1 &&
      e.target.value.search('PDF') === -1
    ) {
      this.accectPdfOnly = true;
      this.uploadedOfferFileData = [];
      this.form.get('offerDocument').setValue(this.uploadedOfferFileData);
    } else {
      this.accectPdfOnly = false;
      this.fileDetails = e;
      this.shared.onFileChanged(this.fileDetails).then((data: any) => {
        const fileData = data.fileData.split(',')[1];
        const dataType = data.fileData.split('/')[0].split(':')[1];
        const fileFormat = data.fileData.split('/')[1].split(';')[0];
        const uploadFilepayload = {
          fileData,
          folder: 'assets',
          dataType: 'file',
          fileFormat,
          fileName: data.fileName
        };
        this.uploadedOfferFileData.push(uploadFilepayload);
        this.s3ViewFlag = false;
        this.form.get('offerDocument').setValue(this.uploadedOfferFileData);
      });
    }
  }
  // delete the file
  deleteRowoffer(i) {
    this.uploadedOfferFileData.splice(i, 1);
    this.fileDetails.target.value = '';
  }
  // deleteS3Fiels the file
  deleteS3Fiels(i) {
    this.s3FileDataArray.splice(i, 1);
    this.form.get('offerDocument').setValue('');
  }
  // campus hire checked
  campusHireCheck(checked) {
    this.form.get('campusHire').setValue(checked);
  }

  // engagement acceptance
  candidateEngagementAcceptance(data) {
    this.form.get('downloadConfig').setValue(data);
  }
  // engagement acceptance
  getCriDisclaimer(data) {
    this.form.get('criDisclaimer').setValue(data);
  }
  // offerStatus changes event
  checkOfferStatus(value) {
    this.changeOfferStatus.emit(value);
  }
  // get offer form data for insert variable
  updateModel(event) {
    if (event) {
      this.offerFormDataInfo.emit("data changed");
    }
  }

  // for input type number
  _keyPress(event: any) {
    const pattern = /[0-9+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  _keyName(event: any) {
    const pattern = /[a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  _keyNotice(event: any){
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }

  }
  _keyEmail(event:any){

  }
  _keyDate(event: any) {
    const pattern = /[0-9-]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
    else{
      return false;
    }
  }
}
