import { uploadBody } from "./../../../../../../candidate/src/app/models/candidate";
import { MasterService } from "./../../../services/master/master.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, Input } from "@angular/core";

interface createBody {
  masterType: null;
  code: null;
  data: {
    parentCode?: string;
    items: any[];
  };
}
@Component({
  selector: "app-org-entity-modal",
  templateUrl: "./org-entity-modal.component.html",
  styleUrls: ["./org-entity-modal.component.scss"]
})
export class OrgEntityModalComponent implements OnInit {
  @Input("type") type: any;
  @Input("code") code: any;
  @Input("title") codeTitle: any;
  @Input("divCode") divCode: any;
  @Input("buCode") buCode: any;
  @Input("masterData") masterData: any;
  header: any;
  fieldName: any;
  selectBoxData: any;
  divisionMasterCode: any;
  departmentMaterCode: any;
  businessUnitMasterCode: any;
  masterType: any;

  codeName: any;
  title: any;
  field: any;
  createBody: createBody;
  allCodes: any[];
  disableSave: any;
  disableDelete: any;
  buForDivisionsPage: any[];
  buCodeForDep: any;

  constructor(
    private activeModal: NgbActiveModal,
    private masterService: MasterService
  ) {
    this.header = "";
    this.selectBoxData = [];
    (this.divisionMasterCode = "DIVISION"),
      (this.departmentMaterCode = "DEPARTMENT"),
      (this.businessUnitMasterCode = "BUSINESSUNIT");
    this.masterType = "orgEntities";
    this.createBody = {
      code: null,
      masterType: null,
      data: {
        items: []
      }
    };
    this.createBody.masterType = this.masterType;
    this.allCodes = [];
    this.disableSave = false;
    (this.codeName = ""), (this.field = ""), (this.title = "");
    this.disableDelete = false;
    this.buForDivisionsPage = [];
  }

  ngOnInit() {
    this.getCodes();
    if (this.code) {
      this.codeName = this.code;
    }
    if (this.codeTitle) {
      this.title = this.codeTitle;
    }
    if (this.buCode) {
      this.buCodeForDep = this.buCode;
    }
    this.manageHeaders(this.type);
  }

  closeModal() {
    this.activeModal.close();
  }

  manageHeaders(type) {
    switch (type) {
      case "BU":
        this.header = "Business Unit";
        if (this.masterData.length > 0) {
          this.masterData.forEach(element => {
            if (
              element.code === this.code &&
              element.items &&
              element.items.length > 0
            ) {
              this.disableDelete = true;
              element.items.forEach(el => {
                if (el.items && el.items.length > 0) {
                  this.disableDelete = true;
                }
              });
            }
          });
          // this.disableDelete = true;
        }
        break;
      case "DIV":
        if (this.divCode) {
          this.field = this.divCode;
        }
        this.header = "Division";
        this.fieldName = "Business Unit";
        this.masterData.forEach(element => {
          let data = {
            businessUnit: null,
            division: element
          };
          if (element.items && element.items.length > 0) {
            element.items.forEach(el => {
              if (el.code === this.code) {
                if (el.items && el.items.length > 0) {
                  this.disableDelete = true;
                }
              }
            });
          }
          this.selectBoxData.push(data);
        });
        this.createBody.code = this.businessUnitMasterCode;
        break;
      case "DEP":
        this.header = "Department";
        this.fieldName = "Division";
        this.masterData.forEach(element => {
          let data = {
            code: element.code,
            title: element.title
          };
          this.buForDivisionsPage.push(data);
        });
        if (this.code) {
          this.sortMasterOnchange(this.buCodeForDep, "bu");
        }
        if (this.divCode) {
          this.field = this.divCode;
        }
        break;
    }
  }

  sortMasterOnchange(value, action) {
    if (action == "bu") {
      this.selectBoxData = [];
      this.masterData.forEach(el => {
        if (el.code === value && el.items && el.items.length > 0) {
          el.items.forEach(element => {
            let obj = {
              businessUnit: el,
              division: element
            };
            this.selectBoxData.push(obj);
          });
        }
      });
    }
  }

  getCodes() {
    this.masterData.forEach(element => {
      this.allCodes.push(element.code);
      if (element.items && element.items.length > 0) {
        element.items.forEach(el => {
          this.allCodes.push(el.code);
          if (el.items && el.items.length > 0) {
            el.items.forEach(ele => {
              this.allCodes.push(ele.code);
            });
          }
        });
      }
    });
  }

  //!detecting changes in modalinput (changes required)
  detectChange() {
    if (!this.code) {
      if (this.allCodes.includes(this.codeName)) {
        this.disableSave = true;
      } else {
        this.disableSave = false;
      }
    }
  }

  saveData(action?) {
    let empty = false;
    this.field = this.type === "BU" ? "nothing" : this.field;
    if (this.codeName === "" || this.field === "" || this.title === "") {
      console.log("fields are empty");
    } else {
      this.disableSave = true;
      //creating new data
      if (this.type === "DIV") {
        this.createBody.code = this.divisionMasterCode;
        this.createBody.data.parentCode = this.field;
        this.masterData.forEach(element => {
          if (
            element.code === this.field &&
            element.items &&
            element.items.length > 0
          ) {
            element.items.forEach(el => {
              this.createBody.data.items.push({
                code: el.code,
                title: el.title
              });
            });
          }
        });
        if (this.code) {
          //update code
          this.createBody.data.items.forEach((el, index) => {
            if (this.code === el.code) {
              if (action) {
                //delete
                this.createBody.data.items.splice(index, 1);
              } else {
                el.title = this.title;
              }
              if (this.createBody.data.items.length == 0) {
                empty = true;
              }
            }
          });
        } else {
          this.createBody.data.items.push({
            code: this.codeName,
            title: this.title
          });
          if (this.createBody.data.items.length == 1) {
            empty = true;
          }
        }
      } else if (this.type == "BU") {
        this.createBody.code = this.businessUnitMasterCode;
        this.masterData.forEach(el => {
          this.createBody.data.items.push({
            code: el.code,
            title: el.title
          });
        });

        if (this.code) {
          //update code
          this.createBody.data.items.forEach((el, index) => {
            if (this.code === el.code) {
              if (action) {
                //delete
                this.createBody.data.items.splice(index, 1);
              } else {
                el.title = this.title;
              }
            }
          });
        } else {
          this.createBody.data.items.push({
            code: this.codeName,
            title: this.title
          });
        }
        if (this.createBody.data.items.length == 1) {
          empty = true;
        }
      } else if (this.type == "DEP") {
        this.createBody.code = this.departmentMaterCode;
        this.createBody.data.parentCode = this.field;
        this.masterData.forEach(element => {
          element.items.forEach(el => {
            if (el.code === this.field && el.items && el.items.length > 0) {
              el.items.forEach(ele => {
                this.createBody.data.items.push({
                  code: ele.code,
                  title: ele.title
                });
              });
            }
          });
        });

        if (this.code) {
          //update code
          this.createBody.data.items.forEach((el, index) => {
            if (this.code === el.code) {
              if (action) {
                //delete
                this.createBody.data.items.splice(index, 1);
              } else {
                el.title = this.title;
              }
            }
          });
          if (this.createBody.data.items.length == 0) {
            empty = true;
          }
        } else {
          this.createBody.data.items.push({
            code: this.codeName,
            title: this.title
          });
          if (this.createBody.data.items.length == 1) {
            empty = true;
          }
        }
      }
      if (empty && !action) {
        this.masterService.createMaster(this.createBody).subscribe(
          res => {
            console.log(res);
            this.activeModal.close("save");
          },
          error => {
            console.log(error);
          }
        );
      } else {
        this.masterService.udpateMaster(this.createBody).subscribe(
          res => {
            console.log(res);
            this.activeModal.close("save");
          },
          error => {
            console.log(error);
          }
        );
      }
      this.disableSave = false;
    }
  }
}
