import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class EngagementService {
  private apiName: string;
  private path: string;
  public engFeedbackPath: string;
  orgHandle: string;
  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.engagement;
    this.engFeedbackPath =
      apis.organizationService.organization.endpoints.engFeedback;
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // Start Engagement
  public startEngagement(data) {
    const request = this.initRequests.initStartEngagement({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: data
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // get Engagement
  public getEngagementData(data) {
    const request = this.initRequests.initGetEngagement({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        offerId: data
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // get all  Engagement data
  public getAllEngagementData(email?) {
    const request = this.initRequests.initGetAllEngagement({
      _action: "GET_ALL",
      orgHandle: this.orgHandle,
      queryBody: {
        emailId: email
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public submitFeedback(feedback) {
    const request = this.initRequests.initSubmitFeedback({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: feedback
    });
    return from(this.auth.post(this.apiName, this.engFeedbackPath, request));
  }

  public updateEngagementTask(data) {
    const request = this.initRequests.initUpdateEngagementTask({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: data
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public getEngagementFeedbackTable(offerId) {
    const request = this.initRequests.initGetEngagementFeedbackTable({
      _action: "GET_TABLE_FEEDBACK",
      orgHandle: this.orgHandle,
      queryBody: {
        offerId: offerId
      }
    });
    return from(this.auth.post(this.apiName, this.engFeedbackPath, request));
  }

  //updating eng meta
  public updateEngMeta(offerId, candidateName, body) {
    const request = this.initRequests.initUpdateEngMeta({
      _action: "UPDATE_META",
      orgHandle: this.orgHandle,
      updateBody: {
        offerId: offerId,
        candidateName: candidateName,
        body: body
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public getEngFeedbackMaster() {
    const request = this.initRequests.initFeedbackMaster({
      _action: "GET_ENG_FEEDBACK_CONFIG",
      orgHandle: this.orgHandle,

    });
    return from(this.auth.post(this.apiName, this.engFeedbackPath, request));
  }
}
