import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { EngagementTemplateService } from "../../../services/engagementTemplate/engagement-template.service";
import { ToastrService } from "ngx-toastr";
import { isNgTemplate, collectExternalReferences } from '@angular/compiler';

@Component({
  selector: "app-candidate-engagement-template",
  templateUrl: "./candidate-engagement-template.component.html",
  styleUrls: ["./candidate-engagement-template.component.scss"]
})
export class CandidateEngagementTemplateComponent implements OnInit, OnChanges {

  public show: boolean = false;

  @Output()
  public nextButtonClick = new EventEmitter<boolean>();
  @Output()
  public selectedTemplate = new EventEmitter<string>();
  @Input() previouslySelectedTemplate;

  engagementTemplates: any[] = []
  templateId: any = "";


  constructor(
    private templateService: EngagementTemplateService,
    private toaster: ToastrService) {
    this.show = true;
  }

  ngOnInit() {
    this.getEngagementTemplates();
  }

  //maintaing state from back button
  ngOnChanges(changes: any): void {
    if (changes && changes.previouslySelectedTemplate && changes.previouslySelectedTemplate.currentValue) {
      this.templateId = this.previouslySelectedTemplate;
    }


  }

  public goToEngagementConfiguration(): void {
    if (this.templateId != "") {

      this.show = !this.show;
      this.nextButtonClick.emit(this.show);
      this.selectedTemplate.emit(this.templateId);
    }
    else {
      this.toaster.error("Please select an engagement template to continue")
    }
  }

  //get templates for org
  getEngagementTemplates() {
    this.templateService.getAllTemplates().subscribe((res) => {
      this.engagementTemplates = res.body;
    }, error => {
      this.toaster.error("Error getting engagement templates")
    })
  }

}
