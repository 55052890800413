import { OfferService } from 'src/app/services/offer/offer.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { routeNames } from 'src/app-settings/routes';
@Component({
  selector: 'app-search-candidate',
  templateUrl: './search-candidate.component.html',
  styleUrls: ['./search-candidate.component.scss']
})
export class SearchCandidateComponent implements OnInit {
  @ViewChild('modalcandidate') modal: any;
  candidateDetails = {
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    dob: ''
  };
  searchObject = {} as any;
  fields: any;
  searchedCandidates: any;
  searchClicked: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private offerService: OfferService,
    private router: Router
  ) {
    this.fields = [];
    this.searchedCandidates = [];
    this.searchClicked = false;
  }
  searchCandidateModal(modalcandidate) {
    this.modalService.open(modalcandidate, { size: 'lg', centered: true });
  }

  ngOnInit() {}

  // search candidate
  searchCandidate(data) {
    this.offerService.searchCandidate(data).subscribe(res => {
      this.searchedCandidates = res.body;
      // console.log(this.searchedCandidates);
    });
  }

  // check for fields
  validateFields() {
    this.searchObject = {} as any;
    _.forOwn(this.candidateDetails, (value, key) => {
      if (value != '') {
        this.fields.push(key);
      }
    });
    if (this.fields.length > 0) {
      this.searchClicked = true;
      this.fields.forEach(element => {
        if (element === 'firstName') {
          this.searchObject['firstName'] = this.candidateDetails.firstName;
        }
        if (element === 'lastName') {
          this.searchObject['lastName'] = this.candidateDetails.lastName;
        }
        if (element === 'mobile') {
          this.searchObject['mobile'] = '+91' + this.candidateDetails.mobile;
        }
        if (element === 'email') {
          this.searchObject['email'] = this.candidateDetails.email;
        }
        if (element === 'dob') {
          this.searchObject['dob'] = this.candidateDetails.dob;
        }
      });
      this.searchCandidate(this.searchObject);
    } else {
      console.log('Fields are empty');
    }
  }

  // get candidateDetails that is selected
  candidateSelected(candidate, id) {
    this.router.navigate([routeNames.candidateDetails, id]);

    this.activeModal.close(candidate); // sending candidate value to offer form
  }
}
