import { Router } from "@angular/router";
import { OfferService } from "./../../services/offer/offer.service";
import { routeNames } from "src/app-settings/routes";
import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { SharedService } from "src/app/services/shared/shared.service";
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"]
})
export class OffersComponent implements OnInit {
  offers: any;
  offerChild: any;
  preProcessOffer: any[] = [];
  pageNumber: any;
  disableNext: any;
  authRole: string;
  currentUser: any;
  filterObj = {
    candidateName: "",
    date: "",
    offerId: "",
    businessUnit: "",
    division: "",
    department: "",
    noticePeriod: "",
    joiningDate: "",
    expiryDate: "",
    offerStatus: ""
  };
  sortObj = {};
  offerCount: any = 0;
  constructor(
    private offerService: OfferService,
    private router: Router,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    public sharedService: SharedService,
    public authService: AuthService,
  ) {
    this.offers = [];
    this.sharedService.getMastersData();
    this.pageNumber = null;
    this.disableNext = false;
    this.getUserInformation();
  }

  ngOnInit() {
    // this.getAllOffers(this.pageNumber);
    this.getUserDeatails();
  }
  getUserDeatails() {
    const userInfo: any = JSON.parse(localStorage.getItem('_user_dim'));
    if (userInfo.role === 'BU') {
      this.filterObj.businessUnit = userInfo.businessUnit;
      if (userInfo.division) {
        this.filterObj.division = userInfo.division;
      }
      this.getAllOffers(1)
    } else {
      this.getAllOffers(1, this.pageNumber);
    }
  }
  goToGenerateOffer() {
    this.router.navigate([routeNames.newOffer]);
  }

  getUserInformation() {
    this.authRole = this.authService.getAuthRole();
    this.authService.getCurrentAuthUser().subscribe((res: any) => {
      this.currentUser = res.attributes.email;
    }, (error) => {
      this.toaster.error(
        'something went wrong  while getting the user details.'
      );
    })
  }

  // getting all offers of the organization
  getAllOffers(pageNumber, lastId?) {
    this.loader.start();
    let offerPayload = {
      pageNumber,
      lastId: lastId,
      limit: "10",
      emailId: this.currentUser,
      role: this.authRole,
      filter: this.filterObj,
      sort: this.sortObj
    }
    this.offerService.getAllOffers(offerPayload).subscribe(
      res => {
        this.offers = [];
        this.offers = res.body;
        this.offerCount = res.count;
        if (this.offers.length < 10) {
          this.disableNext = true;
        } else {
          this.disableNext = false;
        }
        this.preProcessData();
        this.loader.stop();
      },
      err => {
        this.offerCount = 0;
        this.toaster.error("No Data Found.");
        this.preProcessOffer = [];
        this.loader.stop();
      }
    );
  }
  preProcessData() {
    this.preProcessOffer = [];
    this.offers.forEach(offer => {
      const array = {
        name: offer.candidateData.firstName + ' ' + (offer.candidateData.lastName ? offer.candidateData.lastName : ""),
        // lastName: offer.candidateData.lastName,
        SortOfferId: this.sharedService.getOfferIDSort(offer.offerId),
        offerId: offer.offerId,
        businessUnit: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.businessUnit
        ),
        department: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.department
        ),
        division: this.sharedService.getOrgEntitiesTitle(
          offer.organizationData.division
        ),
        noticePeriod: offer.offerMetaData.noticePeriod ? offer.offerMetaData.noticePeriod + ' ' + 'days' : "",
        expiryDate: this.sharedService.getDateFormat(
          offer.offerMetaData.expiryDate
        ),
        joiningDate: this.sharedService.getDateFormat(
          offer.offerMetaData.joiningDate
        ),
        date: this.sharedService.getDateFormat(offer.offerMetaData.date),
        designation: offer.offerMetaData.designation,
        status: this.sharedService.getOfferActivityTitle(
          offer.collectiveStatus[0].statusCode
        ),
        activity: offer.offerMetaData.activity,
        offerData: offer,
        _id: offer._id
      };
      this.preProcessOffer.push(array);
    });
  }
  // send offer details to candidate
  getSendOfferId(offerId) {
    let date = new Date();
    let currentDate = moment(date, "YYYY-MM-DD HH:mm:ss").toISOString();
    let payload = {
      offerId: offerId,
      statusCode: "ST",
      date: currentDate.toString()
    };
    this.offerService.sendOffer(payload).subscribe(
      res => {
        this.preProcessOffer = [];
        this.loader.stop();
        this.getAllOffers(1);
        this.toaster.success("Offer sent successfully");
      },
      err => {
        this.loader.stop();
        this.toaster.error("something went wrong.");
      }
    );
  }

  changePageNumber(pageObj) {
    let idToSend = null;
    let pageNum = 1;
    if (pageObj === null) {
      idToSend = null;
    }
    else {
      idToSend = pageObj.lastId
      pageNum = parseInt(pageObj.pageNumber)
    }
    this.getAllOffers(pageNum, idToSend);
  }

  getFilterObj(filterObj) {
    this.filterObj = filterObj;
    this.getAllOffers(1);
  }
  getSortData(sortData) {
    this.sortObj = sortData;
    this.getAllOffers(1);
  }
}
