export const aws = {
  region: "ap-south-1"
  //  userpoolId: 'ap-south-1_kFjE65pTp',
  //  identityPoolId: 'ap-south-1:672193d1-82e3-4261-bfee-0a0a6b182259',// not yet defined for application
  //  userPoolWebClientId: '2rtul401co4pn3pf2i9cbmt3d8'
};

export const apis = {
  hireloopService: {
    baseUrl: "https://api.tek4biz.in/hl0-v1", // base url for hireloop service containing candidate and hireloop services
    hireloop: {
      name: "hireloopS1",
      endpoints: {
        candidate: "/candidate",
        master: "/master", // /master for candidate
        offer: "/offer",
        notification: "/notification",
        organization: "/organization" //accessing organization cluster
      }
    }
  },
  organizationService: {
    baseUrl: "https://api.tek4biz.in/hl1-v1", // base url for organization service containing organization services
    organization: {
      name: "hireloopS2",
      endpoints: {
        offer: "/offer",
        master: "/master", // /master for candidate
        mail: "/mail",
        user: "/user",
        chat: "/chat",
        engagement: "/engagement",
        engFeedback: "/engFeedback",
        stage: "/stage",
        template: "/template"
      }
    }
  }
};

// TODO bucket details will be got from backend and then sent to FE to dynamically configure the AWS details
//  export const s3Settings = {
//      bucketUrl: "https:// s3.ap-south-1.amazonaws.com/hireloop-bucket-1/",
//      candidateImageFolder: "candidateImages",
//      candidateResumes: "candidateResumes",
//      defaultImageLink: "defaultProfile.png"
//  }
