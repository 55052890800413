import { Component, OnInit, Input } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EngageTempModalComponent } from "src/app/shared/components/engage-temp-modal/engage-temp-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { EngagementTemplateService } from "src/app/services/engagementTemplate/engagement-template.service";
import { routeNames } from 'src/app-settings/routes';
import { SharedService } from "../../../../services/shared/shared.service";
import { MasterTypes } from 'src/app-settings/master_types';

@Component({
  selector: "app-engagement-template",
  templateUrl: "./engagement-template.component.html",
  styleUrls: ["./engagement-template.component.scss"]
})
export class EngagementTemplateComponent implements OnInit {
   collapse: boolean[] = [];
  stages: any[] = [];
  templateId: any = "";
  templateName: any = "";
  description: any = "";
  roles: any = [];
  title="Create";
  updateConfirmMessage = "Making changes in the template will affect the engagments that are associated with the template. Are you sure you want to update it?"

  //todo pagination and search in modal

  constructor(
    private loader: NgxUiLoaderService,
    private engagementTemplateService: EngagementTemplateService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private templateService: EngagementTemplateService,
    private shared: SharedService,
    private router: Router,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.getRoles();
    this.route.paramMap.subscribe(params => {
      this.templateId = params.get("id");
      this.templateId ? this.getTemplate() : null;
    });
  }

  //get roles 

  private getRoles() {
    this.shared
      .getMasterAccToType(MasterTypes.orgRoles)
      .subscribe((res: any) => {
        console.log(res);
        this.roles = res.body[0].data[0].data.items;
      })
  }

  // get template
  private getTemplate() {
    this.title = 'Edit'
    const payload = {
      id: this.templateId
    }
    this.loader.start()
    this.engagementTemplateService.getTemplate(payload).subscribe((res) => {
      this.stages = res.body.template.stages;
      this.setDefaultCollapseFlag();
      this.templateName = res.body.template.name;
      this.description = res.body.template.description;
      if (this.stages.length > 0) {
        this.combineData(res.body);
      }
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error getting template");
    });
  }
// for collapse default set
  setDefaultCollapseFlag(){
    this.stages.forEach(each=>{
      each.collapse = false;
    })
  }

  // combine data on arrival 
  combineData(data) {
    this.stages.forEach((stage => {
      data.stagesAndActivites.forEach((stAc) => {
        if (stage.id === stAc._id) {
          stage._data = stAc;
        }
        stage.activities.forEach((activity) => {
          if (activity.id === stAc._id) {
            activity._data = stAc;
          }
        });
      });
    }));
  }

  // delete stage
  deleteStage(index, event) {
    event.stopPropagation()
    this.stages.splice(index, 1);
  }
  // delete activity
  deleteActivity(secInd, quesInd, event) {
    event.stopPropagation();
    this.stages[secInd].activities.splice(quesInd, 1);
  }



  // Drag & Drop ANgular CDK
  // =========================================
  onDropStage(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stages, event.previousIndex, event.currentIndex,);
  }
  // stop propogation on click
  stopPropagation(event) {
    event.stopPropagation();
  }

  // add engaement modal
  openModalForStages() {
    const modalRef = this.modalService.open(EngageTempModalComponent, {
      windowClass: "engage-edit-modal",
      size: "lg",
      centered: true
    });
    modalRef.componentInstance.engagementData = this.stages;
    modalRef.componentInstance.type = "stage";
    modalRef.result.then((_stages) => {
      // assign stages 
      this.stages = [];
      this.stages = _stages
      console.log("stage res", _stages)
    }).catch(err => { console.log("NO_RESULT") })
  }

  // open modal for activity
  openModalForActivity(stageIndex) {
    const modalRef = this.modalService.open(EngageTempModalComponent, {
      windowClass: "engage-edit-modal",
      size: "lg",
      centered: true
    });
    modalRef.componentInstance.activityData = this.stages[stageIndex].activities;
    modalRef.componentInstance.type = "activity";
    modalRef.result.then((_activities) => {
      // assign the result to activity inside the concerned stage
      this.stages[stageIndex].activities = _activities
    }).catch(err => { console.log("NO_RESULT") })
  }

  // collapse helper
  clickChevron(index) {
    this.stages[index].collapse = !this.stages[index].collapse;
  }

  //clean data before save
  validateAndCleanData() {
    if (this.templateName != "") {
      let problem = false;
      let _stages = JSON.parse(JSON.stringify(this.stages));
      if (_stages.length > 0) {
        _stages.forEach(stage => {
          if (stage.activities.length > 0) {
            stage.activities.forEach(act => {
              act.rules.forEach((rule) => {
                if (rule.role || rule.condition || rule.action || rule.time || rule.days) {
                  for (let key in rule) {
                    if (rule[key] == "") {
                      this.toaster.error(`In "${stage._data.name}" stage "${act._data.name}" is missing "${key}"`)
                      problem = true;
                    }
                  }
                }
              });
              if (act._data) {
                delete act._data
              }
            })
          }
          if (stage._data) {
            delete stage._data;
          }
          if (stage.expanded) {
            delete stage.expanded;
          }
        })
      }
      if (!problem) {
        return _stages;
      }
      else {
        return { err: true, msg: null }
      }

    }
    else {
      return { err: true, msg: 'Please enter the name of the template' };
    }
  }


  //create template
  public createTemplate() {
    const _stages = this.validateAndCleanData();
    if (!_stages.err && _stages.length > 0) {
      const payload = { name: this.templateName, description: this.description, stages: _stages }
      this.templateService.createTemplate(payload).subscribe((res) => {
        this.toaster.success("Engagement template saved successfully");
        this.router.navigate([routeNames.engagementListing.fullRoute]);
      }, error => {
        this.toaster.error("Error saving engagment template")
      })
    }
    else {
      if (_stages.msg) this.toaster.error(_stages.msg);
      if (_stages.length == 0) this.toaster.error("There should be at least one stage in the template");
    }
  }

  // update template

  public updateTemplate() {
    const _stages = this.validateAndCleanData();
    if (!_stages.err && _stages.length > 0) {
      const payload = { id: this.templateId, body: { name: this.templateName, description: this.description, stages: _stages } }
      if (confirm(this.updateConfirmMessage)) {
        this.templateService.updateTemplate(payload).subscribe((res) => {
          this.toaster.success("Engagement template saved successfully");
        }, error => {
          this.toaster.error("Error saving engagment template")
        })
      }
    }
    else {
      if (_stages.msg) this.toaster.error(_stages.msg);
      if (_stages.length == 0) this.toaster.error("There should be at least one stage in the template");
    }
  }

  //cancel
  cancelOperation() {
    this.router.navigate([routeNames.engagementListing.fullRoute]);
  }





}
