export const routeNames = {
  login: 'login', // org handle id
  offers: 'offers',
  newOffer: 'offer/new',
  logout: 'logout',
  engagement: 'engagement',
  offer: 'offer/:id',
  user: 'users',
  updateUser: 'user/updateUser/:id',
  dashboard: 'dashboard',
  sysadminOrganization: 'sysadmin',
  setPassword: 'setPassword',
  usersettings: 'user/settings',
  adduser: 'user/adduser',
  integration: 'integration',
  dashsettings: 'dashsettings',
  organizationalentities: 'organizationalentities',
  mainComponent: 'main',
  candidateDetails: 'candidateDetails',
  masterData: 'masterData',
  report: 'report',
  engagmentTracker: 'engagmentTracker',
  joinersandDropout: 'joinersandDropout',
  engagementListing: {
    fullRoute: 'masterData/engagementListing',
    relativeRoute: 'engagementListing'
  },
  engagementTemplateSetup: {
    fullRoute: 'masterData/engagementTemplateSetup',
    relativeRoute: 'engagementTemplateSetup',
    routeWithId: 'engagementTemplateSetup/:id'
  }
};


export const ignoreRoutes = [
  'engagementTemplateSetup',
  'engagementListing',
  "engagementTemplateSetup*"
]
