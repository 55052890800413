import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ignoreRoutes } from './../../app-settings/routes';
@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }
  //route guard to protect routes from unaunthenticated users
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.getCurrentAuthUser().pipe(
      map(res => {
        let url = null;
        let urls = [];
        if (next.url.length > 0) {
          next.url.forEach(el => {
            urls.push(el.path);
          });
          url = urls.join("/");
          // ! TODO CHANGE LATER ----- THIS IS FOR MASTER DATA CHANGES
          if (new RegExp(ignoreRoutes[2]).test(url)) {
            console.log("dont store route...")
          }
          else if (!ignoreRoutes.includes(url)) {
            localStorage.setItem("route", url); //setting route in localstorage
          }
          localStorage.setItem(
            "routeTimeStamp",
            new Date().getTime().toString()
          ); //getting timestamp of setting route
        }
        let roleMappingResult = this.auth.roleMapping(
          this.auth.getAuthRole(),
          url
        );
        if (roleMappingResult) {
          return true;
        } else {
          this.toastr.error("Unauthorized");
          return false;
        }
      }),
      catchError(err => {
        //check if routeguard
        this.router.navigate([""]);
        // this.toastr.error("Unauthorized");
        return of(false);
      })
    );
  }
}
