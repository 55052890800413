import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private candidateApiName: string;
  private candidatePath: string;
  orgHandle: string;
  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.candidateApiName = apis.hireloopService.hireloop.name; // candidate endpoints
    this.candidatePath = apis.hireloopService.hireloop.endpoints.notification; // canidate endpoints
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // get notifications
  public getNotifications() {
    const request = this.initRequests.initGetNotification({
      _action: "GET",
      queryBody: {
        orgHandle: this.orgHandle,
        createdBy: "candidate"
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }

  // get notifications
  public udpateNotificationStatus(body) {
    const request = this.initRequests.initUpdateNotification({
      _action: "CHANGE_STATUS",
      updateBody: body
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }
}
