import { UserService } from './../../services/user/user.service';
import { GenerateOfferServiceService } from './generate-offer-service/generate-offer-service.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { OfferModalComponent } from './components/offer-modal/offer-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfferService } from 'src/app/services/offer/offer.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExceltojsonService } from 'src/app/services/exceltojson/exceltojson.service';
import { Key } from 'protractor';
import { element } from '@angular/core/src/render3';
import * as moment from 'moment';
import { MasterTypes } from 'src/app-settings/master_types';
@Component({
  selector: 'app-generate-offers',
  templateUrl: './generate-offers.component.html',
  styleUrls: ['./generate-offers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenerateOffersComponent implements OnInit {
  moreDetailsIndex: any;
  moreDetailsToggle: boolean[] = [];
  orgEntitiesData: any[];
  [x: string]: any;
  offerSubmittedState = false;
  fileToUpload: File = null;
  fileToMailUpload: File = null;
  now: Date;
  uploadedFileObject = {};
  uploadedFileMAilObject = {};
  toUploadFiles: any = [];
  toUploadMailFiles: any = [];
  reviewmail = false;
  offerForm: FormGroup;
  masters: any;
  users: any;
  mailTemplate: any;
  fileUrl: any;
  generateOfferPayload: any = {
    candidateData: {
      id: '',
      emailId: '',
      firstName: '',
      lastName: '',
      profileImage: '',
      mobile: '',
      skills: '',
      nameAsInPassport: '',
      resumeSource: '',
      title: '',
      dob: ''
    },
    offerMetaData: {
      title: '',
      noticePeriod: '',
      date: '',
      joiningDate: '',
      offerDate: '',
      expiryDate: '',
      designation: '',
      salary: '',
      currency: '',
      campusHire: '',
      activity: [],
      status: []
    },
    file: {
      URL: ''
    },
    downloadConfig: {
      status: ''
    },
    criDisclaimer: '',
    organizationData: {
      businessUnit: '',
      division: '',
      department: '',
      hiringManager: '',
      recruiter: '',
      reportingManager: '',
      taManager: '',
      hrManager: '',
      location: ''
    },
    // extrafield: {
    //   extraone: '',
    //   extrafield: ''
    // },
    emailTemplate: {
      mailSubject: '',
      body: '',
      otherAttachment: '',
      offerLetterAttached: ''
    },
    createdAt: '',
    updatedAt: ''
  };
  getOfferStatus: any;
  result: any[] = [];
  selectBulkOfferList: any[] = [];
  selectFinalOfferList: any[] = [];
  excelArrayData: any[] = [];
  businessUnitArray: any[] = [];
  excelDataModel: any;
  fileEventData: any;
  bulkOfferForm: FormGroup;
  bulkUploadedFile: any;
  bulkUploadedEventFile: any;
  step: string;
  warnigFlag = false;
  indexValue: any;
  bulkListArray: any;
  sortedEntities: any;
  constructor(
    private location: Location,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private localService: GenerateOfferServiceService,
    private userService: UserService,
    private offerService: OfferService,
    public shared: SharedService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    private router: Router,
    public excelToJson: ExceltojsonService
  ) {
    this.masters = [];
    this.users = [];
    this.bulkUploadedFile = '';
    this.sortedEntities = [];
    this.orgEntitiesData = [];
  }

  ngOnInit() {
    this.generateOfferForm();
    this.generateBulkMailTemplate();
    this.getMasters();
    this.getUsers();
    this.getMailTemplate();
    this.shared.getMastersData();
    this.getOrgEntities();
  }

  // offer generation form
  generateOfferForm() {
    this.offerForm = this.fb.group({
      detailsForm: this.fb.group({
        id: [null], //initial id for the candidate will be null
        firstName: ['', Validators.required],
        lastName: [''], // not mandatory
        email: ['', [Validators.required, Validators.email]],
        dob: [null], // not mandatory
        contactNumber: [
          '',[Validators.required,
            Validators.compose([
              Validators.minLength(13),
              Validators.pattern('^([+][9][1])([0-9]{1})([0-9]{9})$')
            ])
          ]
        ],
        profileImage: [''],
        candidateTitle: [''],
        offerDesignation: [''], // not mandatory
        businessUnit: [''],
        division: [''],
        department: [''],
        noticePeriod: [''], // not mandatory
        currency: [''],
        salary: [''],
        joiningDate: [null, Validators.required], // not mandatory
        offerDate: [null, Validators.required],
        location: [''], // not mandatory
        reportingManager: [''],
        taManager: [''],
        hrManager: [''],
        recruiter: ['', Validators.required],
        hiringManager: [''],
        // extraone:[''],
        // extrafield:[''],
        offerStatus: [
          {
            value: '',
            disabled: true
          }
        ],
        offerCreationDate: [
          {
            value: this.shared.setDateDefault(),
            disabled: false
          },
          Validators.required
        ],
        offerDocument: [''], // not mandatory
        downloadConfig: [''],
        criDisclaimer: [''],
        campusHire: [''],
        expiryDate: [null],
        skills: ['', Validators.required],
        nameAsInPassport: [''], // not mandatory
        resumeSource: ['']
      }),
      mailTemplateForm: this.fb.group({
        subject: ['', Validators.required],
        content: ['', Validators.required],
        attachement: [''],
        attachedToMail: ['']
      })
    });
  }

  //!No search data will populate in fields
  // // getting output data from modal of search
  // getDataFromOfferForm(event) {
  //   const candidateDetails = event.candidateSearchData;
  //   this.offerForm.get('detailsForm').patchValue({
  //     id: candidateDetails.candidateId,
  //     firstName: candidateDetails.personalInfo.firstName,
  //     lastName: candidateDetails.personalInfo.lastName,
  //     email: candidateDetails.email,
  //     dob: candidateDetails.personalInfo.dob,
  //     contactNumber: parseInt(candidateDetails.contactInfo.mobile),
  //     profileImage: candidateDetails.personalInfo.profileImage
  //   });
  // }

  // getting services
  getMasters() {
    this.localService.getMasters(res => {
      this.masters = res;
    });
  }
  public getOrgEntities() {
    this.shared.getMasterAccToType(MasterTypes.orgEntities).subscribe(
      res => {
        (this.orgEntitiesData = this.shared.sortMasterDataForEntities(
          res.body[0].data
        ))
      },
      error => {
        this.toaster.error("something went wrong.");
      }
    );
  }

  // get users for masters in gen offer
  getUsers() {
    this.userService.getUser(res => {
      this.users = res;
    });
    // this.userService
    //   .getUsers({ emailId: "1", _id: "0", displayName: "1", role: "1" })
    //   .subscribe(res => {
    //     this.users = res.body;
    //   });
  }
  // getting mail template for preloading the offer mail template
  getMailTemplate() {
    this.localService.getOfferTemplate(res => {
      this.mailTemplate = res;
      this.offerForm
        .get('mailTemplateForm')
        .patchValue({ content: res.templateBody, subject: res.mailSubject });
      this.bulkOfferForm
        .get('mailTemplateForm')
        .patchValue({ content: res.templateBody, subject: res.mailSubject });
    });
  }

  //Calculate the notice period depending on the Joining date and the offer date.
  calculateNoticePeriod() {
    if(this.generateOfferPayload.offerMetaData.noticePeriod == null || this.generateOfferPayload.offerMetaData.noticePeriod === ""){
      this.generateOfferPayload.offerMetaData.noticePeriod = ((new Date(this.generateOfferPayload.offerMetaData.joiningDate).getTime()-new Date(this.generateOfferPayload.offerMetaData.offerDate).getTime())/ (1000 * 3600 * 24));
    }
    return;
  }

  //  upload offer file
  uploadFileOfOffer(callback) {
    const offerFileData = this.offerForm.get('detailsForm').get('offerDocument').value;
    if (offerFileData.length > 0) {
      this.loader.start();
      const uploadFilepayload = {
        fileData: offerFileData[0].fileData,
        folder: offerFileData[0].folder,
        dataType: offerFileData[0].dataType,
        fileFormat: offerFileData[0].fileFormat




      };
      this.offerService.uploadFile(uploadFilepayload).subscribe(
        res => {
          if (res) {
            this.fileUrl = res.fileLink;
            this.loader.stop();
            this.generateOffer(res, callback);
          }
        },
        err => {
          this.toaster.error('something went wrong');
          this.loader.stop();
        }
      );
    } else {
      // not uploading a file
      this.generateOffer("", callback);
      // this.toaster.error('please upload a PDF type file');
    }
  }
  getUpdateOfferStatus(value) {
    this.getOfferStatus = value;
  }

  getOfferChangeStatus(event) {
    this.shared.setOfferFormData(this.offerForm.get('detailsForm').value);
  }
  getMailContent(data) {
    this.generateOfferPayload.emailTemplate.body = data;
  }
  // call the gererate offer function
  // generateOffer(data, callback) {
  //   this.loader.start();
  //   this.generateOfferPayload.candidateData.id = this.offerForm
  //     .get('detailsForm')
  //     .get('id').value;
  //   this.generateOfferPayload.candidateData.emailId = this.offerForm
  //     .get('detailsForm')
  //     .get('email').value;
  //   this.generateOfferPayload.candidateData.title = this.offerForm
  //     .get('detailsForm')
  //     .get('candidateTitle').value;
  //   this.generateOfferPayload.candidateData.mobile = this.offerForm
  //     .get('detailsForm')
  //     .get('contactNumber').value;
  //   this.generateOfferPayload.candidateData.nameAsInPassport = this.offerForm
  //     .get('detailsForm')
  //     .get('nameAsInPassport').value;
  //   this.generateOfferPayload.candidateData.resumeSource = this.offerForm
  //     .get('detailsForm')
  //     .get('resumeSource').value;
  //   this.generateOfferPayload.candidateData.dob = this.offerForm.get('detailsForm').get('dob').value ? this.shared.convertObjectToDateString(
  //     this.offerForm.get('detailsForm').get('dob').value
  //   ) : "";
  //   this.generateOfferPayload.candidateData.skills = this.offerForm
  //     .get('detailsForm')
  //     .get('skills').value;
  //   this.generateOfferPayload.candidateData.profileImage = this.offerForm
  //     .get('detailsForm')
  //     .get('profileImage').value;
  //   this.generateOfferPayload.candidateData.firstName = this.offerForm
  //     .get('detailsForm')
  //     .get('firstName').value;
  //   this.generateOfferPayload.candidateData.lastName = this.offerForm
  //     .get('detailsForm')
  //     .get('lastName').value;
  //   this.generateOfferPayload.offerMetaData.title = this.offerForm
  //     .get('detailsForm')
  //     .get('candidateTitle').value;
  //   this.generateOfferPayload.offerMetaData.noticePeriod = this.offerForm
  //     .get('detailsForm')
  //     .get('noticePeriod').value;
  //   this.generateOfferPayload.offerMetaData.date = this.offerForm.get('detailsForm').get('offerCreationDate').value ? this.shared.convertObjectToDateString(
  //     this.offerForm.get('detailsForm').get('offerCreationDate').value
  //   ) : "";
  //   this.generateOfferPayload.offerMetaData.joiningDate = this.offerForm.get('detailsForm').get('joiningDate').value ? this.shared.convertObjectToDateString(
  //     this.offerForm.get('detailsForm').get('joiningDate').value
  //   ) : "";
  //   this.generateOfferPayload.offerMetaData.expiryDate = this.offerForm.get('detailsForm').get('expiryDate').value ? this.shared.convertObjectToDateString(
  //     this.offerForm.get('detailsForm').get('expiryDate').value
  //   ) : "";
  //   this.generateOfferPayload.offerMetaData.designation = this.offerForm
  //     .get('detailsForm')
  //     .get('offerDesignation').value;

  //   this.generateOfferPayload.offerMetaData.salary = this.offerForm
  //     .get('detailsForm')
  //     .get('salary').value;
  //   this.generateOfferPayload.offerMetaData.currency = this.offerForm
  //     .get('detailsForm')
  //     .get('currency').value;

  //   this.generateOfferPayload.offerMetaData.campusHire = this.offerForm
  //     .get('detailsForm')
  //     .get('campusHire').value
  //     ? this.offerForm.get('detailsForm').get('campusHire').value
  //     : false;
  //   this.generateOfferPayload.file.URL = this.fileUrl;
  //   this.generateOfferPayload.downloadConfig.status = this.offerForm
  //     .get('detailsForm')
  //     .get('downloadConfig').value
  //     ? this.offerForm.get('detailsForm').get('downloadConfig').value
  //     : false;
  //   this.generateOfferPayload.criDisclaimer = this.offerForm
  //     .get('detailsForm')
  //     .get('criDisclaimer').value
  //     ? this.offerForm.get('detailsForm').get('criDisclaimer').value
  //     : false;
  //   this.generateOfferPayload.organizationData.businessUnit = this.offerForm
  //     .get('detailsForm')
  //     .get('businessUnit').value;
  //   this.generateOfferPayload.organizationData.division = this.offerForm
  //     .get('detailsForm')
  //     .get('division').value;
  //   this.generateOfferPayload.organizationData.department = this.offerForm
  //     .get('detailsForm')
  //     .get('department').value;
  //   this.generateOfferPayload.organizationData.hiringManager = this.offerForm
  //     .get('detailsForm')
  //     .get('hiringManager').value;
  //   this.generateOfferPayload.organizationData.recruiter = this.offerForm
  //     .get('detailsForm')
  //     .get('recruiter').value;
  //   this.generateOfferPayload.organizationData.reportingManager = this.offerForm
  //     .get('detailsForm')
  //     .get('reportingManager').value;
  //   this.generateOfferPayload.organizationData.taManager = this.offerForm
  //     .get('detailsForm')
  //     .get('taManager').value; //new
  //   this.generateOfferPayload.organizationData.hrManager = this.offerForm
  //     .get('detailsForm')
  //     .get('hrManager').value; //new
  //   this.generateOfferPayload.organizationData.location = this.offerForm
  //     .get('detailsForm')
  //     .get('location').value;
  //   this.generateOfferPayload.emailTemplate.mailSubject = this.offerForm
  //     .get('mailTemplateForm')
  //     .get('subject').value;
  //   this.generateOfferPayload.emailTemplate.otherAttachment = this.offerForm
  //     .get('mailTemplateForm')
  //     .get('attachedToMail').value;
  //   this.generateOfferPayload.emailTemplate.offerLetterAttached = this.offerForm
  //     .get('mailTemplateForm')
  //     .get('attachement').value;
  //   this.generateOfferPayload.createdAt = new Date().getTime();
  //   this.generateOfferPayload.updatedAt = new Date().getTime();
  //   if (this.generateOfferPayload.emailTemplate.body === '') {
  //     this.toaster.error('Please add & preview Email body ');
  //     this.loader.stop();
  //   } else {
  //     const tempDate = new Date();
  //     const date = tempDate.toISOString();
  //     this.generateOfferPayload.offerMetaData.activity.push({
  //       statusCode: 'GEN',
  //       date: date
  //     });
  //     this.offerService.generateOffer(this.generateOfferPayload).subscribe(
  //       res => {
  //         if (res) {
  //           this.toaster.success('offer created successfully');
  //           this.router.navigate(['offers']);
  //           this.loader.stop();
  //         }
  //       },
  //       err => {
  //         this.loader.stop();
  //         this.toaster.error('something went wrong');
  //       }
  //     );
  //   }
  // }

  // call the gererate offer function
  // Nikhil Code
  async generateOffer(data, callback) {
    this.loader.start();
    this.generateOfferPayload.candidateData.id = this.offerForm
      .get('detailsForm')
      .get('id').value;
    this.generateOfferPayload.candidateData.emailId = this.offerForm
      .get('detailsForm')
      .get('email').value;
    this.generateOfferPayload.candidateData.title = this.offerForm
      .get('detailsForm')
      .get('candidateTitle').value;
    this.generateOfferPayload.candidateData.mobile = this.offerForm
      .get('detailsForm')
      .get('contactNumber').value;
    this.generateOfferPayload.candidateData.nameAsInPassport = this.offerForm
      .get('detailsForm')
      .get('nameAsInPassport').value;
    this.generateOfferPayload.candidateData.resumeSource = this.offerForm
      .get('detailsForm')
      .get('resumeSource').value;
    this.generateOfferPayload.candidateData.dob = this.offerForm.get('detailsForm').get('dob').value ? this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('dob').value
    ) : "";
    this.generateOfferPayload.candidateData.skills = this.offerForm
      .get('detailsForm')
      .get('skills').value;
    this.generateOfferPayload.candidateData.profileImage = this.offerForm
      .get('detailsForm')
      .get('profileImage').value;
    this.generateOfferPayload.candidateData.firstName = this.offerForm
      .get('detailsForm')
      .get('firstName').value;
    this.generateOfferPayload.candidateData.lastName = this.offerForm
      .get('detailsForm')
      .get('lastName').value;
    this.generateOfferPayload.offerMetaData.title = this.offerForm
      .get('detailsForm')
      .get('candidateTitle').value;
    this.generateOfferPayload.offerMetaData.noticePeriod = this.offerForm
      .get('detailsForm')
      .get('noticePeriod').value;
    this.generateOfferPayload.offerMetaData.date = this.offerForm.get('detailsForm').get('offerCreationDate').value ? this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('offerCreationDate').value
    ) : "";
    this.generateOfferPayload.offerMetaData.joiningDate = this.offerForm.get('detailsForm').get('joiningDate').value ? this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('joiningDate').value
    ) : "";
    this.generateOfferPayload.offerMetaData.offerDate = this.offerForm.get('detailsForm').get('offerDate').value ? this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('offerDate').value
    ) : "";
    this.generateOfferPayload.offerMetaData.expiryDate = this.offerForm.get('detailsForm').get('expiryDate').value ? this.shared.convertObjectToDateString(
      this.offerForm.get('detailsForm').get('expiryDate').value
    ) : "";
    this.generateOfferPayload.offerMetaData.designation = this.offerForm
      .get('detailsForm')
      .get('offerDesignation').value;

    this.generateOfferPayload.offerMetaData.salary = this.offerForm
      .get('detailsForm')
      .get('salary').value;
    this.generateOfferPayload.offerMetaData.currency = this.offerForm
      .get('detailsForm')
      .get('currency').value;

    this.generateOfferPayload.offerMetaData.campusHire = this.offerForm
      .get('detailsForm')
      .get('campusHire').value
      ? this.offerForm.get('detailsForm').get('campusHire').value
      : false;
    this.generateOfferPayload.file.URL = this.fileUrl;
    this.generateOfferPayload.downloadConfig.status = this.offerForm
      .get('detailsForm')
      .get('downloadConfig').value
      ? this.offerForm.get('detailsForm').get('downloadConfig').value
      : false;
    this.generateOfferPayload.criDisclaimer = this.offerForm
      .get('detailsForm')
      .get('criDisclaimer').value
      ? this.offerForm.get('detailsForm').get('criDisclaimer').value
      : false;
    this.generateOfferPayload.organizationData.businessUnit = this.offerForm
      .get('detailsForm')
      .get('businessUnit').value;
    this.generateOfferPayload.organizationData.division = this.offerForm
      .get('detailsForm')
      .get('division').value;
    this.generateOfferPayload.organizationData.department = this.offerForm
      .get('detailsForm')
      .get('department').value;
    this.generateOfferPayload.organizationData.hiringManager = this.offerForm
      .get('detailsForm')
      .get('hiringManager').value;
    this.generateOfferPayload.organizationData.recruiter = this.offerForm
      .get('detailsForm')
      .get('recruiter').value;
    this.generateOfferPayload.organizationData.reportingManager = this.offerForm
      .get('detailsForm')
      .get('reportingManager').value;
    this.generateOfferPayload.organizationData.taManager = this.offerForm
      .get('detailsForm')
      .get('taManager').value; //new
    this.generateOfferPayload.organizationData.hrManager = this.offerForm
      .get('detailsForm')
      .get('hrManager').value; //new
    this.generateOfferPayload.organizationData.location = this.offerForm
      .get('detailsForm')
      .get('location').value;
    // this.generateOfferPayload.extrafield.extraone = this.offerForm
    //   .get('detailsForm')
    //   .get('extraone').value;
    // this.generateOfferPayload.extrafield.extrafield = this.offerForm
    //   .get('detailsForm')
    //   .get('extrafield').value;
    this.generateOfferPayload.emailTemplate.mailSubject = this.offerForm
      .get('mailTemplateForm')
      .get('subject').value;
    this.generateOfferPayload.emailTemplate.otherAttachment = this.offerForm
      .get('mailTemplateForm')
      .get('attachedToMail').value;
    this.generateOfferPayload.emailTemplate.offerLetterAttached = this.offerForm
      .get('mailTemplateForm')
      .get('attachement').value;
    this.generateOfferPayload.createdAt = new Date().getTime();
    this.generateOfferPayload.updatedAt = new Date().getTime();

    const tempDate = new Date();
    const date = tempDate.toISOString();
    this.generateOfferPayload.offerMetaData.activity.push({
      statusCode: 'GEN',
      date: date
    });
    await this.calculateNoticePeriod();
    this.offerService.generateOffer(this.generateOfferPayload).subscribe(
      res => {
        if (res) {
          this.toaster.success('offer created successfully');
          this.router.navigate(['offers']);
          this.loader.stop();
        }
      },
      err => {
        this.loader.stop();
        this.toaster.error('something went wrong');
      }
    );
  }

  // gernerate the offer function
  // openselectedoffer(callback) {
  //   this.offerSubmittedState = true;
  //   if (
  //     this.offerForm.get('detailsForm').valid &&
  //     this.offerForm.get('mailTemplateForm').valid
  //   ) {
  //     this.uploadFileOfOffer(callback);
  //   } else {
  //     // problem here
  //     console.log(this.offerForm);
  //     this.toaster.error('Something is missing');
  //   }
  // }

  // gernerate the offer function
  // Nikhil code
  openselectedoffer(callback) {
    this.offerSubmittedState = true;
    if (this.offerForm.get('detailsForm').valid) {
      this.uploadFileOfOffer(callback);
    } else {
      // problem here
      // console.log(this.offerForm.get('detailsForm'));
      this.toaster.error('Something is missing');
    }
  }

  // generate bulk offer template form
  generateBulkMailTemplate() {
    this.bulkOfferForm = this.fb.group({
      mailTemplateForm: this.fb.group({
        subject: ['', Validators.required],
        content: ['', Validators.required],
        attachement: [''],
        attachedToMail: ['']
      })
    });
  }

  // excel upload
  excelFileUpload(event) {
    console.log('nnnn');
    let file = event.target.files[0];
    this.bulkUploadedEventFile = event;
    if (file) {
      this.bulkUploadedFile = file.name;
      this.excelToJson.processFileToJson({}, file).subscribe(data => {
        const result = data['sheets'].Sheet1;
        const formattedData = [];
        const p = result[0];
        for (let x = 1; x < result.length; x++) {
          const newObj = {};
          for (const key in p) {
            if (p.hasOwnProperty(key)) {
              const replaceString = p[key].replace(/\s+/g, '');
              newObj[replaceString] = result[x][key];
            }
          }
          formattedData.push(newObj);
        }
        this.excelArrayData = formattedData;
        this.excelDataMapper(this.excelArrayData);
        this.checkingBulkFileData(this.excelArrayData)
        this.validateOrgEntities(this.excelArrayData)
        this.step = "1";
      });
    }
  }



  // remove uploaded excelfile
  deleteUploadedExcelFile() {
    this.bulkUploadedEventFile.target.value = '';
    this.bulkUploadedFile = '';
    this.excelDataModel = [];
  }
  excelDataMapper(data) {
    this.excelDataModel = data.map(eachData => {
      let {
        CandidateTitle,
        FirstName,
        MiddleName,
        LastName,
        Email,
        DOB,
        NameasinPassport,
        ContactNumber,
        OfferDesignation,
        BusinessUnit,
        Division,
        Department,
        NoticePeriod,
        Currency,
        Salary,
        JoiningDate,
        OfferDate,
        Skills,
        Location,
        EngagementAssociate,
        // CampaignSpecialist,
        TAManager,
        HRManager,
        Recruiter,
        HiringManager,
        OfferCreationDate,
        OfferExpiryDate,
        OfferDocument,
        DownloadOffer,
        CriDisclaimer,
        ResumeSource,
        CampusHire
      } = eachData;

      return {
        candidateData: {
          id: null,
          emailId: Email,
          firstName: FirstName,
          lastName: LastName,
          profileImage: '',
          mobile: ContactNumber,
          skills: Skills,
          nameAsInPassport: NameasinPassport,
          resumeSource: ResumeSource,
          title: CandidateTitle,
          dob: this.shared.excelDateToJSDate(DOB)
        },
        offerMetaData: {
          title: '',
          noticePeriod: NoticePeriod,
          date: this.shared.excelDateToJSDate(OfferCreationDate),
          joiningDate: this.shared.excelDateToJSDate(JoiningDate),
          offerDate: this.shared.excelDateToJSDate(OfferDate),
          expiryDate: this.shared.excelDateToJSDate(OfferExpiryDate),
          designation: OfferDesignation,
          salary: Salary,
          currency: Currency,
          campusHire: this.shared.returnBooleanValue(CampusHire),
          activity: [],
          status: []
        },
        file: {
          URL: ''
        },
        downloadConfig: {
          status: this.shared.returnBooleanValue(DownloadOffer)
        },
        criDisclaimer: this.shared.returnBooleanValue(CriDisclaimer),
        organizationData: {
          businessUnit: BusinessUnit,
          division: Division,
          department: Department,
          hiringManager: HiringManager,
          recruiter: Recruiter,
          reportingManager: EngagementAssociate,
          // taManager: CampaignSpecialist,
          taManager: TAManager,
          hrManager: HRManager,
          location: Location
        },
        emailTemplate: {
          mailSubject: '',
          body: '',
          otherAttachment: '',
          offerLetterAttached: ''
        },
        createdAt: new Date().getTime(),
        updatedAt: "",
        dataCheckedFlag: false,
        selected: false,
        finalSelected: false,
      };
    });

  }
  checkingBulkFileData(data) {
    // console.log(this.masters)
    if (data.length > 0) {
      this.bulkListArray = data.map(eachData => {
        let {
          FirstName,
          LastName,
          Email,
          DOB,
          NameasinPassport,
          ContactNumber,
          OfferDesignation,
          BusinessUnit,
          NoticePeriod,
          JoiningDate,
          OfferDate,
          Skills,
          Location,
          EngagementAssociate,
          TAManager,
          HRManager,
          Recruiter,
          HiringManager,
          OfferCreationDate,
          DownloadOffer,
          CriDisclaimer,
          CampusHire
        } = eachData;

        return {
          firstName: FirstName,
          lastName: LastName,
          emailId: Email,
          mobile: ContactNumber,
          nameAsInPassport: NameasinPassport,
          dob: this.shared.excelDateToJSDate(DOB),
          noticePeriod: NoticePeriod,
          date: this.shared.excelDateToJSDate(OfferCreationDate),
          joiningDate: this.shared.excelDateToJSDate(JoiningDate),
          offerDate: this.shared.excelDateToJSDate(OfferDate),
          skills: Skills,
          designation: OfferDesignation,
          campusHire: this.shared.returnBooleanValue(CampusHire),
          businessUnit: BusinessUnit,
          hiringManager: HiringManager,
          recruiter: Recruiter,
          reportingManager: EngagementAssociate,
          // taManager: CampaignSpecialist,
          taManager: TAManager,
          hrManager: HRManager,
          location: Location,
          downloadConfig: this.shared.returnBooleanValue(DownloadOffer),
          criDisclaimer: this.shared.returnBooleanValue(CriDisclaimer),
        }
      })
    }
    if (this.bulkListArray.length > 0) {
      this.bulkListArray.map((ele, index) => {
        for (const key in ele) {
          if (ele.hasOwnProperty(key)) {
            const element = ele[key];
            if (key == 'firstName' || key == 'mobile' || key === 'offerCreationDate' || key === 'emailId'
              || key == 'joiningDate' || key == 'offerDate' || key == 'skills'  || key === 'recruiter') {
              if (element === "" || element === undefined) {
                this.warnigFlag = true;
                this.indexValue = index;
                this.excelDataModel[index].dataCheckedFlag = true;
              }
            }
            // if joining date is the key and has any value
            if (key === 'joiningDate' && ele[key]) {
              let bulkJoingingDate = moment(ele[key]).format("DD/MM/YYYY");
              let todayDate = moment(new Date().toISOString()).format("DD/MM/YYYY");
              let split1: any = bulkJoingingDate.split('/');
              let split2: any = todayDate.split('/');
              let date1 = new Date(split1[2], split1[1] - 1, split1[0]);
              let date2 = new Date(split2[2], split2[1] - 1, split2[0]);
              if (date1.getTime() <= date2.getTime()) {
                this.excelDataModel[index].dataCheckedFlag = true;
                this.toaster.error("Date error. Joining date should be after the present date.");
              }
            }
          }
        }
      })
    }
  }


  validateOrgEntities(data: any[]) {
    let errorArray = [];
    let busniessDataArray = [];

    // validation the Business  code of excel data
    data.forEach((item, index) => {
      let BUDataMatched = this.orgEntitiesData.find(element => {
        return element.code === item.BusinessUnit;
      })
      // if (BUDataMatched === undefined) {
      //   this.excelDataModel[index].dataCheckedFlag = true;
      //   this.toaster.error("Business Unit Doesn't match.");
      //   errorArray.push({
      //     input: item.BusinessUnit,
      //     rowData: item,
      //     index: index,
      //     errorAt: "Business Unit"
      //   })
      // } else {
      //   busniessDataArray.push({
      //     input: item.BusinessUnit,
      //     rowData: item,
      //     index: index,
      //     buItem: BUDataMatched.items
      //   })
      // }
      if (BUDataMatched != undefined) {
        busniessDataArray.push({
          input: item.BusinessUnit,
          rowData: item,
          index: index,
          buItem: BUDataMatched.items
        })
      }
    })

    // validation the divison code of excel data
    let divisionDataArray = [];
    busniessDataArray.forEach(divElement => {
      if (divElement.rowData.Division !== undefined) {
        if (divElement.rowData.Division !== '') {
          let divDataMatched = divElement.buItem.find(element => {
            return element.code === divElement.rowData.Division;
          })
          if (divDataMatched === undefined) {
            this.excelDataModel[divElement.index].dataCheckedFlag = true;
            this.toaster.error(divElement.input + ' ' + "Division Doesn't  match" + 'at Line ' + (1 + divElement.index));
            errorArray.push({
              input: divElement.rowData.Division,
              rowData: divElement.rowData,
              index: divElement.index,
              errorAt: "Divison"
            })
          }
          else {
            divisionDataArray.push({
              input: divElement.rowData.Division,
              rowData: divElement.rowData,
              index: divElement.index,
              divisonItem: divDataMatched.items
            })
          }
        }
      }
    })

    // validation the divison code of excel data
    if (divisionDataArray.length > 0) {
      divisionDataArray.forEach(depElement => {
        if (depElement.rowData.Department !== undefined) {
          if (depElement.rowData.Department !== '') {
            let depDataMatched = depElement.divisonItem.find(element => {
              return element.code === depElement.rowData.Department;
            })
            if (depDataMatched === undefined) {
              this.excelDataModel[depElement.index].dataCheckedFlag = true;
              this.toaster.error(depElement.input + ' ' + "Department Doesn't match." + 'at Line ' + (1 + depElement.index));
              errorArray.push({
                input: depElement.rowData.Department,
                rowData: depElement.rowData,
                index: depElement.index,
                errorAt: "Divison"
              })
            }
          }
        }
      })
    }
  }


  uploadOfferFile(event, index) {
    this.fileEventData = event;
    this.shared.onFileChanged(event).then((data: any) => {
      const fileData = data.fileData.split(',')[1];
      const dataType = data.fileData.split('/')[0].split(':')[1];
      const fileFormat = data.fileData.split('/')[1].split(';')[0];
      const uploadFilepayload = {
        fileData,
        folder: 'assets',
        dataType: 'file',
        fileFormat,
        fileName: data.fileName
      };
      this.offerService.uploadFile(uploadFilepayload).subscribe(
        res => {
          if (res) {
            this.excelDataModel[index].file.URL = res.fileLink;
            this.loader.stop();
          }
        },
        err => {
          this.toaster.error('something went wrong');
          this.loader.stop();
        }
      );
    });
  }
  // delete the file
  deleteOfferFile(index) {
    this.excelDataModel[index].file.URL = '';
    this.fileEventData.target.value = '';
  }

  selectOfferFormTable(index, checked, candidateModelData) {
    // this.selectBulkOfferList = []; //bug changed when the code was not pushing to the array
    if (checked === true) {
      candidateModelData.selected = true;
      this.selectBulkOfferList.push(candidateModelData);
    } else {
      this.selectBulkOfferList.splice(index, 1);
    }
  }

  selectAllOffers(checked) {
    this.selectBulkOfferList = [];
    if (checked === true) {
      this.excelDataModel.forEach((element, index) => {
        if (element.dataCheckedFlag === false) {
          element.selected = checked;
        }
      })

      this.excelDataModel.forEach((element, index) => {
        if (element.selected === true) {
          this.selectBulkOfferList.push(element);
        }
      });
    } else {
      this.excelDataModel.forEach((element, index) => {
        if (element.dataCheckedFlag === false) {
          element.selected = false;
        }
      })
      this.selectBulkOfferList = [];
    }
  }

  replaceText(mailBody, candidateData, index) {
    if (mailBody) {
      for (var key in candidateData) {
        if (candidateData.hasOwnProperty(key)) {
          let value = candidateData[key];
          let replacekey = '%' + key + '%';
          let updatedTemplate = mailBody.replace(
            new RegExp(this.escapeRegExp(replacekey), 'g'),
            value
          );
          mailBody = updatedTemplate;
        }
      }
    }
    if (this.selectBulkOfferList.length > 0) {
      this.selectBulkOfferList[index].emailTemplate.body = mailBody;
      this.selectBulkOfferList[index].emailTemplate.mailSubject = this.mailTemplate.mailSubject;
    }
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  addActivityForEachOffer(index) {
    const tempDate = new Date();
    const date = tempDate.toISOString();
    this.selectFinalOfferList[index].offerMetaData.activity.push({
      statusCode: 'GEN',
      date: date
    });
  }

  preViewEmailTemplate() {
    if (this.selectBulkOfferList.length > 0) {
      this.shared.setOfferFormData(this.selectBulkOfferList[0]);

      let fileData = this.selectBulkOfferList.every(ele => {
        return ele.file && ele.file.URL && ele.file.URL.trim() && ele.file.URL.trim().length > 0
      })
      // if (fileData) {
      this.reviewmail = true;
      this.step = '2';
      // }
      //offer letter optional
      // else {
      //   this.reviewmail = false;
      //   this.toaster.error('Please Upload an offer letter for each offer ');
      // }
      this.selectBulkOfferList.forEach((ele, index) => {
        this.addActivityForEachOffer(index);
        const candidateObj = {
          firstName: ele.candidateData.firstName,
          lastName: ele.candidateData.lastName,
          email: ele.candidateData.emailId,
          dob: moment(ele.candidateData.dob).format('YYYY-MM-DD'),
          contactNumber: ele.candidateData.mobile,
          nameAsInPassport: ele.candidateData.nameAsInPassport,
          resumeSource: this.shared.getResumeSourceTitle(
            ele.candidateData.resumeSource
          ),
          skills: ele.candidateData.skills,
          title: ele.offerMetaData.title,
          offerDesignation: ele.offerMetaData.designation,
          businessUnit: this.shared.getOrgEntitiesTitle(
            ele.organizationData.businessUnit
          ),
          division: this.shared.getOrgEntitiesTitle(
            ele.organizationData.division
          ),
          department: this.shared.getOrgEntitiesTitle(
            ele.organizationData.department
          ),
          noticePeriod: ele.offerMetaData.noticePeriod,
          currency: ele.offerMetaData.currency,
          salary: ele.offerMetaData.salary,
          joiningDate: moment(ele.offerMetaData.joiningDate).format('YYYY-MM-DD'),
          offerDate: moment(ele.offerMetaData.offerDate).format('YYYY-MM-DD'),
          expiryDate: moment(ele.offerMetaData.expiryDate).format('YYYY-MM-DD'),
          location: ele.organizationData.location,
          reportingManager: this.userService.getUserName(
            ele.organizationData.reportingManager
          ),
          taManager: this.userService.getUserName(
            ele.organizationData.taManager
          ), //new
          hrManager: this.userService.getUserName(
            ele.organizationData.hrManager
          ), //new
          recruiter: this.userService.getUserName(
            ele.organizationData.recruiter
          ),
          hiringManager: this.userService.getUserName(
            ele.organizationData.hiringManager
          ),
          offerCreationDate: moment(ele.offerMetaData.date).format('YYYY-MM-DD')
        };
        this.replaceText(this.mailTemplate.templateBody, candidateObj, index);
        // this.addActivityForEachOffer(index);
      });
    } else {
      this.reviewmail = false;
      this.toaster.error('Please Select an offer ');
    }
  }

  //
  showOfferMoreDeatils(index) {
    this.moreDetailsToggle[index] = !this.moreDetailsToggle[index]
  }
  backToOfferList() {
    this.reviewmail = false;
  }

  // get bulk changed edit mail template
  getChangeMailTemp(data) {
    this.selectBulkOfferList.forEach((ele, index) => {
      const candidateObj = {
        firstName: ele.candidateData.firstName,
        lastName: ele.candidateData.lastName,
        email: ele.candidateData.emailId,
        dob: moment(ele.candidateData.dob).format('YYYY-MM-DD'),
        contactNumber: ele.candidateData.mobile,
        nameAsInPassport: ele.candidateData.nameAsInPassport,
        resumeSource: this.shared.getResumeSourceTitle(
          ele.candidateData.resumeSource
        ),
        skills: ele.candidateData.skills,
        title: ele.offerMetaData.title,
        offerDesignation: ele.offerMetaData.designation,
        businessUnit: this.shared.getOrgEntitiesTitle(
          ele.organizationData.businessUnit
        ),
        division: this.shared.getOrgEntitiesTitle(
          ele.organizationData.division
        ),
        department: this.shared.getOrgEntitiesTitle(
          ele.organizationData.department
        ),
        noticePeriod: ele.offerMetaData.noticePeriod,
        currency: ele.offerMetaData.currency,
        salary: ele.offerMetaData.salary,
        joiningDate: moment(ele.offerMetaData.joiningDate).format('YYYY-MM-DD'),
        expiryDate: moment(ele.offerMetaData.expiryDate).format('YYYY-MM-DD'),
        location: ele.organizationData.location,
        reportingManager: this.userService.getUserName(
          ele.organizationData.reportingManager
        ),
        taManager: this.userService.getUserName(ele.organizationData.taManager), //new
        hrManager: this.userService.getUserName(ele.organizationData.hrManager), //new
        recruiter: this.userService.getUserName(ele.organizationData.recruiter),
        hiringManager: this.userService.getUserName(
          ele.organizationData.hiringManager
        ),
        offerCreationDate: moment(ele.offerMetaData.date).format('YYYY-MM-DD')
      };
      this.replaceText(data, candidateObj, index);
    });
    this.step = '3';
  }

  // selectFinalOffers(index, checked, candidateModelData) {
  //   // this.selectFinalOfferList = [];
  //   if (checked === true) {
  //     candidateModelData.finalSelected = true;
  //     this.selectFinalOfferList.push(candidateModelData);
  //   } else {
  //     this.selectFinalOfferList.splice(index, 1);
  //   }
  // }
  // selectAllFinalOffers(checked) {
  //   this.selectFinalOfferList = [];
  //   if (checked === true) {
  //     this.selectBulkOfferList.forEach((element, index) => {
  //       element.finalSelected = checked;
  //     })

  //     this.selectBulkOfferList.forEach((element, index) => {
  //       if (element.finalSelected === true) {
  //         this.selectFinalOfferList.push(element);
  //       }
  //     });
  //   } else {
  //     this.selectBulkOfferList.forEach((element, index) => {
  //       element.finalSelected = false;
  //     })
  //     this.selectFinalOfferList = [];
  //   }
  // }

  //Nikhil Code
  selectFinalOffers(index, checked, candidateModelData) {
    // this.selectFinalOfferList = [];
    if (checked === true) {
      candidateModelData.finalSelected = true;
      if(candidateModelData.offerMetaData.noticePeriod == "" || candidateModelData.offerMetaData.noticePeriod == null){
        (candidateModelData.offerMetaData.noticePeriod = ((new Date(candidateModelData.offerMetaData.joiningDate).getTime()-new Date(candidateModelData.offerMetaData.offerDate).getTime())/ (1000 * 3600 * 24)));
      }
      this.selectFinalOfferList.push(candidateModelData);
    } else {
      this.selectFinalOfferList.splice(index, 1);
    }
  }
  selectAllFinalOffers(checked) {
    this.selectFinalOfferList = [];
    if (checked === true) {
      this.excelDataModel.forEach((element, index) => {
        if(element.dataCheckedFlag != true){
          element.finalSelected = checked;
          (element.offerMetaData.noticePeriod == "" || element.offerMetaData.noticePeriod == null) && (element.offerMetaData.noticePeriod = ((new Date(element.offerMetaData.joiningDate).getTime()-new Date(element.offerMetaData.offerDate).getTime())/ (1000 * 3600 * 24)));
        }
      })

      this.excelDataModel.forEach((element, index) => {
        if (element.finalSelected === true) {
          this.selectFinalOfferList.push(element);
        }
      });
    } else {
      this.excelDataModel.forEach((element, index) => {
        element.finalSelected = false;
      })
      this.selectFinalOfferList = [];
    }
  }

  async generateBulkOffers() {
    if (this.selectFinalOfferList.length > 0) {
      await this.selectFinalOfferList.forEach(async (e, index) => {
        await this.addActivityForEachOffer(index);
      })
      this.offerService.generateOffer(this.selectFinalOfferList).subscribe(
        res => {
          if (res) {
            this.step = '4';
            this.toaster.success('offer created successfully');
            this.router.navigate(['offers']);
            this.loader.stop();
          }
        },
        err => {
          this.loader.stop();
          this.toaster.error('something went wrong');
        }
      );
    } else {
      this.toaster.error('Please select an offer');
    }
  }

  //download excel template
  downloadExcelTemplate() {
    this.shared.getMasterAccToType('excelTemplate').subscribe(
      res => {
        let resp = res.body[0].data[0].data.items[0].title;
        window.open(resp, '_blank');
      },
      error => {
        this.toaster.error('Something went wrong');
      }
    );
  }
}
