import Amplify from "aws-amplify";
import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { from, Observable, combineLatest } from "rxjs";
import { API, Cache } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests, requestBody } from "../../models/requestModel";
import { roleStatements } from "../../../app-settings/role_settings";
import * as _ from "lodash";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private initRequests: InitRequests) { }

  //setting aws config
  setAwsConfigForOrg(userpoolId, appClientId, appClientSecret, oauthDetails) {
    let oauth = this.makeOathObject(oauthDetails);
    environment.awsConfig.Auth.userPoolId = userpoolId;
    environment.awsConfig.Auth.userPoolWebClientId = appClientId;
    environment.awsConfig.Auth.userPoolWebClientSecret = appClientSecret;
    environment.awsConfig.Auth.oauth = oauth; //setting oatuh for zool
    Amplify.configure(environment.awsConfig); //configuring aws amplify
  }

  private makeOathObject(details) {
    let oauth = {
      domain: details.oauth_domain,
      scope: details.oauth_scope,
      redirectSignIn: details.oauth_redirectedSignIn,
      redirectSignOut: details.oauth_redirectedSignOut,
      responseType: details.oauth_responseType
    };
    return oauth;
  }

  //hosted UI from frontEnd option
  federatedHostedUI() {
    return from(Auth.federatedSignIn());
  }

  //gettting current user
  getCurrentAuthUser() {
    return from(Auth.currentAuthenticatedUser());
  }
  //signout
  federatedSignout() {
    return from(Auth.signOut());
  }

  //setAuth role
  setAuthRole(role) {
    localStorage.setItem("userRole", role);
  }

  //getAuthRole
  getAuthRole() {
    return localStorage.getItem("userRole");
  }

  //here we will manage which routes is the person allowed to access
  roleMapping(role, route) {
    let result = false;
    let exceptionCase = route.split("/");
    if (exceptionCase[1] != "new") {
      //for letting person enter a created offer
      return true;
    } else {
      roleStatements.forEach(el => {
        if (el.role === role) {
          if (el.routesAllowed.includes(route)) {
            result = true;
          }
        }
      });
      return result;
    }
  }

  //if this function returns true apply disabled class
  roleViewMapping(role, route) {
    let result = false;
    let exceptionCase = route.split("/");
    // if (exceptionCase[1] != "new") {
    //   //for letting person enter a created offer
    //   return true;
    // }
    if (role === "GME") {
      //!this has to be changed
      if (exceptionCase[1] != "new") {
        //for letting person enter a created offer
        return true;
      }
    } else {
      roleStatements.forEach(el => {
        if (el.role === role) {
          if (el.disabledViewRoutes.includes(route)) {
            result = true;
          }
        }
      });
      return result;
    }
  }

  //get org roles
  apiName = apis.organizationService.organization.name;
  path = apis.organizationService.organization.endpoints.master;
  getOrgRoles() {
    const request = this.initRequests.initMaster({
      _action: "GET",
      orgHandle: localStorage.getItem("orgHandle"),
      queryBody: {
        masterType: "orgRoles"
      }
    });
    return from(API.post(this.apiName, this.path, request));
  }

  //saving roles locally
  roles: any;
  saveRolesLocally(roles) {
    this.roles = roles;
  }
  //get auth data after sign in
  public getCurrentSession() {
    return from(Auth.currentSession());
  }

  //post api for all requests with added headers
  public async post(apiName, path, data: requestBody): Promise<any> {
    const request: requestBody = JSON.parse(JSON.stringify(data));
    try {
      let session = await this.getCurrentSession().toPromise();
      if (session.isValid()) {
        const token = session.getIdToken().getJwtToken();
        request.headers.Authorization = token;
        request.headers["X-Api-Key"] = localStorage["orgHandle"];
      }
      return API.post(apiName, path, request);
    }
    catch (err) {
      return API.post(apiName, path, request);
    }
  }
}
