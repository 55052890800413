import { ToastrService } from "ngx-toastr";
import { AuthService } from "./../../services/auth/auth.service";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SettingsComponent } from "./component/settings/settings.component";
import { UserService } from "src/app/services/user/user.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedService } from "src/app/services/shared/shared.service";
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit {
  users: any;
  searchText: any;
  authRole: any;
  projection: any;
  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private loader: NgxUiLoaderService,
    public sharedService: SharedService,
    private auth: AuthService,
    private toastr: ToastrService
  ) {
    this.sharedService.getMastersData();
    this.authRole = this.auth.getAuthRole();
  }

  ngOnInit() {
    this.projection = {
      emailId: "1",
      _id: "0",
      displayName: "1",
      team: "1",
      role: "1",
      status: "1",
      reportsTo: "1",
      approvalStatus: "1"
    };
    this.getUser(this.projection);
  }
  openUserSettingmodal(userData) {
    const modalRef = this.modalService.open(SettingsComponent, {
      size: "lg",
      backdrop: "static",
      centered: true
    });
    modalRef.componentInstance.selectedUserData = userData;
  }
  getUser(projection) {
    this.loader.start();
    this.userService.getUsers(projection).subscribe(res => {
      this.users = res.body;
      this.checkApprovalStatus();
    });
    this.loader.stop();
  }

  checkApprovalStatus() {
    this.users.forEach(element => {
      if (!element.approvalStatus) {
        element.approvalText = "APPROVE";
      } else {
        element.approvalText = "APPROVED";
      }
    });
  }

  //aproval of user by GM
  approveUserFromGM(index) {
    let user = this.users[index];
    this.userService
      .updateUserDetails(user.emailId, { approvalStatus: true })
      .subscribe(
        res => {
          this.toastr.success("User Approved");
          this.getUser(this.projection);
        },
        error => {
          this.toastr.error("Something went wrong");
        }
      );
  }

  changeStatusOfuser(value, index) {
    let user = this.users[index];
    this.userService
      .updateUserDetails(user.emailId, { status: value })
      .subscribe(
        res => {
          this.getUser(this.projection);
        },
        error => {
          this.toastr.error("Something went wrong");
        }
      );
  }
}
