import { Component, OnInit, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditmailModalComponent } from './components/editmail-modal/editmail-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { routeNames } from 'src/app-settings/routes';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-sys-admin-organization',
  templateUrl: './sys-admin-organization.component.html',
  styleUrls: ['./sys-admin-organization.component.scss']
})
export class SysAdminOrganizationComponent implements OnInit {
  public mailTemplateForm: FormGroup;
  public mailTemplateData: any;
  public mailTemplates: any;
  orgDetails: any;
  // orgRole: any;
  orgHandle: any;
  updatedConfigData: any;
  orgConfigForm: FormGroup;
  fileDetails: any;
  orgLogo: any;
  uploadFilepayload: { dataType: any; fileFormat: any; fileData: any };
  resFileUrl: any;
  dataSubmitted: boolean;
  constructor(
    private location: Location,
    public config: NgbAccordionConfig,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    private router: Router,
    private orgService: OrganizationService,
    public authService: AuthService,
    private shared: SharedService
  ) {
    this.dataSubmitted = false;
    config.closeOthers = true;
    this.orgHandle = localStorage.getItem('orgHandle');

    this.orgConfigForm = this.fb.group({
      organizationName: ['', Validators.required],
      otherDetails: this.fb.group({
        orgDescription: ['', Validators.required],
        orgEmail: ['', Validators.required],
        // orgLogo: ['', Validators.required],
        orgVideo: ['']
      }),
      socialDetails: this.fb.group({
        facebook: [''],
        twitter: [''],
        linkedin: [''],
        website: ['']
      })
    });
  }

  ngOnInit() {
    this.getMailTemplates();
    // this.getOrgHandle();
    this.getOrgDetails(this.orgHandle);

    this.uploadFilepayload = {
      dataType: null,
      fileFormat: null,
      fileData: null
    };
  }

  backClicked() {
    this.location.back();
  }

  // opening the email model
  openeditmailmodal(data, title, create) {
    if (typeof data === 'object') {
      const modalRef = this.modalService.open(EditmailModalComponent, {
        size: 'lg',
        backdrop: 'static',
        centered: true
      });
      modalRef.componentInstance.mailTempData = data;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.create = create;
    }
  }

  // get all mail temp code and value
  getMailTemplates() {
    this.sharedService.getMailTemplate().subscribe(res => {
      let templates = res.body[0].data[0].data.items;
      this.mailTemplates = templates.filter(el => {
        return el.code != 'EVI' && el.code != 'OFW';
      });
    });
  }

  // get email template Data
  getemailTemp(code, title) {
    this.loader.start();
    const payload = {
      templateCode: code
    };
    this.sharedService.getEmailTempDataByTempCode(payload).subscribe(
      res => {
        if (res.body) {
          this.openeditmailmodal(res.body, title, false);
        } else {
          let emptyPayload = {
            mailSubject: '',
            templateBody: '',
            templateCode: code
          };
          this.openeditmailmodal(emptyPayload, title, true); //opeing empty modal
        }
        this.loader.stop();
      },
      err => {
        this.loader.stop();
        this.toaster.error('No data Found');
      }
    );
  }

  // getOrgHandle() {
  //   this.authService.getOrgRoles().subscribe(
  //     res => {
  //       this.orgRole = res.body[0].data[0];
  //       this.loader.stop();
  //       console.log(this.orgRole);
  //     },
  //     error => {
  //       this.toaster.error('somthing went wrong');
  //       console.log(error);
  //     }
  //   );
  // }

  getOrgDetails(orgHandle) {
    this.loader.start();
    this.orgService.getOrgConfigDetails(orgHandle).subscribe(
      res => {
        this.orgDetails = res.body;
        console.log(this.orgDetails);
        this.loader.stop();
        this.orgConfigForm.patchValue({
          organizationName: this.orgDetails.organizationName,
          otherDetails: {
            orgDescription: this.orgDetails.otherDetails.orgDescription,
            orgEmail: this.orgDetails.otherDetails.orgEmail,
            // orgLogo: this.orgDetails.otherDetails.orgLogo,
            orgVideo: this.orgDetails.otherDetails.orgVideo
          },
          socialDetails: {
            facebook: this.orgDetails.socialDetails.facebook,
            twitter: this.orgDetails.socialDetails.twitter,
            linkedin: this.orgDetails.socialDetails.linkedin,
            website: this.orgDetails.socialDetails.website
          }
        });
        this.orgLogo = 'url(' + this.orgDetails.otherDetails.orgLogo + ')';
        this.uploadFilepayload = {
          dataType: null,
          fileFormat: null,
          fileData: null
        };
      },
      error => {
        this.toaster.error('somthing went wrong');
        console.log(error);
      }
    );
  }
  submitOrgConfigData(fileUrl) {
    const payload = {
      organizationName: this.orgConfigForm.get('organizationName').value,
      otherDetails: {
        orgDescription: this.orgConfigForm
          .get('otherDetails')
          .get('orgDescription').value,
        orgEmail: this.orgConfigForm.get('otherDetails').get('orgEmail').value,
        orgLogo: fileUrl,
        orgVideo: this.orgConfigForm.get('otherDetails').get('orgVideo').value
      },
      socialDetails: {
        facebook: this.orgConfigForm.get('socialDetails').get('facebook').value,
        twitter: this.orgConfigForm.get('socialDetails').get('twitter').value,
        linkedin: this.orgConfigForm.get('socialDetails').get('linkedin').value,
        website: this.orgConfigForm.get('socialDetails').get('website').value
      }
    };
    return payload;
    // this.updateOrgDetails(this.orgHandle, payload);
  }

  updateOrgDetails(orgHandle, updatedConfigData, callback) {
    this.loader.start();
    this.orgService
      .updateOrgConfigDetails(orgHandle, updatedConfigData)
      .subscribe(
        res => {
          this.toaster.success('Data Updated SucessFully');
          this.loader.stop();
          callback(true);
        },
        err => {
          this.loader.stop();
          this.toaster.error('Something went wrong');
          callback(false);
          console.log(err);
        }
      );
  }

  onFileChanged(e) {
    this.fileDetails = e;
    this.shared.onFileChanged(this.fileDetails).then((data: any) => {
      const uploadData = data.fileData.split(',')[1];
      const type = data.fileData.split('/')[0].split(':')[1];
      const format = data.fileData.split('/')[1].split(';')[0];
      this.uploadFilepayload = {
        dataType: type,
        fileFormat: format,
        fileData: uploadData
      };
      // console.log(this.uploadFilepayload);
    });
  }

  uploadOrgLogo(payload, callback) {
    // console.log(payload);
    this.loader.start();
    this.orgService
      .uploadOrgConfigData(
        payload.dataType,
        payload.fileFormat,
        payload.fileData
      )
      .subscribe(
        res => {
          this.loader.stop();
          this.toaster.success('File uploaded sucessfully');
          this.resFileUrl = res.fileLink;
          callback(true);
          // console.log(this.resFileUrl);
        },
        err => {
          this.loader.stop();
          this.toaster.error('Somthing went wrong');
          console.log(err);
          callback(false);
        }
      );
  }

  // submitData() {
  //   let payload = null;
  //   if (this.uploadFilepayload.dataType) {
  //     this.uploadOrgLogo(
  //       this.uploadFilepayload.dataType,
  //       this.uploadFilepayload.fileFormat,
  //       this.uploadFilepayload.fileData
  //     );
  //     payload = this.submitOrgConfigData(this.resFileUrl);
  //     this.updateOrgDetails(this.orgHandle, payload, res => {
  //       if (res) {
  //         this.getOrgDetails(this.orgHandle);
  //       }
  //     });
  //   } else {
  //     if (this.uploadFilepayload.dataType) {
  //       payload = this.submitOrgConfigData(this.resFileUrl);
  //       this.updateOrgDetails(this.orgHandle, payload, res => {
  //         if (res) {
  //           this.getOrgDetails(this.orgHandle);
  //         }
  //       });
  //     } else {
  //       payload = this.submitOrgConfigData(
  //         this.orgDetails.otherDetails.orgLogo
  //       );
  //       this.updateOrgDetails(this.orgHandle, payload, res => {
  //         if (res) {
  //           this.getOrgDetails(this.orgHandle);
  //         }
  //       });
  //     }

  //     this.uploadFilepayload = {
  //       dataType: null,
  //       fileFormat: null,
  //       fileData: null
  //     };
  //   }
  // }

  submitData() {
    this.dataSubmitted = true;
    if (this.orgConfigForm.valid) {
      let payload = null;
      if (this.uploadFilepayload.fileData) {
        this.uploadOrgLogo(this.uploadFilepayload, res => {
          if (res) {
            payload = this.submitOrgConfigData(this.resFileUrl);
            this.updateOrgDetails(this.orgHandle, payload, (res: any) => {
              if (res) {
                this.getOrgDetails(this.orgHandle);
              }
            });
          }
        });
        // upload and then update
      } else {
        payload = this.submitOrgConfigData(
          this.orgDetails.otherDetails.orgLogo
        );
        this.updateOrgDetails(this.orgHandle, payload, res => {
          if (res) {
            this.getOrgDetails(this.orgHandle);
          }
        });
        // update the data
      }
    }
    // else {
    //   alert('invalid');
    // }
  }
}
