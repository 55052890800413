import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ToastrService } from 'ngx-toastr';
import { fstat } from 'fs';
import { EngagementService } from 'src/app/services/engagement/engagement.service';


@Component({
  selector: 'app-user-activity-feedback-modal',
  templateUrl: './user-activity-feedback-modal.component.html',
  styleUrls: ['./user-activity-feedback-modal.component.scss']
})
export class UserActivityFeedbackModalComponent implements OnInit {


public feedData:any;
  public feedbackMaster:any;
  // public isCompleted:boolean=true;

  @Output() feedbackFormClosed = new EventEmitter<any>();
  @Input() staticModalData: any;


  constructor(
    public activeModal:NgbActiveModal,public engService:EngagementService ,public loader:NgxUiLoaderService,
    private toastr: ToastrService
    ) { }

  feedbackPayload: { offerId: any; groupName: any; itemCode: any; emailId: any; date: any;feedbackType:any, feedbackData: {}; };
  // @Output() feedbackFormClosed = new EventEmitter<any>();

  ngOnInit() {

    this.getFeedbackMasterConfig()
  }

  closeModal() {
    this.feedbackFormClosed.emit(status);
    this.activeModal.close();
  }




  // getFeedbackData
  getFeedbackData(offerData:any) {
    this.engService
      .getEngagementFeedbackTable(offerData.offerId)
      .subscribe(
        res => {
          console.log('getFeedbackData >>>',res);
          // console.log('offerData >>>',offerData);
          console.log('feedbackMaster >>',this.feedbackMaster);

          this.feedData = res.body.filter(element => {
            if ((element.feedbackData.length > 0) && (element.feedbackData[0].itemCode === offerData.code)){
              console.log(element);
              return element
            }
          });

          // this.feedbackMaster = res.body[0].feedbackData;
          console.log('feedData >>>',this.feedData);
        }, error => {
          this.toastr.error("Something went wrong while getting data")
        })
  }


  // getFeedbackMasterConfig
  getFeedbackMasterConfig() {
    this.engService.getEngFeedbackMaster().subscribe((res) => {
      this.feedbackMaster = res.body;
      // console.log(res)
      // console.log('feedbackMaster >>',this.feedbackMaster);
      console.log('staticModalData >>',this.staticModalData);

      if (this.staticModalData) {
        this.getFeedbackData(this.staticModalData);
      }
    }, error => {
      this.toastr.error("Error getting data");
    })

  }


}
