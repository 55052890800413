import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() step: any;
  stratLineOne: boolean;
  stratLineTwo: boolean;
  stratLineThree: boolean;
  startStep1: boolean;
  startStep2: boolean;
  startStep3: boolean;
  startStep4: boolean;
  constructor() {
    this.startStep1 = false;
    this.startStep2 = false;
    this.startStep3 = false;
    this.startStep4 = false;
    this.stratLineOne = false;
    this.stratLineTwo = false;
    this.stratLineThree = false;
  }

  ngOnInit() {

  }
  ngOnChanges(changes: any): void {
    if (changes && changes.step && changes.step.currentValue) {
      this.step = changes.step.currentValue;
      this.changeStepBar();
    }
  }
  changeStepBar() {
    if (this.step === "1") {
      this.stratLineOne = true;
      this.stratLineTwo = false;
      this.stratLineThree = false;
      this.startStep1 = true;
      this.startStep2 = false;
      this.startStep3 = false;
      this.startStep4 = false;
    } else if (this.step === "2") {
      this.stratLineOne = true;
      this.stratLineTwo = true;
      this.stratLineThree = false;
      this.startStep1 = true;
      this.startStep2 = true;
      this.startStep3 = false;
      this.startStep4 = false;
    } else if (this.step === "3") {
      this.stratLineOne = true;
      this.stratLineTwo = true;
      this.stratLineThree = true;
      this.startStep1 = true;
      this.startStep2 = true;
      this.startStep3 = true;
      this.startStep4 = false;

    } else if (this.step === "4") {
      this.stratLineOne = true;
      this.stratLineTwo = true;
      this.stratLineThree = true;
      this.startStep1 = true;
      this.startStep2 = true;
      this.startStep3 = true;
      this.startStep4 = true;
    }

  }
}
