import { Component, OnInit } from "@angular/core";
import { CreateStageModalComponent } from "../create-stage-modal/create-stage-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StageService } from "../../../../services/stage/stage.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from "../../../../services/shared/shared.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-engage-stage-list",
  templateUrl: "./engage-stage-list.component.html",
  styleUrls: ["./engage-stage-list.component.scss"]
})
export class EngageStageListComponent implements OnInit {
  //variables

  stageList = [];
  constructor(private modalService: NgbModal, private stageService: StageService,
    private shared: SharedService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService) { }
  ngOnInit() {
    this.getStages();
  }
  openModal(id, name, description) {
    const modalRef = this.modalService.open(CreateStageModalComponent, {
      windowClass: "engage-edit-modal",
      size:'lg',
      centered: true
    });
    modalRef.componentInstance.data = { id, name, description };
    modalRef.result.then((res) => {
      if (res) {
        if (res.update) {
          console.log(res);
          this.updateStage(res.data);
        }
        else {
          this.addStage(res.data);
        }
      }
    }).catch(err => {
      console.log("accidental close")
    })
  }

  //get stages listing
  private getStages() {
    const payload = { type: "stage" }
    this.loader.start()
    this.stageService.getStages(payload).subscribe((res) => {
      this.loader.stop();
      this.stageList = res.body;
    }, error => {
      this.loader.stop();
      this.toaster.error("Error getting engagement stages");
    })
  }
  //add stage
  private addStage(data) {
    this.loader.start();
    const payload = { type: "stage", name: data.name, description: data.description, digital: false };
    this.stageService.createStage(payload).subscribe((res) => {
      this.toaster.success("Engagement stage created");
      this.getStages();
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error creating engagement stage");
    })
  }
  //update stage
  private updateStage(data) {
    this.loader.start();
    const payload = { id: data.id, body: { type: "stage", name: data.name, description: data.description, digital: false } };
    console.log(data, payload)
    this.stageService.updateStage(payload).subscribe((res) => {
      this.toaster.success("Engagement stage updated");
      this.getStages();
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error updating engagement stage");
    })
  }
}
