import { Component, OnInit, ViewChild, Input, OnChanges } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { SharedService } from "src/app/services/shared/shared.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { MasterTypes, templateCodes } from "src/app-settings/master_types";

@Component({
  selector: "app-editmail-modal",
  templateUrl: "./editmail-modal.component.html",
  styleUrls: ["./editmail-modal.component.scss"]
})
export class EditmailModalComponent implements OnInit {
  @ViewChild("modalmailbox") modal: any;
  @Input() mailTempData: any;
  @Input() title: any;
  @Input() create: any;
  mailTemplateForm: FormGroup;
  submitted: boolean;
  htmlContent2: any;
  htmlContent: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };
  createFlag: true;
  updateFlag: false;
  range: any;
  offerFormData: any[] = [];
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService
  ) {
    this.submitted = false;
    this.getMailMasterData();
  }

  ngOnInit() {
    this.mailTemplateForm = this.fb.group({
      templateBody: ["", Validators.required],
      mailSubject: [""]
    });
    if (this.mailTempData) {
      if (typeof this.mailTempData === "object") {
        this.mailTemplateForm.patchValue({
          templateBody: this.mailTempData.templateBody,
          mailSubject: this.mailTempData.mailSubject
        });
      }
    }
  }
  // create mail temp
  upateMailTemplate() {
    if (this.create) {
      const createBody = {
        templateCode: this.mailTempData.templateCode,
        templateBody: document.getElementById("editor").innerHTML,
        mailSubject: this.mailTemplateForm.value.mailSubject
      };
      this.loader.start();
      this.sharedService.createMailTemplate(createBody).subscribe(
        res => {
          this.loader.stop();
          this.activeModal.close("Cross click");
          this.toaster.success("Template updated sucessfully.");
        },
        error => {
          this.loader.stop();
          this.toaster.error("something went wrong");
        }
      );
    } else {
      const tempBody = this.mailTemplateForm.value;
      const updateBody = {
        templateCode: this.mailTempData.templateCode,
        body: {
          templateBody: document.getElementById("editor").innerHTML,
          mailSubject: tempBody.mailSubject
        }
      };
      this.loader.start();
      this.sharedService.updateMailTemplate(updateBody).subscribe(
        res => {
          this.loader.stop();
          this.activeModal.close("Cross click");
          this.toaster.success("Template updated sucessfully.");
        },
        error => {
          this.loader.stop();
          this.toaster.error("something went wrong.");
        }
      );
    }
  }
  saveRange() {
    let sel;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        this.range = sel.getRangeAt(0);
      }
    }
  }

  async insertTextAtCursor(value) {
    this.range.deleteContents();
    this.range.insertNode(document.createTextNode(value));
  }
  getMailMasterData() {
    this.sharedService
      .getMailMaster(MasterTypes.mailTempMaster, templateCodes.offerTemplate)
      .subscribe(res => {
        if (res) {
          this.offerFormData = res.body[0].data[0].data.items;
        }
      });
  }
}
