//  This file can be replaced during build by using the `fileReplacements` array.
//  `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
//  The list of file replacements can be found in `angular.json`.

import { aws, apis } from "../app-settings/aws-config";
import { version } from "../../package.json";

export const environment = {
  production: false,
  versionNumber: version,
  awsConfig: {
    Auth: {
      mandatorySignIn: false, // for now no mandatory signin
      region: aws.region,
      userPoolId: null, //will be set dynamically
      //  identityPoolId: aws.identityPoolId,
      userPoolWebClientId: null, //will be set dynamically
      userPoolWebClientSecret: null,
      oauth: null //this is required to open stuff
    },
    API: {
      endpoints: [
        {
          name: apis.hireloopService.hireloop.name,
          endpoint: apis.hireloopService.baseUrl,
          region: aws.region
        },
        {
          name: apis.organizationService.organization.name,
          endpoint: apis.organizationService.baseUrl,
          region: aws.region
        }
      ]
    }
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//  import 'zone.js/dist/zone-error';  //  Included with Angular CLI.
