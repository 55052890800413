import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { routeNames } from "../../../../../app-settings/routes";
import { EngagementTemplateService } from "../../../../services/engagementTemplate/engagement-template.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader"
@Component({
  selector: "app-engage-list",
  templateUrl: "./engage-list.component.html",
  styleUrls: ["./engage-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EngageListComponent implements OnInit {
  templates = []
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private engagementTemplateService: EngagementTemplateService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService) { }

  ngOnInit() {
    this.getEngagementTemplates();
  }
  // getting engagement templates
  private getEngagementTemplates() {
    this.loader.start()
    this.engagementTemplateService.getAllTemplates().subscribe((res) => {
      this.templates = res.body;
      this.loader.stop();
    }, (error) => {
      this.loader.stop();
      this.toaster.error("Error getting engagement templates")
    })
  }
  //redirect to template
  redirectToTemplateSetup(template) {
    template ? this.router.navigate([routeNames.engagementTemplateSetup.fullRoute, template._id]) : this.router.navigate([routeNames.engagementTemplateSetup.fullRoute])
  }
}
