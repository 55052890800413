export const roleStatements = [
  {
    role: 'GM',
    routesAllowed: [
      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: [
      'organizationalentities'
    ]
  },
  {
    role: 'RECT',
    routesAllowed: [
      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities']
  },
  {
    role: 'RCTM',
    routesAllowed: [
      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities']
  },
  {
    role: 'SYSA',
    routesAllowed: [
      '',
      'login',
      'logout',
      'dashsettings',
      'sysadmin',
      'user/settings',
      'integration',
      'organizationalentities'
    ],
    disabledViewRoutes: []
  },
  {
    role: 'HRM',
    routesAllowed: [
      // '',
      // 'login',
      // 'logout',
      // 'dashsettings',
      // 'dashboard',
      // 'dashsettings',
      // 'engagement',
      // 'user/settings',
      // 'organizationalentities'

      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities', "offer"]
  },
  {
    role: 'HRMN',
    routesAllowed: [
      // '',
      // 'login',
      // 'logout',
      // 'dashsettings',
      // 'dashboard',
      // 'engagement',
      // 'user/settings',
      // 'organizationalentities'

      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities']
  },
  {
    role: 'TAM',
    routesAllowed: [
      // '',
      // 'login',
      // 'logout',
      // 'dashsettings',
      // 'dashboard',
      // 'engagement',
      // 'user/settings',
      // 'organizationalentities'

      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities']
  },
  {
    role: 'RPM',
    routesAllowed: [
      '',
      'login',
      'logout',
      'dashsettings',
      'dashboard',
      'dashsettings',
      'engagement',
      'user/settings',
      'organizationalentities'
    ],
    disabledViewRoutes: ['organizationalentities']
  },
  {
    role: 'DEV',
    routesAllowed: [
      '',
      'login',
      'offers',
      'logout',
      'offer',
      'dashboard',
      'dashsettings',
      'offer/new',
      'engagement',
      'user/settings',
      'sysadmin',
      'integration',
      'organizationalentities'
    ],
    disabledViewRoutes: []
  },
  {
    role: 'BU',
    routesAllowed: [
      '',
      'login',
      'logout',
      'dashsettings',
      'dashboard',
      'engagement',
      'offer',
      'offers',
      'offer/new',
      'organizationalentities'
    ],
    disabledViewRoutes: [
      'offer',
      'offers',
      'engagement',
      'dashboard',
      'organizationalentities'
    ]
  }
];
