import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-joinersand-dropout-table',
  templateUrl: './joinersand-dropout-table.component.html',
  styleUrls: ['./joinersand-dropout-table.component.scss']
})
export class JoinersandDropoutTableComponent implements OnInit {
  @Input("offerData") offerData: any[];
  @Input("tableName") tableName: any;

  constructor(
    public userService: UserService,
  ) { }

  ngOnChanges(changes: any): void {
    this.offerData.forEach(offer=>{
      console.log(offer);
    })
  }

  ngOnInit() {
    console.log(this.offerData);
    this.userService.getUser();
  }

  getJoinedPercentage(x, y){
    let totalPercantage = Math.round(x/(x+y)*100);
    return (totalPercantage) ? totalPercantage+'%' : '-';
  }

  getDropoutPercentage(x, y) {
    let totalPercantage = Math.round(y/(x+y)*100);
    return (totalPercantage) ? totalPercantage+'%' : '-';
  }

}
