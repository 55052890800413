import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared/shared.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MasterTypes } from 'src/app-settings/master_types';

@Component({
  selector: 'app-settings-dashboard',
  templateUrl: './settings-dashboard.component.html',
  styleUrls: ['./settings-dashboard.component.scss']
})
export class SettingsDashboardComponent implements OnInit {
  imgURL: any;
  authUser: any;
  userDetails: any;
  public userForm: FormGroup;
  userInitials: any;
  userRole: string;
  submitted: any;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    public sharedService: SharedService,
    private fb: FormBuilder
  ) {
    this.imgURL = ['../../../assets/images/user.svg'];
    this.userForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.email]],
      displayName: ['', Validators.required],
      team: ['', Validators.required],
      role: ['', Validators.required],
      status: ['active'],
      reportsTo: ['', Validators.required]
    });
    this.userService.getUser();
    this.sharedService.getMastersData();
  }
  previewuser(files) {
    if (files.length > 0) {
      const mimeType = files[0].type;
      //  this.message = "";

      if (mimeType.match(/image\/*/) == null) {
        //  this.message = "Upload Only .jpeg, .png, .jpg file";
        return;
      } else {
        //  this.uploadStatus = true;
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = event => {
        console.log('Image Load ', event);
        this.imgURL = reader.result;
        //  this.imageFile = files[0];
      };
    }
  }
  ngOnInit() {
    this.getAccountDetails();
  }

  getAccountDetails() {
    this.loader.start();
    this.authService.getCurrentAuthUser().subscribe(
      res => {
        this.authUser = res;
        const userEmailId = res.attributes.email;
        const initials = userEmailId.split('');
        this.userInitials = initials[0];
        this.getUserDetails(userEmailId);
        this.loader.stop();
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error(
          'something went wrong  while getting the account details.'
        );
      }
    );
  }

  getUserDetails(email) {
    this.loader.start();
    this.userService.getUserDetails(email).subscribe(
      res => {
        this.userDetails = res.body;
        this.userRole = this.sharedService.getOrgRoleTitle(
          this.userDetails.role
        );
        this.userForm.patchValue({
          emailId: this.userDetails.emailId,
          displayName: this.userDetails.displayName,
          team: this.userDetails.team,
          role: this.userDetails.role,
          status: 'active',
          reportsTo: this.userDetails.reportsTo
        });

        this.loader.stop();
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error(
          'something went wrong while getting the user details'
        );
      }
    );
  }

  updateUser(updateBody) {
    this.loader.start();
    this.userService.updateUser(updateBody).subscribe(
      res => {
        this.loader.stop();
        this.toaster.success('User updated sucessfully.');
        this.getAccountDetails();
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error('something went wrong.');
      }
    );
  }

  receiveSubmitedData($event) {
    this.submitted = $event;
    const data = {
      emailId: this.userDetails.emailId,
      body: {
        emailId: this.submitted.emailId,
        displayName: this.submitted.displayName,
        team: this.submitted.team,
        role: this.submitted.role,
        status: this.submitted.status,
        reportsTo: this.submitted.reportsTo
      }
    };
    this.updateUser(data);
  }
}
