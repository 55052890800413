import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EngagementService } from 'src/app/services/engagement/engagement.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from "src/app/services/user/user.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { StageService } from "../../../../services/stage/stage.service";
import { EngagementTemplateService } from "../../../../services/engagementTemplate/engagement-template.service";
import { SharedService } from "src/app/services/shared/shared.service";
import * as moment from "moment";
import { AuthService } from "../../../../services/auth/auth.service";
// import { ConsoleReporter } from 'jasmine';

export interface stageInterface {
  id: string,
  code: string,
  _data?: any
  activities?: []
}

export interface activityInterface {
  id: string;
  code: string;
  _data?: any;
  rules: [{
    condition: string,
    action: string,
    time: string,
    days: number,
    role: string
  }
  ],
  ruleAssociation?: string,
}

@Component({
  selector: 'app-engagement-assosiate-activity',
  templateUrl: './engagement-assosiate-activity.component.html',
  styleUrls: ['./engagement-assosiate-activity.component.scss']
})
export class EngagementAssosiateActivityComponent implements OnInit {

  candidateFullName: any;
  recuiterEmailId: any;

  innteractionTypeList: any;
  innteractionTypeList_client: any;
  innteractionTypeList_ea: any;
  innteractionTypeList_digital: any;
  // riskAreasList1: string[];
  riskAssesmentList: any;
  activityTypeList: any;

  allUsers: any;
  projection: any;
  authRole: any;

  riskAreaValue: any;
  riskAssesmentValue: any;
  interventionSummary: any;

  interventionDesc: any;
  interventionName: any;
  interactionType: any;
  activityType_model: any;
  emailSelected: any;
  calenderPicked: any;
  todayDate: any;

  templateData: any;
  stages: any[] = [];
  engamentMasterData: any[] = [];
  engagementData: any = [];
  stageMaster: any[] = [];

  engagementStageList: any[] = [];
  engagementActivityList: any[] = [];

  offerData: any;
  engagementModel = {};

  activityData: activityInterface[] = [];

  templateId: any;
  type = "activity";
  actionFromOpeningModal = "edit";


  selected: any = false;
  selectedItems = [];
  isInterventionVisible: boolean = false;

  public validateInput: any;
  public feedbackType: any;
  public feedbackMaster: any;

  @Input() public candidateDataFromModal;
  @Input() public feedbackModalData: any;
  @Output() submitFeedbackFormData = new EventEmitter<any>();
  @Output() feedbackFormClosed = new EventEmitter<any>();
  @Input() public staticModalData: any;

  selectedIndex: number = 0;

  public question = [
    {
      question: "Delay in joining date",
      type: "dropdown",
      value: null,
      flag: 'high',
    },
    {
      question: "Low responsiveness to EA",
      type: "dropdown",
      value: null,
      flag: 'medium',
    },
    {
      question: "Last 2 DI's unopened",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Stated dissatisfaction with compensation",
      type: "dropdown",
      value: null,
      flag: 'high',
    },
    {
      question: "Still waiting for other offers",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Role/ Growth Concerns",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Onboarding milestones completion",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Notice period longer than 45 days",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Relocation requirement",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Low organization brand perception",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Professional background",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
    {
      question: "Personal background",
      type: "dropdown",
      value: null,
      flag: 'low',
    },
];


  constructor(
    public activeModal: NgbActiveModal,
    public engService: EngagementService,
    public loader: NgxUiLoaderService,
    public toaster: ToastrService,
    public userService: UserService,
    private stageService: StageService,
    private templateService: EngagementTemplateService,
    private sharedService: SharedService,
    private engagementService: EngagementService,
    private auth: AuthService,
  ) {
    this.authRole = this.auth.getAuthRole();
  }
  // dropdownList = [];
  riskAreasList = [];
  dropdownSettings: IDropdownSettings = {};

  public feedbackPayload: { offerId: any; groupName: any; itemCode: any; emailId: any; date: any; feedbackType: any, feedbackData: {}, eafeedbackData: {} };


  riskAreas1 = new FormControl();


  multiSelected() {

  }
  ngOnInit() {
    console.log(this.staticModalData)
    this.todayDate = new Date().toISOString().slice(0, 10);
    // console.log(this.todayDate);
    this.getFeedbackMasterConfig()
    // this.getTemplateData();
    this.offerData = this.candidateDataFromModal;
    console.log(this.candidateDataFromModal);
    if(this.offerData){
      this.gettingAlreadyExistingEngagementData();
    }
    // this.getActvitiesAndStages(this.type)

    this.riskAreasList = [
      { item_id: 1, item_text: 'Compensation' },
      { item_id: 2, item_text: 'Role Clarity' },
      { item_id: 3, item_text: 'Change in Joining Date' },
      { item_id: 4, item_text: 'Unresponsive to EA' },
      { item_id: 5, item_text: 'Relocation requirements' },
      { item_id: 6, item_text: 'Location proximity' },
      { item_id: 7, item_text: 'WFH options' },
      { item_id: 8, item_text: 'Comp and Benefits' },
      { item_id: 9, item_text: 'Personal Reasons' },
      { item_id: 10, item_text: 'Health reasons' },
      { item_id: 11, item_text: 'Retention efforts in the current organisation' },
      { item_id: 12, item_text: 'Project/Client Dependency in the current organisation' },
      { item_id: 13, item_text: 'Counter Offers received' },
      { item_id: 14, item_text: 'Counter Offers In Pipeline' },
      { item_id: 15, item_text: 'Brand Awareness' },
      { item_id: 16, item_text: 'Resignation not accepted' },
      { item_id: 17, item_text: 'Last Working Day not confirmed' },

    ];

    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
    };

    // this.riskAreasList = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    console.log('feedbackModalData >>', this.feedbackModalData)
    this.projection = {
      emailId: "1",
      _id: "0",
      displayName: "1",
      team: "1",
      role: "1",
      status: "1",
      reportsTo: "1",
      approvalStatus: "1"
    };
    this.getUser(this.projection);

    // this.innteractionTypeList = [
    //   'Email',
    //   '1 on 1 y',
    //   'Whatsapp Msg',
    //   'HR to connect ',
    //   'Senior leader connect',
    //   'Share Infographics ',
    //   'Hiring Manager Connect ',
    //   'Share company Insights',
    //   'Provide Benefits of joining the company ',
    //   'Option to add an Intervention '
    // ];

    this.innteractionTypeList_client = [
      'Welcome email from recruiter',
      'Recruiter connect',
      'Senior leader connect',
      'Hiring Manager connect',
      'Revised offer letter'
    ];

    this.innteractionTypeList_ea = [
      'Follow up Call',
      'Risk assessment call',
      'EA connect'
    ];

    this.innteractionTypeList_digital = [
      'Monetary Benefits',
      'Share details on Non - Monetary Benefits',
      'Covid Support Provided by the company',
      'Workplace Policies',
      'Perks',
      'Leave Policy details',
      'Partnership details',
      'Share Company overview',
      'Welcome Email',
      'Fun at work',
      'Rewards Program',
      'Growth Path',
      'Company Road map',
      'Know us better',
      'Work Culture',
      'Values'
    ];

    this.riskAssesmentList = [
      'Very high',
      'High',
      'Medium',
      'Low'
    ]
    this.activityTypeList = [
      {code:'EAACT', title:'EA'},
      {code:'CHACT', title:'Client'},
      {code:'DIGACT', title:'Digital'},
      {code:'OTHACT', title:'Other'},
    ]
  }

  onItemSelect(item: any) {
    // console.log(item);
    console.log('selectedItems >>',this.selectedItems);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  private addTask() {
    this.loader.start();
    const payload = {
      type: "activity",
      name: this.interventionName,
      description: this.interventionDesc,
      activityType: this.activityType_model,
      interactionType: this.interactionType
    };
    this.stageService.createStage(payload).subscribe((res) => {
      // this.toaster.success("Engagement activity created");
      // this.getTasks();
      // console.log('activity created');
      this.getActvitiesAndStages(this.type)

      this.loader.stop();
    }, error => {
      this.loader.stop();
      console.log('activity came but failed');
      // this.toaster.error("Error creating engagement activity");
    })
  }

  // function to send th feedback data along with the intervation activity creation in EA button.
  public submitEngagementFeedback() {

    this.feedbackPayload = {

      offerId: this.feedbackModalData.offerId,
      groupName: this.feedbackModalData.item,
      itemCode: this.feedbackModalData.data,
      emailId: this.feedbackModalData.assigned,
      date: new Date().toISOString(),
      feedbackType: 'EAFEED',
      feedbackData: this.feedbackMaster,
      eafeedbackData: {
        riskAreaValue: this.riskAreaValue,
        riskArea: this.selectedItems,
        riskAssesmentValue: this.riskAssesmentValue,
        interventionSummary: this.interventionSummary,
        interventionNeed: this.selected,
        itemCode: this.feedbackModalData.item,
        interventionName: this.interventionName,
        interventionDesc: this.interventionDesc,
        interactionType: this.interactionType,
        emailSelected: this.emailSelected,
        activityType_model: this.activityType_model,
        calenderPicked: this.calenderPicked,
        intervationCreatedDate: this.todayDate
      }
    };
    console.log(this.feedbackPayload);

    this.submitFeedbackData(this.feedbackPayload);
  }

  // function to send only the feedback data for EA activity
  public submitEngagementFeedback_maindata() {

    this.feedbackPayload = {

      offerId: this.feedbackModalData.offerId,
      groupName: this.feedbackModalData.item,
      itemCode: this.feedbackModalData.data,
      emailId: this.feedbackModalData.assigned,
      date: new Date().toISOString(),
      feedbackType: 'EAFEED',
      feedbackData: this.feedbackMaster,
      eafeedbackData: []
    };
    console.log(this.feedbackPayload);

    this.submitFeedbackData(this.feedbackPayload);
  }


  // submit EA feedback payload
  public submitFeedbackData(feedback) {
    console.log('clientFeedback >>', feedback);
    this.loader.start();
    console.log(feedback.eafeedbackData.length)
    // console.log(feedback);
    let orgHandle = localStorage.getItem("orgHandle");
    let userName = JSON.parse(localStorage.getItem("_user_dim"));
    let engagementMainData = {
      engFedback: feedback,
      candidateName: this.candidateFullName,
      RecuriterId: this.recuiterEmailId,
      orgHandle: orgHandle,
      activityName: this.feedbackModalData.activityName,
      userName: userName.name
    }
    console.log(engagementMainData, userName.name);

    this.engService.submitFeedback(engagementMainData).subscribe(
      res => {
        this.loader.stop();
        (feedback.eafeedbackData.length != 0 && feedback.eafeedbackData.interventionNeed) ? this.addTask() : this.submitdata();
        this.toaster.success('Feedback submitted sucessfully');

        // this.dismissModal();
        // this.submitdata();
      },
      error => {
        this.loader.stop();
        this.toaster.error('something went wrong.');
      }
    );
  }

  submitdata() {
    this.submitFeedbackFormData.emit(this.feedbackModalData.value);
    this.activeModal.close();
  }



  // getFeedbackMasterConfig
  getFeedbackMasterConfig() {
    this.feedbackMaster = this.question;
    console.log(this.feedbackMaster);

    if (this.staticModalData) {
      this.getFeedbackData(this.staticModalData);
      console.log(this.staticModalData);
    }
    // this.engService.getEngFeedbackMaster().subscribe((res) => {
    //   // this.feedbackMaster = res.body;

    //   this.feedbackMaster = this.question;
    //   console.log(this.feedbackMaster)


    // }, error => {
    //   this.toaster.error("Error getting data");
    // })

  }

  // getFeedbackData
  getFeedbackData(offerData: any) {
    console.log(offerData)
    this.engService
      .getEngagementFeedbackTable(offerData.offerId)
      .subscribe(
        res => {
          let data;
          console.log('getFeedbackData >>>', res);
          // this.feedbackMaster = res.body[offerData.index].feedbackData;
          data = res.body.filter(element => {
            // console.log("code>>>>>>", element.feedbackData[0].itemCode, offerData.code)
            if ((element.feedbackData.length > 0) && (element.groupName === offerData.code)){
              console.log(element);
              return element;
            }
          });
          if(data){
            data.forEach(async element => {
              console.log(element.feedbackData)
              this.feedbackMaster = element.feedbackData;
              this.selected = await element.eafeedbackData.interventionNeed ? element.eafeedbackData.interventionNeed : '';
              this.selectedItems = (element.eafeedbackData.riskArea && element.eafeedbackData.riskArea.length > 0) ? element.eafeedbackData.riskArea : '';
              this.riskAssesmentValue = element.eafeedbackData.riskAssesmentValue ? element.eafeedbackData.riskAssesmentValue : '';
              this.interventionSummary = element.eafeedbackData.interventionSummary ? element.eafeedbackData.interventionSummary : '';
              this.interventionName = element.eafeedbackData.interventionName ? element.eafeedbackData.interventionName : '';
              this.interventionDesc = element.eafeedbackData.interventionDesc ? element.eafeedbackData.interventionDesc : '';
              this.interactionType = element.eafeedbackData.interactionType ? element.eafeedbackData.interactionType : '';
              this.emailSelected = element.eafeedbackData.emailSelected ? element.eafeedbackData.emailSelected : '';
              this.activityType_model = element.eafeedbackData.activityType_model ? element.eafeedbackData.activityType_model : '';
              this.calenderPicked = element.eafeedbackData.calenderPicked ? element.eafeedbackData.calenderPicked : '';
              this.todayDate = element.eafeedbackData.intervationCreatedDate ? element.eafeedbackData.intervationCreatedDate : '';
              await this.radioChange(this.selected);
            });
          }
          console.log(data);
          // this.feedbackMaster = data.feedbackData
          console.log('feedbackMaster >>>', this.feedbackMaster);
        }, error => {
          this.toaster.error("Something went wrong while getting data")
        })
  }


  getUser(projection) {
    this.loader.start();
    this.userService.getUsers(projection).subscribe(res => {
      this.allUsers = res.body;
      console.log('allusers >>>', this.allUsers)
      // this.checkApprovalStatus();
    });
    this.loader.stop();
  }


  radioChange(event) {
    console.log('selected >>>', this.selected);

    if (this.selected) {
      this.isInterventionVisible = true;
    } else {
      this.isInterventionVisible = false;
    }
  }

  clearIT() {
    // if(this.activityType_model != 'CHACT') {
    //   this.interactionType = '';
    // }
    this.interventionName = '';
  }

  // close modal
  dismissModal() {
    this.activeModal.close();
  }


  // submit Engagement Assosiate form
  submitEAForm() {
    console.log(this.riskAreaValue, this.riskAssesmentValue, this.interventionSummary, this.selected)
  }

  nextStep() {
    this.selectedIndex += 1;
  }

  getTemplateData(callback?) {
    console.log(this.templateId)
    const payload = { id: this.templateId }
    this.templateService.getTemplate(payload).subscribe((res) => {
      console.log('res >>>',res.body);

      // console.log('stagesAndActivites >>>',res.body.stagesAndActivites);

      this.templateData = res.body.template;
      this.stages = JSON.parse(JSON.stringify(res.body.template.stages))
      this.combineData(res.body);
      this.getEngamentMasterData();
      if (callback) {
        callback();
      }
    }, error => {
      this.toaster.error("Something went wrong while getting the template")
    })
  }

  // combine template data on arrival
  combineData(data) {
    if (this.templateData.stages && this.templateData.stages.length > 0) {
      this.templateData.stages.forEach((stage => {
        data.stagesAndActivites.forEach((stAc) => {
          if (stage.id === stAc._id) {
            stage._data = stAc;
          }
          stage.activities.forEach((activity) => {
            if (activity.id === stAc._id) {
              activity._data = stAc;
            }
          });
        });
      }));
    }
  }

  getEngamentMasterData() {
    this.engamentMasterData = this.sharedService.engagementTemplateToMasterMapper(JSON.parse(JSON.stringify(this.templateData.stages)));
    console.log('engamentMasterData >>',this.templateData)
    //adding extra fields to master data from exusting template
    if (this.actionFromOpeningModal === 'edit') {
      this.engamentMasterData = this.sharedService.remapperForUpdatedDataEngagement(this.engamentMasterData, this.engagementData);
      this.templateData.stages = this.sharedService.mapMasterToStages(JSON.parse(JSON.stringify(this.templateData.stages)), this.engagementData);
    }
    console.log('data >>', this.engamentMasterData, this.templateData)
    this.createModel();
  }

  createModel() {
    console.log(this.engamentMasterData);
    this.engamentMasterData.forEach(eachGroup => {
      if (!this.engagementModel[eachGroup.code]) {
        this.engagementModel[eachGroup.code] = {};
      }
      eachGroup.data.items.forEach(eachItem => {
        // console.log(eachItem)
        // when creating the engagement for the first time the rules will apply
        let ruleData = {} as any;
        if (this.actionFromOpeningModal != "edit") {
          if (eachItem.rules){
            ruleData = this.sharedService.ruleGeneratorForEngagement(eachItem.rules[0], this.offerData);
          }
        }
        // if no object exists then only create new otherwise leave the old one
        // console.log('eachItem >>',eachItem);

        if (!this.engagementModel[eachGroup.code][eachItem.code]) {
          var itemObj = {
            applicable: false,
            assignedTo: ruleData.user ? ruleData.user : "",
            completed: false,
            other: false,
            otherEmail: "",
            code: eachItem.code,
            taskName: eachItem.title,
            activityType: eachItem.activityType,
            interactionType: eachItem.interactionType,
            description : eachItem.description,

            date: ruleData.date ? ruleData.date : null,
            time: ruleData.time ? ruleData.time : ""

          };
          // console.log('itemObj >>',itemObj);
          //for create, set applicable to true
          if (this.actionFromOpeningModal != 'edit') {
            itemObj.applicable = true;
          }
          this.engagementModel[eachGroup.code][eachItem.code] = itemObj;
        }

      });
    });

  }

  gettingAlreadyExistingEngagementData() {
    console.log(this.offerData);
    this.candidateFullName = this.offerData.candidateData.firstName+' '+this.offerData.candidateData.lastName;
    this.recuiterEmailId = this.offerData.organizationData.recruiter;
    this.engagementService.getEngagementData(this.offerData.offerId).subscribe(
      res => {
        console.log(res);
        this.templateId = res.body.templateId; //added later
        //   code for new template based data merge
        console.log(this.userService, res)
        this.userService.getUser(() => {
          this.engagementData = res.body.engagementData;
          this.getTemplateData(() => {
            this.getStagesMaster(() => {
              this.updateEngagementModel();
            })
          })
        })
      },
      error => {
        this.toaster.error("Something went wrong");
      }
    );
  }

  //get stages master
  private getStagesMaster(callback?) {
    const payload = { type: 'stage' };
    this.stageService.getStages(payload).subscribe((res) => {
      console.log(res)
      this.stageMaster = res.body;
      if (callback) { callback() }
    }, err => {
      this.toaster.error("Error getting stages")
    })
  }

  updateEngagementModel() {
    console.log(this.engagementData, this.engagementModel);
    this.engagementData.forEach(eachGroup => {
      eachGroup.items.forEach(eachItem => {
        eachItem.date = moment(eachItem.date).toISOString()
        eachItem.date = this.sharedService.convertDateStringToObject(eachItem.date);
        if (this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem) {
          this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem;
        }
        else {
          this.engagementModel[eachGroup.groupName] = {};
          this.engagementModel[eachGroup.groupName][eachItem.code] = this.createSingleModelForExtraData();
          this.engagementModel[eachGroup.groupName][eachItem.code] = eachItem;
        }
      });
    });
    let stage = null;
    let stageCode = 's_1_7JDltl';
    console.log(this.templateData)
    this.templateData.stages.forEach((_stage) => {
      console.log(_stage)
      if (_stage.code === stageCode) {
        stage = _stage;
      }
      stage.activities.forEach(e => {
        e.selected = true
      });
    })
    console.log(stage)
    this.activityData = stage.activities;

    this.checkDataBeforeDestroy()
  }

  // extra data creaatino onn engagement template
  createSingleModelForExtraData() {
    var itemObj = {
      applicable: false,
      assignedTo: "",
      completed: false,
      other: false,
      otherEmail: "",
      code: "",
      taskName: "",
      date: null,
      time: ""
    };
    return itemObj;
  }

  private initialize() {
    let dataToIterate = this.type === "stage" ? JSON.parse(JSON.stringify(this.engagementData)) : JSON.parse(JSON.stringify(this.activityData))
    let dataToCompareTo = this.type === "stage" ? this.engagementStageList : this.engagementActivityList;
    //if stages already exist
    if (dataToIterate.length > 0) {
      dataToIterate.forEach((stage) => {
        dataToCompareTo.forEach((master) => {
          if (stage.id === master._id || stage.code === master.code) {
            master.selected = true;
          }
        })
      })
    }
  }

  public checkDataBeforeDestroy() {
    let newStage: stageInterface = { id: "", code: "", activities: [], _data: {} };
    let newActivity: activityInterface = { id: "", code: "", _data: {}, rules: [{ action: "", time: "11:00", days: 1, condition: "", role: "" }], ruleAssociation: "" }
    let dataToCompareTo = this.type === "stage" ? JSON.parse(JSON.stringify(this.engagementData)) : JSON.parse(JSON.stringify(this.activityData))
    let dataToIterate = this.type === "stage" ? this.engagementStageList : this.engagementActivityList;

    console.log(dataToIterate, dataToCompareTo)
    console.log(this.activityData)
    dataToIterate.forEach((master) => {
      console.log(master)
      const _newStage: stageInterface = JSON.parse(JSON.stringify(newStage));
      const _newActivity: activityInterface = JSON.parse(JSON.stringify(newActivity));
      console.log(_newActivity);
      _newStage.id = master._id;
      _newStage.code = master.code;
      _newStage._data = { name: master.name, description: master.description, digital: master.digital, code: master.code, activityType: master.activityType ,interactionType: master.interactionType};
      _newActivity.id = master._id;
      _newActivity.code = master.code;
      _newActivity._data = { name: master.name, description: master.description, digital: master.digital, code: master.code, activityType: master.activityType,interactionType: master.interactionType};
      if (master.selected) {
        let present = false;
        if (dataToCompareTo.length > 0) {
          console.log(dataToCompareTo)
          dataToCompareTo.forEach((stageOrActivity) => {
            if (master._id === stageOrActivity.id || master.code === stageOrActivity.code) {
              present = true;
            }
          })
          if (!present) {
            this.type === "stage" ? dataToCompareTo.push(_newStage) : dataToCompareTo.push(_newActivity);
          }
        } else {
          this.type === "stage" ? dataToCompareTo.push(_newStage) : dataToCompareTo.push(_newActivity);
        }
      } else {
        if (dataToCompareTo.length > 0) {
          dataToCompareTo.forEach((stageOrActivity) => {
            if (master._id === stageOrActivity.id || master.code === stageOrActivity.code) {
              stageOrActivity.deleted = true;
            }
          })
        }
      }
    });
    // filtering data out
    dataToCompareTo = dataToCompareTo.filter(data => {
      return !data.deleted;
    });
    console.log(this.engagementData);
    console.log(dataToCompareTo)
  }


  // -------
  // code for the intervation and the direct function
  // --------

  private getActvitiesAndStages(type) {
    const payload = { type }
    console.log(payload)
    this.stageService.getStages(payload).subscribe((res) => {
      console.log(res)
      type === "stage" ? this.engagementStageList = res.body : this.engagementActivityList = res.body;
      this.engagementStageList = this.engagementStageList.map(el => {
        el.selected = true;
        return el;
      })
      this.initialize();
      this.submitEngagementFeedbackWithintervation()
    }, error => {
      this.toaster.error("Error getting engagement stages");
    })
  }

  submitEngagementFeedbackWithintervation() {
    let values;
    let date = this.calenderPicked.split('-')
    console.log(this.engagementActivityList);
    this.engagementActivityList.forEach(e => {
      if(this.interventionName == e.name){
        console.log(e)
        values = {
          applicable: true,
          activityType: this.activityType_model,
          interactionType: this.interactionType,
          assignedTo: this.emailSelected,
          completed: false,
          other: false,
          otherEmail:"",
          code: e.code,
          taskName: this.interventionName,
          date: {year: date[0], month: date[1], day: date[2]},
          groupName: this.feedbackModalData.data,
          time: "11:00",
        }
      }
    })
    console.log(values, this.calenderPicked, date, this.activityType_model);

    this.engagementData.forEach(e => {
      if(e.groupName == this.feedbackModalData.data){
        e.items.push(values);
      }
    })
    console.log(this.engagementData, this.offerData)
    this.submit();
  }

  submit() {
    // validate everything
    const tempStartEngagementData = JSON.parse(JSON.stringify(this.engagementData));
    console.log('tempStartEngagementData >>>',tempStartEngagementData)
    tempStartEngagementData.forEach((eachItem: any) => {
      eachItem.items.forEach((eachElement) => {
        var dateFormatConversion = this.sharedService.convertObjectToDateString(eachElement.date);
        dateFormatConversion = moment(dateFormatConversion).format("YYYY-MM-DD")
        eachElement.date = dateFormatConversion
      });
    })
    console.log('tempStartEngagementData >>>',tempStartEngagementData)
    // const name = this.offerData.candidateData.firstName + " " + this.offerData.candidateData.lastName;

    // const startEngagement = {
    //   offerId: this.offerData.offerId,
    //   templateId: this.templateId,//added later
    //   candidateName: name,
    //   updatedAt: new Date().getTime(),
    //   imageLink: this.offerData.candidateData.profileImage,
    //   engagementData: tempStartEngagementData
    // };

    // console.log(startEngagement)

    const updateEngagment = {
      engagementData: tempStartEngagementData
    };
    console.log(updateEngagment)
    this.loader.start();
    this.engagementService
      .updateEngMeta(this.offerData.offerId, name, updateEngagment)
      .subscribe(
        res => {
          this.loader.stop();
          // this.change.emit(res);
          this.activeModal.close("Cross click");
          this.toaster.success("Engagement task updated sucessfully");
          this.handleRating();
        },
        error => {
          this.loader.stop();
          this.toaster.error("something went wrong.");
        }
      );
  }

  handleRating() {
    const updateEngagment = {
      changeInRating: "0.0",
      ratingData: this.riskAssesmentValue
    }
    this.engagementService
      .updateEngMeta(this.offerData.offerId, name, updateEngagment)
      .subscribe(
        res => {
          this.submitdata();
          console.log("went good", updateEngagment);
        },
        error => {
          console.log("Something went wrong!");
        }
      );
  }

}
