
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master/master.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateMasterDataComponent } from './update-master-data/update-master-data.component';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit {
  engagementForm: FormGroup;
  items: FormArray;
  masterData: any;

  constructor(
    private toaster: ToastrService,
    private masterService: MasterService,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal
  ) {
    // this.getMasterDataOfEngagement();
  }

  ngOnInit() {
    this.getMasterDataOfEngagement();
  }

  // getMasterDataOfEngagement
  getMasterDataOfEngagement() {
    this.loader.start();
    this.masterData = [];
    this.masterService.getMasterAccToType('engagement').subscribe(
      res => {
        this.loader.stop();
        this.masterData = res.body[0].data;
      },
      error => {
        this.loader.stop();
        this.toaster.error('Somthing went wrong');
      }
    );
  }

  openupdateMasterDataModal(data, mode) {
    const modalRef = this.modalService.open(UpdateMasterDataComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.itemMasterData = data;
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.editEngagementMasterResponse.subscribe(res => {
      console.log(res)
      if (res.msg === 'success') {
        this.getAllMasterDataInit(()=>{
          this.getMasterDataOfEngagement();
        })       
      }
    });
  }
  
  getAllMasterDataInit(callback) {
    this.masterService.getAllMaster().subscribe(
      res => {
        localStorage["_master"] = JSON.stringify(res); //storing all response in localstorage
        callback();
      },
      error => {
        console.log("");
      }
    );
  }
}
