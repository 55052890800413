import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { SharedService } from "src/app/services/shared/shared.service";
import { Subscription } from "rxjs";
import { OfferService } from "src/app/services/offer/offer.service";
@Component({
  selector: "app-candidate-detail",
  templateUrl: "./candidate-detail.component.html",
  styleUrls: ["./candidate-detail.component.scss"]
})
export class CandidateDetailComponent implements OnInit, OnChanges {
  @Input() public candidateData;
  @Input() public ratingBlock;
  @Input() public personRating;
  @Input() public ratingData;
  @Input() public totalActivity;
  @Input() public engagmentfinalDate;
  engagementRating: any;
  offerData: any;
  profileImage: any;
  ratingMeta: any;
  engagmentDays: any;
  offerStatusVal: any;
  constructor(
    public sharedService: SharedService,
    private offerService: OfferService
  ) {
    this.engagementRating = '';
  }

  ngOnInit() {
    this.engagementRating = this.candidateData.ratingData;
    // console.log("candidateName>>", this.candidateData, "2>>", this.ratingBlock, "3>>", this.personRating, "4>>", this.ratingData, "5>>", this.totalActivity, "6>>", this.engagmentfinalDate)
  }
  ngOnChanges(changes: any): void {
    // console.log(changes);
    console.log(this.candidateData.offerStatus);
    this.offerStatusVal = this.candidateData.offerStatus;
    if (
      changes &&
      changes.candidateData &&
      changes.candidateData.currentValue
    ) {
      this.candidateData = changes.candidateData.currentValue;
      // console.log(this.candidateData)
      this.getOfferDetailsByOferID(this.candidateData);
    }
    if (changes && changes.personRating && changes.personRating.currentValue) {
      this.engagementRating = changes.personRating.currentValue;
    }
    if (changes && changes.ratingData && changes.ratingData.currentValue) {
      this.ratingMeta = changes.ratingData.currentValue;
    }
  }


  // GET ENGAGEMENT DATA FOR OFFER
  getOfferDetailsByOferID(data) {
    const payload = {
      offerId: data.offerId
    };
    this.offerService.GetOfferDeatilsByOferID(payload).subscribe(res => {
      if (res.body) {
        this.offerData = res.body;
        this.profileImage = this.offerData.candidateData.profileImage;
        this.engagmentDays = Math.ceil( Math.abs((new Date(this.offerData.createdAt).getTime()-new Date((this.offerData.offerMetaData.expiryDate) ? this.offerData.offerMetaData.expiryDate : this.offerData.offerMetaData.joiningDate).getTime())/ (1000*60*60*24)))
      }
    });
  }

  checkOfferStatusVal(x){
    switch (x) {
      case 'JND':
        return 'Joined';
        break;
      case 'EEX':
        return 'Drop Out';
        break;
      case 'WDN':
        return 'Not Engaged';
        break;
      default:
        return;
        break;
    }
  }
}
