import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class MasterService {
  apiName: string;
  path: string;
  orgHandle: string;

  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.master;
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // creating master
  public createMaster(body) {
    const request = this.initRequests.initCreateMaster({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // updating master
  public udpateMaster(body) {
    const request = this.initRequests.initUpdateMaster({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // get master acc to  type

  public getMasterAccToType(type): Observable<any> {
    let body = new Observable<any>();
    let allMasterData = JSON.parse(localStorage["_master"]); //all master data
    let nestedMaster = {
      body: []
    };
    nestedMaster.body = allMasterData.body.filter(element => {
      return element.type == type;
    });

    body = of(nestedMaster);
    return body;
  }

  //get all Master
  public getAllMaster() {
    const request = this.initRequests.initMaster({
      orgHandle: this.orgHandle,
      _action: "GET_ALL"
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
}
