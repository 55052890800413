import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit {
  candidateDetails: any;

  constructor(
    private loader: NgxUiLoaderService,
    public sharedService: SharedService,
    private toaster: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(res => {
      this.getUserDetails(res.id);
    });
  }

  getUserDetails(id) {
    this.loader.start();
    this.sharedService.getCandidateDetails(id).subscribe(
      res => {
        this.candidateDetails = res.body;
        console.log('candidateDetails >>',this.candidateDetails);
        this.loader.stop();
      },
      error => {
        this.loader.stop();
        console.log(error);
        this.toaster.error(
          'something went wrong while getting the candidate details'
        );
      }
    );
  }
}
