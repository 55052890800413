import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OfferService } from "./../../services/offer/offer.service";
import { EngagementService } from "src/app/services/engagement/engagement.service";
import { Location } from '@angular/common';
import { ExcelService } from './../../services/excelExport/excel-services.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  currentUser: any;
  authRole: string;
  filterObj = {
    candidateName: "",
    date: "",
    offerId: "",
    businessUnit: "",
    division: "",
    department: "",
    noticePeriod: "",
    joiningDate: "",
    expiryDate: "",
    offerStatus: ""
  };
  sortObj = {};
  dashboardData: any;
  engagmentData: any;
  preProcessOffer: any[] = [];
  orgHandle: any;
  routeName: any;

  constructor(
    private loader: NgxUiLoaderService,
    private offerService: OfferService,
    private engagementService: EngagementService,
    private location: Location,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    public userService: UserService,
  ) {}

  async ngOnInit() {
    await this.getDashboardData();
    this.orgHandle = localStorage.getItem("orgHandle");
    // await this.getEngagmemtData();
    // await this.checkTotalEngagment();
    this.route.params.subscribe(params => {
      this.routeName = params.roterName;
      console.log(this.routeName);
    })
  }

  getDashboardData() {
    this.loader.start();
    let offerPayload = {
      limit: "",
      emailId: this.currentUser,
      role: this.authRole,
      filter: this.filterObj,
      sort: this.sortObj
    }
    this.offerService.getAllDashboard(offerPayload).subscribe(
      res => {
        // console.log(res);
        this.dashboardData = res.body
        this.loader.stop();
        this.getEngagmemtData();
        // console.log(this.dashboardData);
      },
      err => {
        this.loader.stop();
      }
    );
  }

  getEngagmemtData() {
    this.loader.start();
    this.engagementService
      .getEngagementFeedbackTable("")
      .subscribe(
        res => {
          this.engagmentData = res.body;
          this.loader.stop();
          this.checkTotalEngagment();
        },
        error => {
          this.loader.stop();
          // this.toaster.error('Somthing went wrong');
        }
      );
  }

  checkEngagementFeedbackData() {
    this.preProcessOffer.forEach((item, index) => {
      if('nextActivityDate' in item){
        this.engagmentData.forEach(e => {
          // console.log(e);
          if((item.offer_id == e.offerId) && (e.feedbackType == 'EAFEED')){
            let riskArea = '';
            if(e.eafeedbackData.riskArea.length > 0){
              e.eafeedbackData.riskArea.forEach((element, index) => {
                (e.eafeedbackData.riskArea.length - 1 === index) ? riskArea += element.item_text : riskArea += element.item_text+', ';
              })
            }
            this.preProcessOffer[index]['engagementSummary'] = e.eafeedbackData.interventionSummary;
            this.preProcessOffer[index]['interventionName'] = e.eafeedbackData.interventionName;
            this.preProcessOffer[index]['riskArea'] = riskArea;
            // console.log(riskArea);
          } else if((item.offer_id == e.offerId) && (e.feedbackType == 'CLIFEED')){
            this.preProcessOffer[index]['clientEngagementSummary'] = e.feedbackData[0].clientAction;
          }
        })
      }
    })
  }

  async checkTotalEngagment() {
    // console.log(this.dashboardData);
    // console.log(this.engagmentData);
    let todayDate = new Date().getTime();
    let actualDate =  todayDate - (todayDate % 86400000);
    this.preProcessOffer = [];
    let tempDate;
    await this.dashboardData.forEach(async (item, index) => {
      // console.log(item, index);
      let joiningDate = new Date(item.offerMetaData.joiningDate).getTime();
      let revisedDate = new Date(item.offerMetaData.expiryDate).getTime();
      const Offer = {
        offer_id: item.offerId,
        candidateName: item.candidateData.firstName+' '+item.candidateData.lastName,
        recruiter: this.userService.getUserName(item.organizationData.recruiter),
        initialDOJ: (item.offerMetaData.joiningDate) ? new Date(item.offerMetaData.joiningDate).toLocaleDateString("en-IN") : '-',
        RevisedDOJ: (item.offerMetaData.expiryDate) ? new Date(item.offerMetaData.expiryDate).toLocaleDateString("en-IN") : '-',
        engagmentPeriod: Math.ceil( Math.abs((new Date(item.createdAt).getTime()-new Date((item.offerMetaData.expiryDate) ? item.offerMetaData.expiryDate : item.offerMetaData.joiningDate).getTime())/ (1000*60*60*24)))+' days',
        remainingJoiningDate: ((revisedDate) ? ( (revisedDate > todayDate) ? Math.round((revisedDate-todayDate)/ (1000 * 3600 * 24))+' days' : '-' ): (joiningDate > todayDate) ? Math.round((joiningDate-todayDate)/ (1000 * 3600 * 24))+' days' : '-'),
      }
      if('Engagement_data' in item){
        Offer['ratingData'] = item.Engagement_data.ratingData;
        Offer['activityCompleted'] = 0;
        Offer['TotalActivities'] = 0;
        await item.Engagement_data.engagementData.forEach((e) => {
          e.items.forEach(element => {
            Offer['TotalActivities']++;
            // (element.completed) && Offer['activityCompleted']++;
            // if((!element.completed) && (new Date(element.date).getTime() < new Date('2222-06-07').getTime()))
            console.log(element.completed);
            if(!element.completed){
              // console.log((tempDate));
              if((tempDate) && (new Date(element.date).getTime() < new Date(tempDate).getTime()))
              {
                tempDate = new Date(element.date).getTime();
                Offer['nextActivityDate'] = new Date(element.date).toLocaleDateString("en-IN");
                Offer['activityName'] = element.taskName;
              } else {
                tempDate = new Date(element.date).getTime();
                Offer['nextActivityDate'] = new Date(element.date).toLocaleDateString("en-IN");
                Offer['activityName'] = element.taskName;
              }
            } else {
              Offer['activityCompleted']++;
            }
          })
        })
        // Offer['engagementSummary'] = await this.checkEngagementFeedbackData(item.offerId, this.engagmentData);
        // this.engagmentData.forEach(element => {
        //   // if(item.offerId == element.offerId){
        //   //   // console.log(element);
        //   //   Offer['engagementSummary'] = element.eafeedbackData.interventionSummary;
        //   // }
        // });
      }
      this.preProcessOffer.push(Offer);
      // console.log(this.preProcessOffer);
    })

    await this.checkEngagementFeedbackData();


    // this.dashboardData.forEach((item, index) => {
    //   let joiningDate = new Date(item.offerMetaData.joiningDate).getTime();
    //   this.dashboardData[index]['engagmentPeriod'] = Math.ceil( Math.abs((new Date(item.createdAt).getTime()-new Date((item.offerMetaData.expiryDate) ? item.offerMetaData.expiryDate : item.offerMetaData.joiningDate).getTime())/ (1000*60*60*24)));
    //   if(joiningDate > todayDate) {
    //     this.dashboardData[index]['remainingJoiningDate'] = Math.round((joiningDate-todayDate)/ (1000 * 3600 * 24))+' days';
    //     // console.log((joiningDate-todayDate)/ (1000 * 3600 * 24));
    //   }
    //   this.dashboardData[index]['fullName'] = item.candidateData.firstName+' '+item.candidateData.lastName;
    //   this.dashboardData[index]['joiningDate'] = item.offerMetaData.joiningDate;
    //   this.dashboardData[index]['expiryDate'] = item.offerMetaData.expiryDate;
    //   console.log(item);
    //   // this.dashboardData[index]['activityCompleted'] = 0;
    //   if('Engagement_data' in item) {
    //     this.dashboardData[index]['ratingData'] = item.Engagement_data.ratingData;
    //     this.dashboardData[index]['activityCompleted'] = 0;
    //     console.log(item.Engagement_data);
    //     item.Engagement_data.engagementData.forEach((item) => {
    //       item.items.forEach(element => {
    //         console.log(element);
    //         (element.completed) && this.dashboardData[index]['activityCompleted']++;
    //         // console.log(actualDate, new Date(element.date).getTime(), new Date('2022-06-07').getTime());
    //         if((actualDate <= new Date(element.date).getTime()) && (new Date(element.date).getTime() < new Date('2222-06-07').getTime())){
    //           this.dashboardData[index]['NextActivityDate'] = element.date;
    //           this.dashboardData[index]['TypeOfActivity'] = element.taskName;
    //         }
    //       });
    //     })
    //     this.engagmentData.forEach(element => {
    //       if(item.offerId == element.offerId){
    //         console.log(element);
    //         this.dashboardData[index]['EngagementFeedBack'] = element.eafeedbackData.interventionSummary;
    //       }
    //     });
    //   }
    // });
    // console.log(this.dashboardData);
  }

  // Checking and appending the engagement data to the main dashboard data
  // insertEngagmentData(x) {
  //     // if(item.offerId == element.offerId)
  //   this.engagmentData.forEach(element => {
  //     if(x.offerId == element.offerId){
  //       console.log(element);
  //       return(element);
  //     }
  //   });
  //   console.log(this.dashboardData);
  //   // console.log(x);
  // }

  navigateBack() {
    this.location.back();
  }

  exportToXLSX() {
    this.excelService.exportAsExcelFile(this.preProcessOffer, this.orgHandle);
  }

}
