import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-create-stage-modal",
  templateUrl: "./create-stage-modal.component.html",
  styleUrls: ["./create-stage-modal.component.scss"]
})
export class CreateStageModalComponent implements OnInit {
  name: any;
  description: any;
  id: any;
  @Input("data") data: any;

  constructor(public activeModal: NgbActiveModal, private toaster: ToastrService) { this.name = "", this.description = "", this.id = "" }

  ngOnInit() {
    if (this.data) {
      console.log(this.data)
      this.id = this.data.id;
      this.name = this.data.name;
      this.description = this.data.description
    }
  }

  dismissModal(data?) {
    this.activeModal.close(data);
  }

  saveData() {
    if (this.name) {
      this.dismissModal({ update: this.data.id ? true : false, data: { id: this.id, name: this.name, description: this.description } });
    }
    else {
      this.toaster.error("Please fill in the name");
    }
  }


}
