import { AuthService } from "../../services/auth/auth.service";
import { OrgEntityModalComponent } from "./org-entity-modal/org-entity-modal.component";
import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedService } from "src/app/services/shared/shared.service";
import { MasterTypes } from "src/app-settings/master_types";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { JsonEditorComponent, JsonEditorOptions } from "ang-jsoneditor";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MasterService } from "../../services/master/master.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-organizational-entities",
  templateUrl: "./organizational-entities.component.html",
  styleUrls: ["./organizational-entities.component.scss"]
})
export class OrganizationalEntitiesComponent implements OnInit {
  orgEntitiesData: any;
  public modify: boolean;
  public flexed = false;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;
  showActionItems: boolean;
  disablePage: boolean;
  legalEntity: any;
  drop(ev) {
    ev.preventDefault();
    const data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  constructor(
    private shared: SharedService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private masterService: MasterService,
    private auth: AuthService,
    private router: Router
  ) {
    this.modify = false;
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ["code", "text", "tree", "view"]; // set all allowed modes
    this.disablePage = false;
    this.legalEntity = "";
  }

  ngOnInit() {
    this.showActionItems = false;
    this.getOrgEntities();
    this.handleRole();
    setTimeout(() => {
      let elm = document.getElementsByClassName("root-branch")[0].clientWidth;
      console.log(elm);
      if (elm > 1000) {
        this.flexed = true;
      }
    }, 1000);
  }

  handleRole() {
    this.disablePage = this.auth.roleViewMapping(
      this.auth.getAuthRole(),
      this.router.url.substring(1)
    ); //skipping first /
  }

  public getOrgEntities() {
    this.orgEntitiesData = [];
    this.loader.start();
    this.shared.getMasterAccToType(MasterTypes.orgEntities).subscribe(
      res => {
        console.log("result org entitty", res);
        let legal = res.body[0].data.filter(el => {
          return el.code === "LEGALENTITY";
        });
        this.legalEntity = legal[0].data.items[0].title;
        (this.orgEntitiesData = this.shared.sortMasterDataForEntities(
          res.body[0].data
        )),
          this.loader.stop();
        console.log(this.orgEntitiesData);
      },
      error => {
        this.loader.stop();
        this.toaster.error("something went wrong.");
        console.log(error);
      }
    );
  }

  public modifyEntities() {
    this.modify = !this.modify;
  }

  // show action items

  showActions() {
    this.showActionItems = !this.showActionItems;
  }

  openModalForEnteringData(data, code?, codeTitle?, divCode?, buCode?) {
    console.log(buCode);
    this.showActionItems = false;
    const modalRef = this.modalService.open(OrgEntityModalComponent, {
      size: "lg",
      backdrop: "static",
      centered: true
    });
    modalRef.componentInstance.type = data;
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.divCode = divCode;
    modalRef.componentInstance.buCode = buCode;
    modalRef.componentInstance.codeTitle = codeTitle;
    modalRef.componentInstance.masterData = this.orgEntitiesData;

    modalRef.result.then(res => {
      if (res === "save") {
        this.getAllMasterDataInit(() => {
          this.getOrgEntities();
        });
      }
    });
  }
  //this is a bug fix
  getAllMasterDataInit(callback) {
    this.masterService.getAllMaster().subscribe(
      res => {
        localStorage["_master"] = JSON.stringify(res); //storing all response in localstorage
        callback();
      },
      error => {
        console.log("");
      }
    );
  }
}
