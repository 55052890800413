import { Injectable } from "@angular/core";
import { from, BehaviorSubject, Observable, of } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import * as moment from "moment";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderService,
  POSITION,
  SPINNER,
  PB_DIRECTION
} from "ngx-ui-loader";
import { MasterTypes, activityCodes, orgRoles } from "src/app-settings/master_types";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "../user/user.service";
import { StageService } from "../../services/stage/stage.service"
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: "root"
})
export class SharedService {
  orgHandle: any;
  apiName: string;
  path: string;
  masterPath: string;
  mailTemplatePath: any;
  config: NgxUiLoaderConfig;
  mailTemplateData = new BehaviorSubject({
    mailTemplateData: null
  });
  offerFormData = new BehaviorSubject({
    offerData: null
  });
  customConfig: NgxUiLoaderConfig = {
    bgsColor: "#0277BD",
    fgsColor: "#0277BD",
    bgsPosition: POSITION.bottomCenter,
    bgsSize: 40,
    bgsType: SPINNER.fadingCircle,
    fgsType: SPINNER.fadingCircle,
    pbDirection: PB_DIRECTION.leftToRight, //  progress bar direction
    pbThickness: 5, //  progress bar thickness,
    hasProgressBar: false,
    overlayColor: "rgba(255,255,255,0.8)"
  };
  engagementTaskmasterData: any[] = [];
  orgEntities: any[] = [];
  offerActivity: any[] = [];
  orgRole: any[] = [];
  variableNameValue = new BehaviorSubject({
    data: null
  });
  offerStatus: any[] = [];
  candidateApiName: string;
  candidatePath: string;
  resumeSources: any[] = [];
  dateOfBirth: any;
  joiningDateData: any;
  expiryDateData: any;
  creationDate: any;
  setMsgFlag: any;
  constructor(
    private initRequests: InitRequests,
    private ngxUiLoaderService: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    private auth: AuthService,
    private stageService: StageService
  ) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.master;
    this.candidateApiName = apis.hireloopService.hireloop.name;
    this.candidatePath = apis.hireloopService.hireloop.endpoints.candidate;
    this.mailTemplatePath =
      apis.organizationService.organization.endpoints.mail;
    this.orgHandle = localStorage.getItem("orgHandle");
    this.userService.getUser();
  }

  // file getter service from local
  onFileChanged(e) {
    const imageDatalength: any = e.target.files;
    return new Promise((resolve, reject) => {
      for (var i = 0; i < imageDatalength.length; i++) {
        let imageName: any = imageDatalength[i].name;
        const reader = new FileReader();
        reader.readAsDataURL(imageDatalength[i]);
        reader.onload = (element: any) => {
          let data = {
            fileData: element.target.result,
            fileName: imageName
          };
          resolve(data);
        };
      }
    });
  }

  // getMaster accroding to the type
  getMasterAccToType(type): Observable<any> {
    let body = new Observable<any>();
    let allMasterData = JSON.parse(localStorage["_master"]); //all master data
    let nestedMaster = {
      body: []
    };
    nestedMaster.body = allMasterData.body.filter(element => {
      return element.type == type;
    });

    body = of(nestedMaster);
    return body;
  }

  // getMaster accroding to the type
  getMailMaster(type, code) {
    const request = this.initRequests.initMaster({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        masterType: type,
        code: code
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  getEngamentMasterData() {
    this.getMasterAccToType(MasterTypes.engagement).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.engagementTaskmasterData.push(element);
        });
      });
    });
  }
  // get leble based on type of engagement code return the value of title
  getLabelFromMasterData(masterDataPath, key, labelKey, code) {
    let label = "";
    masterDataPath.forEach(element => {
      if (element[key] === code) {
        label = element[labelKey];
      }
    });
    return label;
  }

  // get  the engagement titile
  getEngagementTitle(code) {
    return this.getLabelFromMasterData(
      this.engagementTaskmasterData,
      "code",
      "title",
      code
    );
  }

  // sort masters

  sortMasterDataForEntities(data) {
    const businessUnit = [];
    if (data) {
      if (data.length > 0) {
        data.forEach(el => {
          if (el.code === "BUSINESSUNIT") {
            el.data.items.forEach(element => {
              businessUnit.push(element);
            });
          }
        });
        data.forEach(el => {
          if (el.code === "DIVISION") {
            businessUnit.forEach(element => {
              if (!element.items) {
                element.items = [];
              }
              if (el.data.parentCode === element.code) {
                el.data.items.forEach(ele => {
                  element.items.push(ele);
                });
              }
            });
          }
        });
        data.forEach(el => {
          if (el.code === "DEPARTMENT") {
            businessUnit.forEach(element => {
              if (element.items.length > 0) {
                element.items.forEach(ele => {
                  if (!ele.items) {
                    ele.items = [];
                  }
                  if (el.data.parentCode === ele.code) {
                    el.data.items.forEach(elem => {
                      ele.items.push(elem);
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    return businessUnit;
  }

  getMailTemplates(code) {
    const request = this.initRequests.initGetTemplates({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        templateCode: code
      }
    });
    return from(this.auth.post(this.apiName, this.mailTemplatePath, request));
  }

  setMailTemplateData(mailTemplateData) {
    this.mailTemplateData.next(mailTemplateData);
  }
  getMailTemplateData() {
    return this.mailTemplateData.asObservable();
  }
  // set offer Data
  setOfferFormData(data) {
    if (typeof data.candidateData === "object") {
      const formData: any = {
        firstName: data.candidateData.firstName,
        lastName: data.candidateData.lastName,
        email: data.candidateData.emailId,
        dob: moment(data.candidateData.dob).format("DD-MM-YYYY"),
        contactNumber: data.candidateData.mobile,
        nameAsInPassport: data.candidateData.nameAsInPassport,
        resumeSource: this.getResumeSourceTitle(
          data.candidateData.resumeSource
        ),
        skills: data.candidateData.skills,
        title: data.offerMetaData.title,
        offerDesignation: data.offerMetaData.designation,
        businessUnit: this.getOrgEntitiesTitle(
          data.organizationData.businessUnit
        ),
        division: this.getOrgEntitiesTitle(data.organizationData.division),
        department: this.getOrgEntitiesTitle(data.organizationData.department),
        noticePeriod: data.offerMetaData.noticePeriod,
        currency: data.offerMetaData.currency,
        salary: data.offerMetaData.salary,
        joiningDate: moment(data.offerMetaData.joiningDate).format(
          "DD-MM-YYYY"
        ),
        expiryDate: moment(data.offerMetaData.expiryDate).format("DD-MM-YYYY"),
        location: data.organizationData.location,
        reportingManager: this.userService.getUserName(data.organizationData.reportingManager),
        taManager: this.userService.getUserName(data.organizationData.taManager), //new
        hrManager: this.userService.getUserName(data.organizationData.hrManager), //new
        recruiter: this.userService.getUserName(data.organizationData.recruiter),
        hiringManager: this.userService.getUserName(data.organizationData.hiringManager),
        // extraone: data.extrafield.extraone,
        // extrafield: data.extrafield.extrafield,
        date: moment(data.offerMetaData.date).format("DD-MM-YYYY")
      };
      this.offerFormData.next(formData);
    } else {
      if (data.dob) {
        this.dateOfBirth = moment(
          this.convertObjectToDateString(data.dob)
        ).format("DD-MM-YYYY");
      } else {
        this.dateOfBirth = "";
      }

      if (data.joiningDate) {
        this.joiningDateData = moment(
          this.convertObjectToDateString(data.joiningDate)
        ).format("DD-MM-YYYY");
      } else {
        this.joiningDateData = "";
      }

      if (data.expiryDate) {
        this.expiryDateData = moment(
          this.convertObjectToDateString(data.expiryDate)
        ).format("DD-MM-YYYY");
      } else {
        this.expiryDateData = "";
      }

      if (data.offerCreationDate) {
        this.creationDate = moment(
          this.convertObjectToDateString(data.offerCreationDate)
        ).format("DD-MM-YYYY");
      } else {
        this.creationDate = "";
      }

      const formData: any = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dob: this.dateOfBirth,
        contactNumber: data.contactNumber,
        nameAsInPassport: data.nameAsInPassport,
        resumeSource: this.getResumeSourceTitle(data.resumeSource),
        skills: data.skills,
        title: data.candidateTitle,
        offerDesignation: data.offerDesignation,
        businessUnit: this.getOrgEntitiesTitle(data.businessUnit),
        division: this.getOrgEntitiesTitle(data.division),
        department: this.getOrgEntitiesTitle(data.department),
        noticePeriod: data.noticePeriod,
        currency: data.currency,
        salary: data.salary,
        joiningDate: this.joiningDateData,
        expiryDate: this.expiryDateData,
        location: data.location,
        reportingManager: this.userService.getUserName(data.reportingManager),
        taManager: this.userService.getUserName(data.taManager), //new
        hrManager: this.userService.getUserName(data.hrManager), //new
        recruiter: this.userService.getUserName(data.recruiter),
        hiringManager: this.userService.getUserName(data.hiringManager),
        date: this.creationDate
        // extraone: data.extraone,
        // extrafield: data.extrafield
      };
      this.offerFormData.next(formData);
    }
  }
  getofferFormData() {
    return this.offerFormData.asObservable();
  }

  //same as master please do not edit this
  public getMailTemplate(): Observable<any> {
    // const request = this.initRequests.initGetMailTemplate({
    //   _action: "GET",
    //   orgHandle: this.orgHandle,
    //   queryBody: {
    //     masterType: "mailTemplates"
    //   }
    // });
    // return from(this.auth.post(this.apiName, this.path, request);

    let body = new Observable<any>();
    let allMasterData = JSON.parse(localStorage["_master"]); //all master data
    let nestedMaster = {
      body: []
    };
    nestedMaster.body = allMasterData.body.filter(element => {
      return element.type == "mailTemplates";
    });

    body = of(nestedMaster);
    return body;
  }

  public createMailTemplate(InsertBody) {
    const request = this.initRequests.initCreateMailTemplate({
      orgHandle: this.orgHandle,
      _action: "CREATE",
      insertBody: InsertBody
    });
    return from(this.auth.post(this.apiName, this.mailTemplatePath, request));
  }

  public updateMailTemplate(data) {
    const request = this.initRequests.initUpdateMailTemplateBody({
      orgHandle: this.orgHandle,
      _action: "UPDATE",
      updateBody: data
    });
    return from(this.auth.post(this.apiName, this.mailTemplatePath, request));
  }
  // get email temp data as temp code

  public getEmailTempDataByTempCode(data) {
    const request = this.initRequests.initGetMailTemplateData({
      orgHandle: this.orgHandle,
      _action: "GET",
      queryBody: data
    });
    return from(this.auth.post(this.apiName, this.mailTemplatePath, request));
  }
  // convert Object To Date String
  convertObjectToDateString(date) {
    if (date) {
      return new Date(date.year, date.month - 1, date.day).toISOString();
    } else {
      return null;
    }
  }
  // convert Object To Date String
  convertObjectToDateStringTOForamt(date) {
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    } else {
      return null;
    }
  }
  // convert Date String  to Object
  convertDateStringToObject(date) {
    if (date) {
      const tempDate = new Date(date);
      return {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    } else {
      return null;
    }

  }
  // convert Date String  to Object
  setDateDefault() {
    const tempDate = new Date();
    return {
      year: tempDate.getFullYear(),
      month: tempDate.getMonth() + 1,
      day: tempDate.getDate()
    };
  }

  // get first letter of string
  getFirstletter(data) {
    return data.charAt(0);
  }

  // convert time stamps to time ago
  timeStampToAgo(timestamp) {
    let time = moment(parseInt(timestamp));
    let currentTime = moment();
    let timeElapsed = currentTime.diff(time, "seconds");
    if (timeElapsed < 60) {
      return `${Math.floor(timeElapsed)} second(s) ago`;
    } else if (timeElapsed > 60 && timeElapsed < 3600) {
      return `${Math.floor(timeElapsed / 60)} min(s) ago`;
    } else if (timeElapsed > 3600 && timeElapsed < 86400) {
      return `${Math.floor(timeElapsed / 3600)} hour(s) ago`;
    } else if (timeElapsed > 86400) {
      return `${Math.floor(timeElapsed / 86400)} day(s) ago`;
    }
  }
  // get MastersData
  getMastersData() {
    this.getMasterAccToType(MasterTypes.orgEntities).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.orgEntities.push(element);
        });
      });
    });
    this.getMasterAccToType(MasterTypes.offerActivity).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.offerActivity.push(element);
        });
      });
    });
    this.getMasterAccToType(MasterTypes.orgRoles).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.orgRole.push(element);
        });
      });
    });
    this.getMasterAccToType(MasterTypes.offerStatus).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.offerStatus.push(element);
        });
      });
    });
    this.getMasterAccToType(MasterTypes.resumeSources).subscribe((res: any) => {
      res.body[0].data.forEach(ele => {
        ele.data.items.forEach(element => {
          this.resumeSources.push(element);
        });
      });
    });
  }
  // get the engagement titile
  getOrgEntitiesTitle(code) {
    return this.getLabelFromMasterData(this.orgEntities, "code", "title", code);
  }
  // get the engagement titile
  getResumeSourceTitle(code) {
    return this.getLabelFromMasterData(
      this.resumeSources,
      "code",
      "title",
      code
    );
  }
  // get the engagement titile
  getOfferActivityTitle(code) {
    const allStatusArray = this.offerActivity.concat(this.offerStatus);
    return this.getLabelFromMasterData(allStatusArray, "code", "title", code);
  }

  getOrgRoleTitle(code) {
    return this.getLabelFromMasterData(this.orgRole, "code", "title", code);
  }
  // get image url
  public getImagePath(imageName) {
    // const imageUrl = aws.s3.STAR_PROFILE + "/" + imageName;
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageName})`);
  }

  //notification time returner
  notificationTimeAgo(dateTime) {
    let time = moment(new Date(dateTime));
    let currentTime = moment();
    let timeElapsed = currentTime.diff(time, "seconds");
    if (timeElapsed < 60) {
      return `${Math.floor(timeElapsed)} second(s) ago`;
    } else if (timeElapsed > 60 && timeElapsed < 3600) {
      return `${Math.floor(timeElapsed / 60)} min(s) ago`;
    } else if (timeElapsed > 3600 && timeElapsed < 86400) {
      return `${Math.floor(timeElapsed / 3600)} hour(s) ago`;
    } else if (timeElapsed > 86400) {
      return `${Math.floor(timeElapsed / 86400)} day(s) ago`;
    }
  }

  // SET INSERT VARIABLES
  setVariables(data) {
    this.variableNameValue.next(data);
  }
  getVariableData() {
    return this.variableNameValue.asObservable();
  }
  getOfferIDSort(value) {
    if (value) {
      let splitted = value.split("_"); //spliited between _ //11,12,13
      let first = splitted[0].substr(splitted[1].length - 10);
      let middle = splitted[1];
      let last = splitted[2].substr(splitted[2].length - 5);
      return first + middle + last;
    } else {
      return null;
    }
  }

  getDateFormat(isoDate) {
    // return new Date(isoDate).toLocaleDateString();
    if (isoDate) {
      // return moment(isoDate).format("DD/MM/YYYY");
      return moment(isoDate).format("DD-MM-YYYY");
    } else {
      return '';
    }
  }
  getCandidateDetails(id) {
    const request = this.initRequests.initGetCandidateDetails({
      _action: "GET",
      queryBody: {
        candidateId: id
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }

  getJsDateFromExcel(excelDate) {

    let date = new Date((excelDate - (25567 + 1)) * 86400 * 1000);
    // let temp = moment(date).format('DD-MM-YYYY')
    // console.log(temp)
    // return new Date((excelDate - (25567 + 1)) * 86400 * 1000).toISOString();
    return date;
  }
  excelDateToJSDate(serial) {
    if (serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      let date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toISOString();
      return date;
    } else {
      return '';
    }

  }

  //  return boolean value
  returnBooleanValue(data) {
    // debugger;
    if (data === "Yes" || data === "yes") {
      return true;
    } else {
      return false;
    }
  }
  // set messaging flag to open offer msg section
  setMsgNotificationFlag(msgFlag) {
    this.setMsgFlag = msgFlag;
  }
  getMsgNotificationFlag() {
    if (this.setMsgFlag) {
      return this.setMsgFlag
    } else {
      return false;
    }
  }

  //used in engagement candidate configuration
  engagementTemplateToMasterMapper(newTemplateData) {
    let finalData = [];
    newTemplateData.forEach((stage) => {
      let obj = {
        masterType: "engagement",
        code: "",
        name: "",
        data: {
          items: []
        }
      }
      obj.code = stage.code;
      obj.name = stage._data.name;
      stage.activities.forEach((act) => {
        let activity = {
          code: "",
          activityType: "",
          interactionType: "",
          description:'',

          title: "", rules: []
        }
        activity.code = act.code;
        activity.activityType = act._data.activityType;
        activity.interactionType = act._data.interactionType;
        activity.description = act._data.description;
        activity.title = act._data.name;
        activity.rules = act.rules;
        obj.data.items.push(activity)
      })
      console.log(' stage.activities >>',stage.activities)

      finalData.push(obj);
      console.log('final data >>',finalData)
    })
    return finalData;
  }

  public ruleGeneratorForEngagement(rule, offerObject) {
    let offerCreated = moment(offerObject.createdAt);
    let joiningDate = moment(offerObject.offerMetaData.joiningDate);
    let sentDate = null;
    if (offerObject.collectiveStatus && offerObject.collectiveStatus.length > 0) {
      offerObject.collectiveStatus.forEach((status) => {
        if (status.statusCode === activityCodes.sent) {
          sentDate = moment(status.date);
        }
      })
    }
    //configuration for rules
    const rulesConfig = {
      actions: {
        "offer_created": offerCreated,
        "offer_sent": sentDate,
        "joining_date": joiningDate
      }
    }
    let obj = {
      time: "",
      date: {},
      user: ""
    }
    //assuming only one rule is there
    obj.time = rule.time;
    obj.date = this.dataCalcForRules(rule.days ? rule.days : 0, rule.condition ? rule.condition : 'After', rulesConfig.actions[rule.action]);
    obj.user = this.findUserForEngagement(offerObject, rule.role)
    obj.date = this.convertDateStringToObject(obj.date);
    return obj;
  }
  // function for the above rule generator
  private dataCalcForRules(timeInDays, condition, date) {
    let returnDate = "";
    switch (condition) {
      case 'before':
        {
          if (date) {
            returnDate = moment(date).subtract(timeInDays, 'days').format("YYYY-MM-DD");
          }
          else {
            returnDate = moment().format("YYYY-MM-DD");
          }
        }
        break;
      case 'After':
        {
          if (date) {
            returnDate = moment(date).add(timeInDays, 'days').format("YYYY-MM-DD");
          }
          else {
            returnDate = moment().format("YYYY-MM-DD");
          }
        }
        break;

    }
    return returnDate;
  }
  // function for the above rule generator
  private findUserForEngagement(offerData, role) {
    let orgData = offerData.organizationData;
    let user = orgData[orgRoles[role]] ? orgData[orgRoles[role]] : ""
    return user;
  }

  // remapper for extra data from template
  public remapperForUpdatedDataEngagement(masterData, engagementData) {
    engagementData.forEach((stage) => {
      let present = false;
      masterData.forEach((masterStage) => {
        if (masterStage.code == stage.groupName) {
          present = true;
        }
      })
      if (!present) {
        let _stage = {
          masterType: "engagement",
          code: stage.groupName,
          data: {
            items: []
          }
        }
        masterData.push(_stage);
      }
    })

    engagementData.forEach((stage) => {
      stage.items.forEach((act) => {
        masterData.forEach((_stage) => {
          if (stage.groupName === _stage.code) {
            let present = false;
            _stage.data.items.forEach((_item) => {
              if (_item.code == act.code) {
                present = true;
              }
            })
            if (!present) {
              let obj = {
                code: act.code,
                title: act.taskName,
                rules: []
              }
              _stage.data.items.push(obj);
            }
          }
        })
      })
    })
    return masterData;
  }


  public mapMasterToStages(stages, engagementData) {
    engagementData.forEach((stage, index) => {
      let present = false;
      stages.forEach((masterStage) => {
        if (masterStage.code == stage.groupName) {
          present = true;
        }
      })
      if (!present) {
        let _stage = {
          id: "",
          code: stage.groupName,
          activities: [],
          _data: {
          }
        }
        stages.push(_stage);
      }
    })

    engagementData.forEach((stage) => {
      stage.items.forEach((act) => {
        stages.forEach((_stage) => {
          if (stage.groupName === _stage.code) {
            let present = false;
            _stage.activities.forEach((_item) => {
              if (_item.code == act.code) {
                present = true;
              }
            })
            if (!present) {
              let obj = {
                code: act.code,
                id: "",
                rules: [],
                _data: {
                  name: act.taskName
                }
              }
              _stage.activities.push(obj);
            }
          }
        })
      })
    })

    return stages;

  }

}
