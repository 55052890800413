import { AuthService } from "./../../../services/auth/auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  authRole: any;
  constructor(private auth: AuthService) {
    this.authRole = null;
  }

  ngOnInit() {
    this.sortRoles();
  }
  sortRoles() {
    this.authRole = this.auth.getAuthRole();
    console.log(this.authRole);
  }
}
