import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/services/shared/shared.service';
import { MasterTypes, templateCodes } from 'src/app-settings/master_types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { Subscription } from 'rxjs';
// declare var $: any;

@Component({
  selector: 'app-customise-mail',
  templateUrl: './customise-mail.component.html',
  styleUrls: ['./customise-mail.component.scss']
})
export class CustomiseMailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() offerSubmittedState: string;
  @Input() showBulkOffeTemp: any;
  @Output() submitMailContent = new EventEmitter<any>();
  submitted = 'false';
  fieldSelect: any;
  subscription: Subscription;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };
  offerFormData: any[] = [];
  startCursorStartPosition: any;
  startEndPosition: any;
  startCursorEndPosition: any;
  range: any;
  offerDataObj: any;

  constructor(private shared: SharedService, private modalService: NgbModal) {
    this.getMailMasterData();
    this.getOfferData();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any): void {
    this.submitted = this.offerSubmittedState;
    if (changes && changes.form && changes.form.currentValue) {
      this.form = changes.form.currentValue;
    }
  }

  saveRange() {
    let sel;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        this.range = sel.getRangeAt(0);
      }
    }
  }

  async insertTextAtCursor(value) {
    this.range.deleteContents();
    this.range.insertNode(document.createTextNode(value));
  }

  getOfferData() {
    this.subscription = this.shared.getofferFormData().subscribe((offerDataObj: any) => {
      this.offerDataObj = offerDataObj;
    })
  }

  getMailMasterData() {
    this.shared.getMailMaster(MasterTypes.mailTempMaster, templateCodes.offerTemplate).subscribe(res => {
      if (res) {
        this.offerFormData = res.body[0].data[0].data.items;
      }
    }, (err => {
      console.log(err)
    }));
  }

  openPreviewTemplateModal() {
    const modalRef = this.modalService.open(PreviewTemplateComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.mailData = document.getElementById('editor').innerHTML;
    modalRef.componentInstance.offerFormData = this.offerDataObj;
    modalRef.componentInstance.showBulkOffeTemp = this.showBulkOffeTemp;
    modalRef.componentInstance.submitMailData.subscribe((res) => {
      this.submitMailContent.emit(res);
    });
  }
  saveBulkEdittemp() {
    this.submitMailContent.emit(document.getElementById('editor').innerHTML);
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}