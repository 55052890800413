import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-engagment-tracker-table',
  templateUrl: './engagment-tracker-table.component.html',
  styleUrls: ['./engagment-tracker-table.component.scss']
})
export class EngagmentTrackerTableComponent implements OnInit {
  @Input("offerData") offers: any[];
  searchValue = '';
  RecuriterSearchValue = '';
  listOfData: any[] = [];
  listOfDisplayData: any[] = [];
  sortValue: string | null = null;
  sortName: string | null = null;
  listOfFilterName = [{ text: 'Completed', value: 'Completed' }, { text: 'Pending', value: 'Pending' }];

  constructor(
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.listOfData = this.offers;
    this.listOfDisplayData = this.listOfData;
    console.log(this.listOfData);
  }

  resetFilters(): void {
    this.searchValue = '';
    this.RecuriterSearchValue = '';
    this.sortName = '';
    this.sortValue = '';
    this.sortAll();
    this.search();
  }

  reset(): void {
    this.searchValue = '';
    this.RecuriterSearchValue = '';
    this.search();
  }

  search() {
    this.RecuriterSearchValue = '';
    const filterFunc = (item: {
      Engagement: boolean;
      RevisedDOJ: string;
      activity: string;
      activityStatus: string;
      activityType: string;
      candidateName: string;
      candidateStatus: string;
      dueDate: string;
      engagementStartDate: string;
      engagmentPeriod: string;
      groupName: string;
      initialDOJ: string;
      offerDate: string;
      offer_id: string;
      recruiter: string;
      remainingJoiningDate: string;
    }) => {
      console.log(item);
      return (
        item.candidateName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      );
    };
    const data = this.listOfData.filter(
      (item: {
        Engagement: boolean;
        RevisedDOJ: string;
        activity: string;
        activityStatus: string;
        activityType: string;
        candidateName: string;
        candidateStatus: string;
        dueDate: string;
        engagementStartDate: string;
        engagmentPeriod: string;
        groupName: string;
        initialDOJ: string;
        offerDate: string;
        offer_id: string;
        recruiter: string;
        remainingJoiningDate: string;
      }) => filterFunc(item)
    );
    this.listOfDisplayData = data;
    // this.listOfDisplayData = data.sort((a, b) =>
    //   this.sortValue === 'ascend'
    //     ? a[this.sortName!] > b[this.sortName!]
    //       ? 1
    //       : -1
    //     : b[this.sortName!] > a[this.sortName!]
    //     ? 1
    //     : -1
    // );
  }

  searchRecuriter() {
    this.searchValue = '';
    const filterFunc = (item: {
      Engagement: boolean;
      RevisedDOJ: string;
      activity: string;
      activityStatus: string;
      activityType: string;
      candidateName: string;
      candidateStatus: string;
      dueDate: string;
      engagementStartDate: string;
      engagmentPeriod: string;
      groupName: string;
      initialDOJ: string;
      offerDate: string;
      offer_id: string;
      recruiter: string;
      remainingJoiningDate: string;
    }) => {
      return (
        item.recruiter.toLowerCase().indexOf(this.RecuriterSearchValue.toLowerCase()) !== -1
      );

      // console.log(item);
      // if(this.listOfDisplayData.length > 0){
      //   return (this.listOfDisplayData.indexOf(this.RecuriterSearchValue) !== -1);
      // } else {
      //   return (item.recruiter.indexOf(this.RecuriterSearchValue) !== -1);
      // }
    };
    const data = this.listOfData.filter(
      (item: {
        Engagement: boolean;
        RevisedDOJ: string;
        activity: string;
        activityStatus: string;
        activityType: string;
        candidateName: string;
        candidateStatus: string;
        dueDate: string;
        engagementStartDate: string;
        engagmentPeriod: string;
        groupName: string;
        initialDOJ: string;
        offerDate: string;
        offer_id: string;
        recruiter: string;
        remainingJoiningDate: string;
      }) => filterFunc(item)
    );
    this.listOfDisplayData = data;
  }

  sort(sort: { key: string; value: string }): void {
    console.log(sort);
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.sortAll();
  }

  sortAll(): void {
    const data = this.listOfData;
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
    } else {
      this.listOfDisplayData = data;
    }
  }


}
