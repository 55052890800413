import { Component } from "@angular/core";
import { SharedService } from "./services/shared/shared.service";
import { environment } from "../../src/environments/environment";
// import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  versionNumber = "";
  constructor(public shared: SharedService) {
    this.versionNumber = environment.versionNumber;
    // this.primengConfig.ripple = true;
  }
  title = "organization";
}
