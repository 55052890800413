import { AuthService } from "./../../../services/auth/auth.service";
import { SharedService } from "./../../../services/shared/shared.service";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "./../../../services/notification/notification.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  notifications: any;
  newNotificationCount: any;
  unreadNotifications: any;
  notificationPolling: NodeJS.Timer;
  userInitials: any;
  sub: any;
  notificatonBellIcon = true;
  constructor(
    config: NgbDropdownConfig,
    private notificationService: NotificationService,
    private toasterService: ToastrService,
    public shared: SharedService,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    config.placement = "bottom-right";
    //  config.autoClose = false;
    this.notifications = [];
    this.newNotificationCount = 0;
    this.unreadNotifications = [];
    this.userInitials = null;
  }

  ngOnInit() {
    this.getNotifications();
    this.getCurrentUserData();
    /**Removed f11-03-2021 -  */
    // this.notificationPolling = setInterval(() => {
    //   this.getNotifications();
    // }, 60000);
    
    this.sub = this.route.params.subscribe(params => {
      if (params.id) {
        this.notificatonBellIcon = false;
      }
    });
  }

  private getNotifications() {
    this.notificationService.getNotifications().subscribe(
      res => {
        this.concatAndSortOffers(res.body);
        this.sortAndUpdate();
      },
      error => {
        /** reason of removal Frequent error occurence */
        // this.toasterService.error(
        //   "something went wrong while getting notifications"
        // );
      }
    );
  }

  sortAndUpdate() {
    this.newNotificationCount = 0;
    this.unreadNotifications = [];
    this.notifications.forEach(element => {
      element.min = moment().diff(
        moment(element.createdAt).startOf("minutes"),
        "minutes"
      );
      if (element.notificationStatus === "unread") {
        this.newNotificationCount++;
        this.unreadNotifications.push(element._id);
      }
    });
  }

  statusUpdate() {
    if (this.unreadNotifications.length > 0) {
      this.notificationService
        .udpateNotificationStatus({
          read: true,
          notificationIds: this.unreadNotifications
        })
        .subscribe(
          res => {
            this.newNotificationCount = 0;
          },
          error => {
            this.toasterService.error("something went wrong");
          }
        );
    }
  }

  //apply clicked update to notification
  viewOffer(id, index) {
    this.notificationService
      .udpateNotificationStatus({
        clicked: true,
        notificationIds: [this.notifications[index]._id]
      })
      .subscribe(
        res => {
          this.router.navigate(["offer", id]);
          this.getNotifications();
          this.shared.setMsgNotificationFlag(true);
        },
        error => {
          this.toasterService.error("something went wrong");
        }
      );
  }
  ngOnDestroy(): void {
    // clearInterval(this.notificationPolling);
    this.sub.unsubscribe();
  }

  getCurrentUserData() {
    this.auth.getCurrentAuthUser().subscribe(
      res => {
        let userData = res.attributes.email;
        let initials = userData.split("");
        this.userInitials = initials[0];
      },
      error => {
        this.router.navigate([""]);
      }
    );
  }

  concatAndSortOffers(notifications) {
    let concatenatedArray = [];
    let uniqueMessageNotifications = [];
    notifications.messageNotification.forEach(element => {
      if (uniqueMessageNotifications.length > 0) {
        let exists = null;
        uniqueMessageNotifications.forEach(el => {
          if (el.offerId === element.offerId) {
            exists = true;
          }
        });
        if (exists) {
          if (element.notificationStatus === "unread") {
            uniqueMessageNotifications.push(element);
          }
        } else {
          uniqueMessageNotifications.push(element);
        }
      } else {
        uniqueMessageNotifications.push(element);
      }
    });
    concatenatedArray = uniqueMessageNotifications.concat(
      notifications.offerNotifications
    );
    this.notifications = _.orderBy(concatenatedArray, function (o) {
      //showing the latest status in 0th index
      return moment(o.createdAt);
    }).reverse();
  }
}
