import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../services/shared/shared.service";

@Pipe({
  name: "offerfilter"
})
export class OfferfilterPipe implements PipeTransform {

  transform(items: any[], value: any, label: string): any[] {
    if (!items) return [];
    if (!value) return items;
    if (value == '' || value == null) return [];
    if (value == 'Invalid date') return items;
    value = value.toLowerCase();
    return items.filter(e => e[label].toLowerCase().indexOf(value) > -1);

  }
}
