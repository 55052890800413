import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from 'src/app/services/shared/shared.service';
@Component({
  selector: 'app-response-mail',
  templateUrl: './response-mail.component.html',
  styleUrls: ['./response-mail.component.scss']
})
export class ResponseMailComponent implements OnInit, OnChanges {
  tags: any = [];
  @Input() formData: any;
  @Input() offerId: any;
  @Input() candidateId: any;
  @Output() outputData = new EventEmitter();
  message: any;
  @ViewChild(NgbTabset) public tabset: NgbTabset;
  offerMailForm: FormGroup;
  msgNotificationFlag: any;
  constructor(private fb: FormBuilder, public shared: SharedService, ) {
    this.message = {
      sender: '',
      message: '',
      time: ''
    };
  }

  ngOnInit() {
    this.offerMailForm = this.fb.group({
      mailTemplateForm: this.fb.group({
        subject: ['', Validators.required],
        content: ['', Validators.required],
        attachement: [''],
        attachedToMail: ['']
      })
    });
    this.getMsgFlagFromNotification();
  }
  ngOnChanges(changes: any): void {
    if (changes && changes.formData && changes.formData.currentValue) {
      this.formData = changes.formData.currentValue;
      if (this.formData) {
        this.setData();
      }
    }
  }

  getMsgFlagFromNotification() {
    this.msgNotificationFlag = this.shared.getMsgNotificationFlag();
    if (this.msgNotificationFlag === true) {
      this.tabset.activeId = 'tab-selectbyid2';
    } else {
      this.tabset.activeId = 'tab-selectbyid1';
    }
  }
  setData() {
    this.offerMailForm.get('mailTemplateForm').patchValue({
      subject: this.formData.subject,
      content: this.formData.content,
      attachement: '',
      attachedToMail: ''
    })
  }

  getmailData() {
    // this.outputData.emit(this.offerMailForm.get('mailTemplateForm'));
  }
  getMailContent(data) {
    let mailData = {
      subject: this.offerMailForm.get('mailTemplateForm').get('subject').value,
      content: data,
      attachement: '',
      attachedToMail: ''
    };
    this.outputData.emit(mailData);
  }
}
