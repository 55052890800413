import { Component, OnInit } from "@angular/core";
import { CreateTaskModalComponent } from "../create-task-modal/create-task-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StageService } from "../../../../services/stage/stage.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedService } from "../../../../services/shared/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-engage-task-list",
  templateUrl: "./engage-task-list.component.html",
  styleUrls: ["./engage-task-list.component.scss"]
})
export class EngageTaskListComponent implements OnInit {
  taskList = [];
  constructor(private modalService: NgbModal, private stageService: StageService,
    private shared: SharedService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService) { }

  ngOnInit() {
    this.getTasks();
  }

  openModal(id, name, description, digital,activityType,interactionType) {
    const modalRef = this.modalService.open(CreateTaskModalComponent, {
      windowClass: "engage-edit-modal",
      size:'lg',
      centered: true
    });

    modalRef.componentInstance.data = { id, name, description, digital ,activityType,interactionType};
    modalRef.result.then((res) => {
      if (res) {
        if (res.update) {
          console.log(res);
          this.updateTask(res.data);
        }
        else {
          this.addTask(res.data);
        }
      }
    }).catch(err => {
      console.log("accidental close");
    })
  }

  //get stages listing
  private getTasks() {
    const payload = { type: "activity" }
    this.loader.start()
    this.stageService.getStages(payload).subscribe((res) => {
      this.loader.stop();
      this.taskList = res.body;
    }, error => {
      this.loader.stop();
      this.toaster.error("Error getting engagement activities");
    })
  }
  
  //add stage
  private addTask(data) {
    this.loader.start();
    const payload = { type: "activity", name: data.name, description: data.description, digital: data.digital,activityType:data.activityType ,interactionType:data.interactionType};
    this.stageService.createStage(payload).subscribe((res) => {
      this.toaster.success("Engagement activity created");
      this.getTasks();
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error creating engagement activity");
    })
  }
  //update stage
  private updateTask(data) {
    this.loader.start();
    const payload = { id: data.id, body: { type: "activity", name: data.name, description: data.description, digital: data.digital,activityType:data.activityType ,interactionType:data.interactionType} };
    console.log(data, payload)
    this.stageService.updateStage(payload).subscribe((res) => {
      this.toaster.success("Engagement activity updated");
      this.getTasks();
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error updating engagement activity");
    })
  }
}
