import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests, StageCreate } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class StageService {
  apiName: string;
  path: string;
  orgHandle: string;

  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.stage;
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // creating master
  public createStage(body) {
    const request = this.initRequests.initCreateStage({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // updating master
  public updateStage(body) {
    const request = this.initRequests.initUpdateStage({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  // updating master
  public getStages(body) {
    const request = this.initRequests.initGetStage({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: body
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
}
