import { AuthService } from 'src/app/services/auth/auth.service';
import { OfferService } from 'src/app/services/offer/offer.service';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';
import { RatingModalComponent } from 'src/app/pages/start-engagement/components/rating-modal/rating-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EngagementService } from 'src/app/services/engagement/engagement.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { CandidateEngageModalComponent } from '../../../pages/start-engagement/components/candidate-engage-modal/candidate-engage-modal.component';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EngagementTemplateService } from 'src/app/services/engagementTemplate/engagement-template.service';
import { StageService } from "../../../services/stage/stage.service";


import { UserActivityModalComponent } from 'src/app/pages/start-engagement/components/user-activity-modal/user-activity-modal.component';
import { EngagementAssosiateActivityComponent } from 'src/app/pages/start-engagement/components/engagement-assosiate-activity/engagement-assosiate-activity.component';
import { UserActivityFeedbackModalComponent } from 'src/app/pages/start-engagement/components/user-activity-feedback-modal/user-activity-feedback-modal.component';
import { ShowDescriptionComponent } from 'src/app/pages/start-engagement/components/description/show-description/show-description.component';
import { DigitalActivityModalComponent } from 'src/app/pages/start-engagement/components/digital-activity-modal/digital-activity-modal.component';
import { OtherActivityModalComponent } from 'src/app/pages/start-engagement/components/other-activity-modal/other-activity-modal.component';


@Component({
  selector: 'app-candidate-engagement',
  templateUrl: './candidate-engagement.component.html',
  styleUrls: ['./candidate-engagement.component.scss']
})
export class CandidateEngagementComponent implements OnInit, OnChanges {
  items;
  moreDetailsToggle: boolean[] = [];
  public users: any;
  engamentMasterData: any[] = [];
  engamentForm: FormGroup;
  cards: boolean;
  foods:any;
  // readMoreText: string;
  markAsCompleted: any;
  feedbackPayload = {};
  @Input() public candidateData;
  @Output() ratingOutput = new EventEmitter();
  engagementDataForCandidate: any;
  @Output() totalActivity = new EventEmitter();
  @Output() engagmentfinalDate = new EventEmitter();
  engagementTaskmasterData: any[] = [];
  checkedMasrkAscomplete: boolean;
  currentDate: any;
  engagementTable: any[];
  public periodicEngagement: any;
  currentUser: any;
  templateId: any = ""; // template id
  stages: any = []; // stges for template
  stageMaster: any = [] = [];
  activites: any = [] = [];
  tableTitles: any = [];
  sortingdate: any = [];
  public stagesList: any = [];
  isClientSubmitted: boolean;
  offerData:any;
  isChecked: boolean;
  groupName: any;

  // offerData: any;

  constructor(
    public userService: UserService,
    public sharedService: SharedService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private engagementService: EngagementService,
    private loader: NgxUiLoaderService,
    private toaster: ToastrService,
    private offerService: OfferService,
    public config: NgbAccordionConfig,
    private router: Router,
    public authService: AuthService,
    private engagementTemplateService: EngagementTemplateService,
    private stageService: StageService
  ) {
    // console.log('stages list >>',this.stagesList);

    this.config.closeOthers = false;
    // this.readMoreText = 'read more';
    this.checkedMasrkAscomplete = true;
    this.cards = false;
    this.getAccountDetails();
    this.currentDate = new Date().toISOString();
    this.engagementTable = [];
  }

  riskAreas = new FormControl();
  riskAreasList: string[];
  eaForm:any


  multiSelected(){
    console.log(this.riskAreas.value)
  }
  ngOnInit() {

    console.log('currentDate >>',this.currentDate)
    this.riskAreasList = [
      'Compensation',
      'Role Clarity',
      'Responsibility',
      'In- depth understanding of Tech stack',
      'Change in Joining Date ',
      'Unresponsive to EA',
      'Relocation requirements',
      'Location proximity',
      'WFH options',
      'Comp and Benefits',
      'Personal Issues',
      'Health reasons',
      'Retention efforts in the current organisation',
      'Project/Client Dependency in the current organisation',
      'Counter Offers In hand',
      'Counter Offers In Pipeline',
      'Brand Visibility ',
      'Resignation not accepted',
      'Last Working Day not confirmed',
      'Option to add a Risk Area'
    ]

   console.log('candidateData >>>',this.candidateData);
    // changed by rohan...should not  be called in service constructor
    // console.log('stages list >>',this.stagesList);

    this.userService.getUser(() => {
      this.getStagesMaster(() => {
        this.sharedService.getEngamentMasterData();
        this.sharedService.getMastersData();
      })
    });
  }

  ngOnChanges(changes: any): void {
    if (
      changes &&
      changes.candidateData &&
      changes.candidateData.currentValue
    ) {
      this.candidateData = changes.candidateData.currentValue;
      this.getEngagementDataForCandidate();
      this.showEngagementTableData();
      this.getOfferDetailsByOferID(this.candidateData);
    }


    // if (
    //   changes &&
    //   changes.candidateData &&
    //   changes.candidateData.currentValue
    // ) {
    //   this.candidateData = changes.candidateData.currentValue;
    //   this.getOfferDetailsByOferID(this.candidateData);
    // }
    // if (changes && changes.personRating && changes.personRating.currentValue) {
    //   this.engagementRating = changes.personRating.currentValue;
    // }
    // if (changes && changes.ratingData && changes.ratingData.currentValue) {
    //   this.ratingMeta = changes.ratingData.currentValue;
    // }



  }


  public markAsComplete(
    data: string,
    isChecked: boolean,
    item,
    assigned: string
  ) {

    if (isChecked) {
      this.markAsCompleted = {
        offerId: this.candidateData.offerId,
        body: [
          {
            groupName: item,
            items: [
              {
                code: data,
                applicable: true,
                assignedTo: assigned,
                other: false,
                otherEmail: '',
                completed: isChecked
              }
            ]
          }
        ]
      };
      // console.log(item, data, this.markAsCompleted, assigned);
      this.openratinggmodal(item, data, this.markAsCompleted, assigned);
    }
  }

  public updateEngagementTask(data) {
    this.loader.start();
    this.engagementService.updateEngagementTask(data).subscribe(
      res => {
        this.loader.stop();
        this.toaster.success('Engagement task updated sucessfully');
      },
      error => {
        this.loader.stop();
        this.toaster.error('something went wrong.');
      }
    );
  }

  public toggleView() {
    this.cards = !this.cards;
  }
  public cehckActivityType() {

  }
  // ................................................................
  panelOpenState = false;

  showDescription(description){
    const modalRef = this.modalService.open(ShowDescriptionComponent, {
      size: 'sm',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.feedbackModalData = {description};

  }


  markToComplete(activityType,interactionType, data, item, assigned, description, activityName) {
    // console.log(activityType,data, item)
    this.groupName = data;

    this.isClientSubmitted = true;
    if (this.isClientSubmitted) {
      this.markAsCompleted = {
        offerId: this.candidateData.offerId,
        body: [
          {
            groupName: item,
            items: [
              {
                code: data,
                applicable: true,
                assignedTo: assigned,
                other: false,
                otherEmail: '',
                completed: this.isClientSubmitted
              }
            ]
          }
        ]
      };

    if (activityType === 'CHACT') {
      let payload = {
        offerId: this.candidateData.offerId
      };
      this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
        res => {
          console.log(res)
          const candidateData = res.body;
          this.openClientActivityModal(activityType, interactionType, item, data, this.markAsCompleted, assigned, candidateData, activityName);
        },
        err => {
          this.toaster.error('Somthing went wrong');
        }
      );
    }
    else if(activityType === 'EAACT'){
      let payload = {
        offerId: this.candidateData.offerId
      };
      this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
        res => {
          console.log(res)
          const candidateData = res.body;
          this.openEngagementAssosiateModal(candidateData, activityType, item, data, this.markAsCompleted, assigned, this.candidateData.candidateName, this.offerData.offerMetaData.joiningDate, activityName);
        },
        err => {
          this.toaster.error('Somthing went wrong');
        }
      );
    }
    else if(activityType === 'DIGACT'){
      let payload = {
        offerId: this.candidateData.offerId
      };
      this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
        res => {
          console.log(res)
          const candidateData = res.body;
          this.openDigitalActivittModal(candidateData, activityType,interactionType, item, data, this.markAsCompleted, assigned, activityName);
        },
        err => {
          this.toaster.error('Somthing went wrong');
        }
      );
      // this.openDigitalActivittModal(activityType,interactionType, item, data, this.markAsCompleted, assigned);
    }
    else if(activityType === 'OTHACT'){
      let payload = {
        offerId: this.candidateData.offerId
      };
      this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
        res => {
          console.log(res)
          const candidateData = res.body;
          // this.openDigitalActivittModal(candidateData, activityType,interactionType, item, data, this.markAsCompleted, assigned, activityName);
          this.openOtherActivityModal(candidateData, activityType,interactionType, item, data, this.markAsCompleted, assigned, activityName);
        },
        err => {
          this.toaster.error('Somthing went wrong');
        }
      );
    }
    }
  }


  // ///////////////////
// openDigitalActivittModal
openDigitalActivittModal(candidateData, activityType, interactionType, data, item, markAsCompleted, assigned, activityName){
  const modalRef = this.modalService.open(DigitalActivityModalComponent, {
    size: 'sm',
    backdrop: 'static',
    centered: true
  });
  modalRef.componentInstance.feedbackModalData = { data, isChecked: this.isClientSubmitted, item, assigned, offerId: this.candidateData.offerId ,interactionType, activityName: activityName};
  modalRef.componentInstance.candidateDataFromModal = candidateData;

  modalRef.componentInstance.submitFeedbackFormData.subscribe(response => {
    this.updateEngagementTask(markAsCompleted);

    this.getEngagementDataForCandidate();
    this.showEngagementTableData();
  });

  modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
    this.checkedMasrkAscomplete = res;
    this.getEngagementDataForCandidate(() => {
      this.showEngagementTableData();
    });
  });
  window.scrollTo(0, 20000);
}


// openOtherActivityModal
openOtherActivityModal(candidateData, activityType,interactionType, data, item, markAsCompleted, assigned, activityName){
  const modalRef = this.modalService.open(OtherActivityModalComponent, {
    size: 'sm',
    backdrop: 'static',
    centered: true
  });
  modalRef.componentInstance.feedbackModalData = { data, isChecked: this.isClientSubmitted, item, assigned, offerId: this.candidateData.offerId ,interactionType, activityName: activityName};
  modalRef.componentInstance.candidateDataFromModal = candidateData;

  modalRef.componentInstance.submitFeedbackFormData.subscribe(response => {
    // this.submitEngagementFeedback(item, data, assigned);
    // !----------------NEW-----------------------------//
    //  getting engdata after submission of data
    this.updateEngagementTask(markAsCompleted);

    this.getEngagementDataForCandidate();
    this.showEngagementTableData();
  });

  modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
    this.checkedMasrkAscomplete = res;
    this.getEngagementDataForCandidate(() => {
      this.showEngagementTableData();
    });
  });
  window.scrollTo(0, 20000);
}





  // clientActivityModal
  openClientActivityModal(activityType, interactionType, data, item, markAsCompleted, assigned, candidateData, activityName) {
    console.log(activityType,interactionType, data, item, markAsCompleted, assigned)

      const modalRef = this.modalService.open(UserActivityModalComponent, {
        size: 'lg',
        backdrop: 'static',
        centered: true
      });
      modalRef.componentInstance.feedbackModalData = { data, isChecked: this.isClientSubmitted, item, assigned, offerId: this.candidateData.offerId ,interactionType, activityName:activityName};
      modalRef.componentInstance.candidateDataFromModal = candidateData;
      modalRef.componentInstance.submitFeedbackFormData.subscribe(async response => {
        await this.updateEngagementTask(markAsCompleted);

        await this.getEngagementDataForCandidate();
        await this.showEngagementTableData();
      });

      modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
        this.checkedMasrkAscomplete = res;
        this.getEngagementDataForCandidate(() => {
          this.showEngagementTableData();
        });
      });
      window.scrollTo(0, 20000);
      // window.location.reload();

    // }

  }

  // viewClientActivityDetails
  viewClientActivityDetails(code,activityType,interactionType){
    // console.log(code, activityType);
    // CHACT will pop-up with static data
    if(activityType === 'CHACT'){
      const modalRef = this.modalService.open(UserActivityFeedbackModalComponent, {
        size: 'lg',
        backdrop: 'static',
        centered: true
      });
      console.log(code);
      modalRef.componentInstance.staticModalData = { offerId: this.candidateData.offerId,code:code ,interactionType};
      modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
        // this.checkedMasrkAscomplete = res;
        this.getEngagementDataForCandidate(() => {
          this.showEngagementTableData();
        });
      });
    }
    // EAACT will pop-up with static data
    else if(activityType === 'EAACT'){
      const modalRef = this.modalService.open(EngagementAssosiateActivityComponent, {
        size: 'lg',
        backdrop: 'static',
        centered: true
      });

      modalRef.componentInstance.staticModalData = { offerId: this.candidateData.offerId,code:code };
      modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
        this.checkedMasrkAscomplete = res;
        this.getEngagementDataForCandidate(() => {
          this.showEngagementTableData();
        });
      });
    }
    else{
      // console.log('This is OTHER activity')
    }



    }




  // openEngagementAssosiateModal
  openEngagementAssosiateModal(candidateData, activityType, data, item, markAsCompleted, assigned,candidateName,joiningDate, activityName) {
   // console.log('stagesList >>',this.stagesList, markAsCompleted);
    this.isChecked = true
    const modalRef = this.modalService.open(EngagementAssosiateActivityComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });

    console.log(candidateData);
    modalRef.componentInstance.feedbackModalData = { data, isChecked:  this.isChecked , item, assigned, offerId: this.candidateData.offerId ,candidateName:candidateName,joiningDate:joiningDate, activityName: activityName};
    modalRef.componentInstance.candidateDataFromModal = candidateData;
    modalRef.componentInstance.submitFeedbackFormData.subscribe(async response => {
      // this.submitEngagementFeedback(item, data, assigned);
      // !----------------NEW-----------------------------//
      //  getting engdata after submission of data
      await this.updateEngagementTask(markAsCompleted);

      await this.getEngagementDataForCandidate();
      await this.showEngagementTableData();
    });

    modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
      this.checkedMasrkAscomplete = res;
      this.getEngagementDataForCandidate(() => {
        this.showEngagementTableData();
      });
    });
    window.scrollTo(0, 20000);
    // window.location.reload();

  // }

  }



  // ngOnChanges
  // ngOnChanges(changes: any): void {

  // }



// GET ENGAGEMENT DATA FOR OFFER
getOfferDetailsByOferID(data) {
  const payload = {
    offerId: data.offerId
  };
  this.offerService.GetOfferDeatilsByOferID(payload).subscribe(res => {
    if (res.body) {
      this.offerData = res.body;
      console.log('newodderData >>',this.offerData);
      // this.profileImage = this.offerData.candidateData.profileImage;
      // this.engagmentDays = Math.ceil( Math.abs((new Date(this.offerData.createdAt).getTime()-new Date((this.offerData.offerMetaData.expiryDate) ? this.offerData.offerMetaData.expiryDate : this.offerData.offerMetaData.joiningDate).getTime())/ (1000*60*60*24)))
    }
  });
}



////////////////////////////////////
  // open ratings  model
  openratinggmodal(item, data, markAsCompleted, assigned) {
    // document.getElementById(data).setAttribute('checked', 'checked');

    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });


    // modalRef.componentInstance.feedbackModalData = this.feedbackFormData;
    modalRef.componentInstance.feedbackModalData = { item, data, markAsCompleted, assigned, offerId: this.candidateData.offerId };

    // after rating the candidate
    modalRef.componentInstance.submitFeedbackFormData.subscribe(response => {
      // this.submitEngagementFeedback(item, data, assigned);
      // !----------------NEW-----------------------------//
      //  getting engdata after submission of data
      this.updateEngagementTask(markAsCompleted);

      this.getEngagementDataForCandidate();
      this.showEngagementTableData();
    });
    modalRef.componentInstance.feedbackFormClosed.subscribe(res => {
      this.checkedMasrkAscomplete = res;
      this.getEngagementDataForCandidate(() => {
        this.showEngagementTableData();
      });
    });
    window.scrollTo(0, 20000);
  }

  sortdates(es) {
    this.sortingdate = [];
    es.forEach(e => {
      this.sortingdate.push(new Date(e.date))
    });
    this.engagmentfinalDate.emit(new Date(Math.max.apply(null, this.sortingdate)))
  }


  // getEngagemnt for candidate
  getEngagementDataForCandidate(callback?) {
    this.loader.start();
    if (this.candidateData) {
      // console.log(this.candidateData)
      this.engagementService
        .getEngagementData(this.candidateData.offerId)
        .subscribe(res => {
          console.log(res)
          this.sortdates(res.body.engagementData[0].items);
          let tAt = 0;
          res.body.engagementData.forEach(element => {
            tAt += element.items.length;
          });
          this.totalActivity.emit(tAt);
          if (res.body) {
            this.templateId = res.body.templateId;
            this.getTemplate(() => {
              this.loader.stop();
              this.engagementDataForCandidate = res.body.engagementData;
              console.log(this.engagementDataForCandidate)
              //mapping stages to master
              this.mapEngagementObjectToMaster(() => {
                if (callback) {
                  callback();
                }
              })
            })
          } else {
            this.loader.stop();
            this.engagementDataForCandidate = [];
            if (callback) {
              callback();
            }
          }
        });
    }
  }

  showEngagementTableData() {
    let queryBody = {
      offerId: this.candidateData.offerId
    };
    this.offerService.GetOfferDeatilsByOferID(queryBody).subscribe(
      res => {
        let offerData = res.body;
        this.engagementService
          .getEngagementFeedbackTable(this.candidateData.offerId)
          .subscribe(
            res => {
              let engagementData = res.body;
              console.log(engagementData)
              this.sortForTable(offerData, engagementData);
            },
            error => {
              this.toaster.error('Somthing went wrong');
            }
          );
      },
      error => {
        this.toaster.error('Somthing went wrong');
      }
    );
  }

  sortForTable(offerData, engagementData) {
    // console.log(offerData, engagementData, this.groupName)
    this.engagementTable = [];
    let finalValue;
    if (engagementData !== undefined) {
      if(this.groupName !== undefined ){
        if (engagementData.length > 0) {
          engagementData.forEach(element => {
            //! NEW CHANGES SUPPORT
            // if (Array.isArray(element.feedbackData) && element.feedbackData.length > 0) {
            //   let constants = {
            //     "Organization and Culture": "hiringExperience",
            //     "Job Role": "jobRole",
            //     "Compensation and benefits": "compensation",
            //     "Workplace and policies": "workPlace",
            //     "Career Growth": "careerGrowth"
            //   }
            //   element.feedbackDataTable = {};
            //   //ignoring can't rate option
            //   element.feedbackData[0].parameters.forEach(param => {
            //     let key = constants[param.title];
            //     element.feedbackDataTable[key] = param.value;
            //   });
            // } //This is for support temporary
            // else {
            //   element.feedbackDataTable = element.feedbackData;
            // }
            // console.log("here", element)
            // let scoreObject = {
            //   role: null,
            //   email: null,
            //   feedback: null,
            //   total: null,
            //   activity: null,
            //   date: null
            // };
            // scoreObject.email = element.emailId;
            // scoreObject.feedback = element.feedbackDataTable;
            // // Here if the value exists then we will use it otherwise 0
            // scoreObject.total = (
            //   (element.feedbackDataTable.compensation ? 0.4 * parseInt(element.feedbackDataTable.compensation) : .4 * 5) +
            //   (element.feedbackDataTable.careerGrowth ? 0.2 * parseInt(element.feedbackDataTable.careerGrowth) : .2 * 5) +
            //   (element.feedbackDataTable.hiringExperience ? 0.2 * parseInt(element.feedbackDataTable.hiringExperience) : 0.2 * 5) +
            //   (element.feedbackDataTable.jobRole ? 0.1 * parseInt(element.feedbackDataTable.jobRole) : .1 * 5) +
            //   (element.feedbackDataTable.workPlace ? 0.1 * parseInt(element.feedbackDataTable.workPlace) : .1 * 5)
            // ).toPrecision(2);
            // scoreObject.activity = null;
            // scoreObject.date = element.date;
            // this.engagementTable.push(scoreObject);

            if(element.groupName === this.groupName){
              finalValue = element.eafeedbackData.riskAssesmentValue
            }
          });

          // console.log("engagementTable", this.engagementTable)
          // this.engagementTable = _.orderBy(this.engagementTable, function (o) {
          //   return moment(o.date);
          // }); //the latest one will be at last


          this.ratingOutput.emit({
            average: finalValue,  //latest engagement score
            offerId: offerData.offerId,
            incDec: 0.0
          });
        }
      } else {

        this.engagementService
        .getEngagementData(this.candidateData.offerId)
        .subscribe(res => {

          this.ratingOutput.emit({
            average: res.body.ratingData,
            offerId: offerData.offerId,
            incDec: 0.0
          });
        })
      }

      } else {
        this.ratingOutput.emit({
          average: null, //latest engagement score
          offerId: offerData.offerId,
          incDec: null
        });
      }
    }


  getOfferData(candidateData) {
    const modalRef = this.modalService.open(CandidateEngageModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    console.log(candidateData);
    modalRef.componentInstance.candidateDataFromModal = candidateData;
    modalRef.componentInstance.action = 'edit'; //this is to tell the modal whether it is edit or start engagement
    modalRef.componentInstance.editEngagementResponse.subscribe(res => {
      if (res.msg === 'success') {
        this.getEngagementDataForCandidate();
      }
    });
  }

  getAccountDetails() {
    this.authService.getCurrentAuthUser().subscribe(
      res => {
        this.currentUser = res.attributes.email;
        const userEmailId = res.attributes.email;
        this.periodicEngagement = {
          groupName: 'adHoc',
          item: 'periodic',
          ratedBy: userEmailId,
          submit: true
        };
      },
      error => {
        this.toaster.error(
          'something went wrong  while getting the account details.'
        );
      }
    );
  }

  // edit engagement
  editEngagement() {
    let payload = {
      offerId: this.candidateData.offerId
    };
    this.offerService.GetOfferDeatilsByOferID(payload).subscribe(
      res => {
        console.log(res)
        const candidateData = res.body;
        this.getOfferData(candidateData);
      },
      err => {
        this.toaster.error('Somthing went wrong');
      }
    );
  }

  //Nikhil code
  //getDisableEngagmentActivity depending on the users disable the add-extra engagment from the portal
  getDisableEngagmentActivity() {
    if (this.authService.getAuthRole() === 'DEV') {
      return false;
    } else {
      return true;
    }
  }

  readMore(index) {
    this.moreDetailsToggle[index] = !this.moreDetailsToggle[index];
  }

  // get template and combine data
  private getTemplate(callback?) {
    const payload = {
      id: this.templateId
    }
    this.loader.start()
    this.engagementTemplateService.getTemplate(payload).subscribe((res) => {
      this.stages = res.body.template.stages;
      if (this.stages.length > 0) {
        this.combineData(res.body);
      }
      if (callback) {
        callback();
      }
      this.loader.stop();
    }, error => {
      this.loader.stop();
      this.toaster.error("Error getting template");
    });
  }
  //combine date for template
  private combineData(data) {
    this.stages.forEach((stage => {
      data.stagesAndActivites.forEach((stAc) => {
        if (stage.id === stAc._id) {
          stage._data = stAc;
        }
        stage.activities.forEach((activity) => {
          if (activity.id === stAc._id) {
            activity._data = stAc;
          }
        });
      });
    }));
  }
  // getting names for stages and tasks
  public getNamesFromCodesForStagesAndTasks(code) {
    let nameToReturn = "";
    this.stageMaster.forEach((stage) => {
      if (stage.code === code) {
        nameToReturn = stage.name;
      }
    })
    return nameToReturn;
  }

  private getStagesMaster(callback?) {
    const payload = { type: 'stage' };
    this.stageService.getStages(payload).subscribe((res) => {
      this.stageMaster = res.body;
      console.log('stageMaster >>', this.stageMaster)
      if (callback) { callback() }
    }, err => {
      this.toaster.error("Error getting stages")
    })
  }

  // eng to master mapper
  // private mapEngagementObjectToMaster(callback) {
  //   let stages = this.sharedService.mapMasterToStages(this.stages, this.engagementDataForCandidate);
  //   let activities = [];
  //   let activitiesWithoutData = []
  //   stages.forEach((stage) => {
  //     stage.activities.forEach((act) => {
  //       if (!act._data.code) {
  //         activitiesWithoutData.push(act);
  //       }
  //       else {
  //         activities.push(act);
  //       }
  //     })
  //   })
  //   activitiesWithoutData.forEach((_act, index) => {
  //     const payload = { type: 'activity', code: _act.code }
  //     this.stageService.getStages(payload).subscribe((res) => {
  //       _act._data = res.body[0];
  //       if (index === activitiesWithoutData.length - 1) {
  //         let _activities = activities.concat(activitiesWithoutData);
  //         // this.mergeActivites(activitiesWithoutData, activities);
  //         this.activites = _activities;
  //         console.log(this.activites);
  //         callback();
  //       }
  //     })
  //   })
  // }

  public mapEngagementObjectToMaster(callback) {
    let stages = this.sharedService.mapMasterToStages(this.stages, this.engagementDataForCandidate);
    console.log('stages >>', stages);
    stages.forEach(stage => {
      this.stagesList.push(stage);
    })
    //  this.stagesList = stages;
    //  console.log('stagesList >>',this.stagesList);
    let activities = [];
    let activitiesWithoutData = []
    stages.forEach((stage) => {
      stage.activities.forEach((act) => {
        if (!act._data.code) {
          activitiesWithoutData.push(act);
        }
        else {
          activities.push(act);
        }
      })
    })
    if (activitiesWithoutData.length === 0 || activitiesWithoutData === undefined) {
      this.activites = activities;
    } else {
      activitiesWithoutData.forEach((_act, index) => {
        const payload = { type: 'activity', code: _act.code }
        this.stageService.getStages(payload).subscribe((res) => {
          _act._data = res.body[0];
          if (index === activitiesWithoutData.length - 1) {
            let _activities = activities.concat(activitiesWithoutData);
            // this.mergeActivites(activitiesWithoutData, activities);
            this.activites = _activities;
            callback();
          }
        })
      })
    }
  }

  mergeActivites(actWithouData, actWithData) {
    // console.log
    actWithData.forEach(_with => {
      actWithouData.forEach((_without) => {
        if (_with.code === _without.code) {
          _with._data = _without._data;
        }
      })
    });
    return (actWithouData);
  }

  getMarkAsCompleteForDigital(code) {
    let result = true;
    this.activites.forEach((act) => {
      if (act.code === code) {
        if (act._data.digital) {
          result = false;
        }
      }
    })
    return result;
  }

  //open static modal with inputs
  public openRatingModalForViewing(index) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg',
      backdrop: 'static',
      centered: true
    });
    // opening from feedback
    modalRef.componentInstance.staticModalData = { offerId: this.candidateData.offerId, index };

  }




}
