import { EngagementService } from "./../../../services/engagement/engagement.service";
// tslint:disable-next-line:max-line-length
import { CandidateEngageModalComponent } from "src/app/pages/start-engagement/components/candidate-engage-modal/candidate-engage-modal.component";
import { SharedService } from "src/app/services/shared/shared.service";
import { AuthService } from "../../../services/auth/auth.service";
import {
  Component,
  OnChanges,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { NgbModal, NgbDateParserFormatter, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { OfferService } from "src/app/services/offer/offer.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as _ from "lodash";
import * as moment from "moment";
import { MasterTypes } from "src/app-settings/master_types";
import { NgbDateFRParserFormatter } from '../offer-form/dateformat';
@Component({
  selector: "app-offers-table",
  templateUrl: "./offers-table.component.html",
  styleUrls: ["./offers-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class OffersTableComponent implements OnChanges {
  // tslint:disable-next-line:no-input-rename
  searchBusniessUnit: any;
  divisionSearch: any;
  @Input("offers") offers: any[];
  @Input("disableNext") disableNext: any;
  @Input("totalOffers") totalOffers: any;
  @Output() offerids = new EventEmitter<any>();
  @Output() pageNumber = new EventEmitter<any>();
  @Output() filterDataObj = new EventEmitter<any>();
  @Output() sortDataObj = new EventEmitter<any>();
  // @ViewChild('p1') p1: NgbPopover;
  public isCollapsed = false;
  orgOffers: any[] = [];
  preProcessOffer: any[] = [];
  disableButton: any;
  disableNextButton: any;
  disablePrevButton: any;

  pageNum: any;
  pageArray: any[] = [
    {
      pageNumber: "1",
      lastId: null,
      active: false
    },
    {
      pageNumber: "2",
      lastId: "",
      active: false
    }
  ];
  private firstPage: any;
  private candidateId: any;
  filterSearch: any;
  header: any;
  candidateName: any;
  searchOfferId: any;
  departmentSearch: any;
  offerStatusSearch: any;
  noticePeriodSearch: any;
  expiryDateSearch: any;
  joiningDateSearch: any;
  offerDateSearch: any;
  ascending: boolean;
  dateHeader: any;
  toggleSort: boolean = true;
  filterobj = {
    candidateName: "",
    date: "",
    offerId: "",
    businessUnit: "",
    division: "",
    department: "",
    noticePeriod: "",
    joiningDate: "",
    expiryDate: "",
    offerStatus: ""
  };
  sortObj = {
    key: "",
    order: ""
  };
  businessMasterDataArray: any;
  divisonMasterDataArray: any[] = [];
  departmentMasterDataArray: any[] = [];
  masterDataOfferStatus: any[] = [];
  offerActivityStatus: any[] = [];
  allMasterOfferStatus: any[] = [];
  constructor(
    private modalService: NgbModal,
    public sharedService: SharedService,
    private router: Router,
    public offerService: OfferService,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private engagementService: EngagementService,
    private auth: AuthService
  ) {
    this.disableButton = false;
    // this.ascending = false
    this.disablePrevButton = true;
    this.pageNum = "1";
    this.firstPage = true;
    this.getMasterData();
  }
  name = "World";

  ngOnChanges(changes: any): void {
    this.offers.forEach(offer=>{
      console.log(offer);
    })

    if (changes && changes.offers && changes.offers.currentValue) {
      console.log('preProcessOffer >>',this.preProcessOffer)
      this.preProcessOffer = changes.offers.currentValue;
      this.disableNextButton = this.preProcessOffer.length < 10 ? true : false;
      if (this.firstPage) {
        if (this.preProcessOffer.length >= 10) {
          this.pageArray = [
            {
              pageNumber: "1",
              lastId: null,
              active: false
            },
            {
              pageNumber: "2",
              lastId: "",
              active: false
            }
          ];
        } else {
          this.pageArray = [
            {
              pageNumber: "1",
              lastId: null,
              active: false
            }
          ];
        }
        if (
          this.preProcessOffer.length > 0 &&
          this.preProcessOffer.length >= 10
        ) {
          this.firstPage = false;
          this.pageArray[1].lastId = this.preProcessOffer[
            this.preProcessOffer.length - 1
          ]._id;
        }
        if (this.totalOffers > 10) {
          for (let i = (this.pageArray.length) + 1; i <= parseInt((this.totalOffers / 10).toFixed()); i++) {
            let page = {
              pageNumber: i.toString(),
              lastId: null,
              active: false
            }
            this.pageArray.push(page)
          }
        }

      }
    }
    if (changes && changes.disableNext && changes.disableNext.currentValue) {
      this.disableNextButton = changes.disableNext.currentValue;
    }
    if (changes && changes.totalOffers && changes.totalOffers.currentValue) {
      if (this.totalOffers > 10) {
        for (let i = (this.pageArray.length) + 1; i <= parseInt((this.totalOffers / 10).toFixed()); i++) {
          let page = {
            pageNumber: i.toString(),
            lastId: null,
            active: false
          }
          this.pageArray.push(page)
        }
      }
    }
  }

  ngOnInit() {
    let role = this.auth.getAuthRole();
    if (role === "BU") {
      this.disableButton = true;
    }
    this.getUserDeatails();
  }

  // get user Details from local
  getUserDeatails() {
    const userInfo: any = JSON.parse(localStorage.getItem("_user_dim"));
    if (userInfo.role === "BU") {
      this.searchBusniessUnit = userInfo.businessUnit;
      if (userInfo.division) {
        this.divisionSearch = userInfo.division;
      }
    }
  }
  getMasterData() {
    this.sharedService.getMasterAccToType(MasterTypes.orgEntities).subscribe(
      (res: any) => {
        let orgEntities = res.body[0].data;
        orgEntities.forEach(element => {
          if (element.code === "BUSINESSUNIT") {
            this.businessMasterDataArray = element.data.items;
          }
          if (element.code === "DIVISION") {
            element.data.items.forEach(divElement => {
              this.divisonMasterDataArray.push(divElement);
            });
          }
          if (element.code === "DEPARTMENT") {
            element.data.items.forEach(divElement => {
              this.departmentMasterDataArray.push(divElement);
            });
          }
        });
      },
      err => {
        this.toaster.error("something went wrong.");
      }
    );
    this.sharedService.getMasterAccToType(MasterTypes.offerStatus).subscribe(
      (res: any) => {
        this.masterDataOfferStatus = res.body[0].data[0].data.items;
      },
      err => {
        this.toaster.error("something went wrong.");
      }
    );
    this.sharedService.getMasterAccToType(MasterTypes.offerActivity).subscribe(
      (res: any) => {
        this.offerActivityStatus = res.body[0].data[0].data.items;
      },
      err => {
        this.toaster.error("something went wrong.");
      }
    );
    this.allMasterOfferStatus = this.masterDataOfferStatus.concat(
      this.offerActivityStatus
    );
  }

  toggleWithGreeting(popover, event) {
    if (event) event.stopPropagation();
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }
  openEngagementmodal(candidateData, offerId, event?) {
    console.log(candidateData);
    if (event) event.stopPropagation();
    this.engagementService.getEngagementData(offerId).subscribe(res => {
      if (res.body) {
        this.router.navigate(["engagement"]);
      } else {
        const modalRef = this.modalService.open(CandidateEngageModalComponent, {
          size: "lg",
          backdrop: "static",
          centered: true
        });
        modalRef.componentInstance.candidateDataFromModal = candidateData;
        modalRef.componentInstance.action = "start"; // this is to tell the modal whether it is edit or start engagement
      }
    });
  }

  // view offer details
  viewOfferDeatils(id, event?) {
    if (event) event.stopPropagation();
    this.router.navigate(["offer", id]);
  }
  // send offer function
  sendOffer(offerId, statusCode) {
    this.offerids.emit(offerId);
  }
  getDisableStatus(activity) {
    return activity.some(element => element.statusCode === "ST");
  }

  //changes in latest version - Engagement can start even if offer has not been sent
  getDisableEngagement(activity) {
    if(this.auth.getAuthRole() === 'DEV'){
      return false;
    } else {
      return true;
    }
    // console.log(this.auth.getAuthRole());
    // return true;
    // if (activity.some(element => element.statusCode === "ST")) {
    //   return false
    // } else {
    //   return true
    //     ;
    // }
  }

  //Nikhil code
  checkForRoleToDisableButton(){

  }

  //pagination

  changePageNumber(action) {
    let pageNumber = parseInt(this.pageNum);
    let pageObj = {
      pageNumber: null,
      active: false,
      lastId: null
    };
    if (action === "previous") {
      this.pageNum = (--pageNumber).toString();
      if (!this.checkIfExists(this.pageNum, this.pageArray)) {
        this.pageArray.splice(0, 1);
        pageObj.pageNumber = this.pageNum;
        pageObj.lastId = this.preProcessOffer[
          this.preProcessOffer.length - 1
        ]._id;
        this.pageArray.splice(
          this.pageArray.findIndex(x => x.pageNumber === this.pageNum) + 1,
          1
        );
      }
      if (this.pageNum !== "1") {
        this.pageNumber.emit(
          this.pageArray[
          this.pageArray.findIndex(x => x.pageNumber === this.pageNum)
          ]
        );
      } else {
        this.pageNumber.emit(null);
      }
      this.disablePrevButton = this.pageNum === "1" ? true : false;
    } else if (action === "next") {
      this.pageNum = (++pageNumber).toString();
      if (!this.checkIfExists(this.pageNum, this.pageArray)) {
        pageObj.pageNumber = this.pageNum;
        pageObj.lastId = this.preProcessOffer[
          this.preProcessOffer.length - 1
        ]._id;
        this.pageArray.push(pageObj);
      } else {
        this.pageArray[
          this.pageArray.findIndex(x => x.pageNumber === this.pageNum)
        ].lastId = this.preProcessOffer[this.preProcessOffer.length - 1]._id;
      }
      if (this.pageNum !== "1") {
        this.pageNumber.emit(
          this.pageArray[
          this.pageArray.findIndex(x => x.pageNumber === this.pageNum)
          ]
        );
      } else {
        this.pageNumber.emit(null);
      }
      this.disablePrevButton = this.pageNum === "1" ? true : false;
    } else {
      if (action.pageNumber !== this.pageNum) {
        this.pageNum = action.pageNumber;
      }
      if (this.pageNum !== "1") {
        this.pageNumber.emit(
          this.pageArray[
          this.pageArray.findIndex(x => x.pageNumber === this.pageNum)
          ]
        );
      } else {
        this.pageNumber.emit(null);
      }
      this.disablePrevButton = this.pageNum === "1" ? true : false;
    }
  }

  //check if object exists

  checkIfExists(pageNumber, list) {
    let exists = null;
    list.forEach(element => {
      _.forOwn(element, (value, key) => {
        if (value === pageNumber) {
          exists = true;
        }
      });
    });
    if (exists) {
      return true;
    } else {
      return false;
    }
  }

  serachByValue() {
    this.firstPage = true;
    if (this.candidateName) {
      this.filterobj.candidateName = this.candidateName;
    } else {
      this.filterobj.candidateName = "";
    }
    if (this.offerDateSearch) {
      const tempDate = this.sharedService.convertObjectToDateStringTOForamt(this.offerDateSearch)
      this.filterobj.date = moment(tempDate).toISOString();
    } else {
      this.filterobj.date = "";
    }
    if (this.searchOfferId) {
      this.filterobj.offerId = this.searchOfferId;
    } else {
      this.filterobj.offerId = "";
    }
    if (this.searchBusniessUnit) {
      this.filterobj.businessUnit = this.searchBusniessUnit;
    } else {
      this.filterobj.businessUnit = "";
    }
    if (this.divisionSearch) {
      this.filterobj.division = this.divisionSearch;
    } else {
      this.filterobj.division = "";
    }
    if (this.departmentSearch) {
      this.filterobj.department = this.departmentSearch;
    } else {
      this.filterobj.department = "";
    }
    if (this.noticePeriodSearch) {
      this.filterobj.noticePeriod = this.noticePeriodSearch;
    } else {
      this.filterobj.noticePeriod = "";
    }
    if (this.joiningDateSearch) {
      const joiningDateSearch = this.sharedService.convertObjectToDateStringTOForamt(this.joiningDateSearch)
      this.filterobj.joiningDate = moment(joiningDateSearch).toISOString();
    } else {
      this.filterobj.joiningDate = "";
    }
    if (this.expiryDateSearch) {
      const expiryDateSearch = this.sharedService.convertObjectToDateStringTOForamt(this.expiryDateSearch)
      this.filterobj.expiryDate = moment(expiryDateSearch).toISOString();
    } else {
      this.filterobj.expiryDate = "";
    }
    if (this.offerStatusSearch) {
      this.filterobj.offerStatus = this.offerStatusSearch;
    } else {
      this.filterobj.offerStatus = "";
    }
    this.filterDataObj.emit(this.filterobj);
  }

  sortOnLabel(header) {
    this.firstPage = true;
    this.toggleSort = !this.toggleSort;
    this.dateHeader = header;
    if (this.toggleSort === false) {
      this.sortObj.key = header;
      this.sortObj.order = "desc";
      this.sortDataObj.emit(this.sortObj);
    } else {
      this.sortObj.key = header;
      this.sortObj.order = "asc";
      this.sortDataObj.emit(this.sortObj);
    }
  }

  handleRightClick(event) {
    if (event) {
      event.preventDefault();
      // this.toggleWithGreeting(this.p1);
      console.log(event)
    }

  }
}
