import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements OnInit {
  @Input("offers") offers: any[];
  sortName: string | null = null;
  sortValue: string | null = null;
  listOfData: any[] = [];
  listOfDisplayData: any[] = [];
  searchValue = '';
  RecuriterSearchValue = '';

  constructor(
    public userService: UserService,
  ) { }

  ngOnChanges(changes: any): void {
    console.log(this.offers);
    this.listOfData = this.offers;
    this.listOfDisplayData = this.listOfData;
    console.log(this.listOfDisplayData);
    console.log(this.listOfData);
    // this.offers.forEach(offer=>{
    //   // console.log(offer);
    // })
  }
  ngOnInit() {
    console.log(this.offers);
    this.userService.getUser();
  }

  resetFilters(): void {
    this.searchValue = '';
    this.RecuriterSearchValue = '';
    this.sortName = '';
    this.sortValue = '';
    this.sortAll();
    this.search();
  }

  reset(): void {
    this.searchValue = '';
    this.RecuriterSearchValue = '';
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    console.log(sort);
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.sortAll();
  }

  sortAll(): void {
    const data = this.listOfData;
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
    } else {
      this.listOfDisplayData = data;
    }
  }

  search() {
    this.RecuriterSearchValue = '';
    const filterFunc = (item: {
      RevisedDOJ: string,
      TotalActivities: string,
      activityCompleted: string,
      activityName: string,
      candidateName: string,
      engagementSummary: string,
      engagmentPeriod: string,
      initialDOJ: string,
      interventionName: string,
      nextActivityDate: string,
      offer_id: string,
      ratingData: string,
      recruiter: string,
      remainingJoiningDate: string,
      riskArea: string,
    }) => {
      console.log(item);
      return (
        item.candidateName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      );
    };
    const data = this.listOfData.filter(
      (item: {
        RevisedDOJ: string,
        TotalActivities: string,
        activityCompleted: string,
        activityName: string,
        candidateName: string,
        engagementSummary: string,
        engagmentPeriod: string,
        initialDOJ: string,
        interventionName: string,
        nextActivityDate: string,
        offer_id: string,
        ratingData: string,
        recruiter: string,
        remainingJoiningDate: string,
        riskArea: string,
      }) => filterFunc(item)
    );
    this.listOfDisplayData = data;
  }

  searchRecuriter() {
    this.searchValue = '';
    const filterFunc = (item: {
      RevisedDOJ: string,
        TotalActivities: string,
        activityCompleted: string,
        activityName: string,
        candidateName: string,
        engagementSummary: string,
        engagmentPeriod: string,
        initialDOJ: string,
        interventionName: string,
        nextActivityDate: string,
        offer_id: string,
        ratingData: string,
        recruiter: string,
        remainingJoiningDate: string,
        riskArea: string,
    }) => {
      return (
        item.recruiter.toLowerCase().indexOf(this.RecuriterSearchValue.toLowerCase()) !== -1
      );
    };
    const data = this.listOfData.filter(
      (item: {
        RevisedDOJ: string,
        TotalActivities: string,
        activityCompleted: string,
        activityName: string,
        candidateName: string,
        engagementSummary: string,
        engagmentPeriod: string,
        initialDOJ: string,
        interventionName: string,
        nextActivityDate: string,
        offer_id: string,
        ratingData: string,
        recruiter: string,
        remainingJoiningDate: string,
        riskArea: string,
      }) => filterFunc(item)
    );
    this.listOfDisplayData = data;
  }

}
