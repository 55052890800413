import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-show-description',
  templateUrl: './show-description.component.html',
  styleUrls: ['./show-description.component.scss']
})
export class ShowDescriptionComponent implements OnInit {

  constructor(   public activeModal:NgbActiveModal) { }
  @Input() public feedbackModalData: any;

  ngOnInit() {
    console.log('feedbackModalData >>>',this.feedbackModalData);
  }
  closeModal() {
    // this.feedbackFormClosed.emit(status);
    this.activeModal.close();
  }


}
