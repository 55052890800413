import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-task-modal',
  templateUrl: './create-task-modal.component.html',
  styleUrls: ['./create-task-modal.component.scss']
})
export class CreateTaskModalComponent implements OnInit {

  name: any;
  description: any;
  id: any;
  digital: boolean;
  activityType:any;

  innteractionTypeList:any
  interactionType:any;
  isInterventionType:boolean=false;
  @Input("data") data: any;


  constructor(public activeModal: NgbActiveModal, private toaster: ToastrService) { this.name = "", this.description = "", this.id = "", this.digital = false; this.activityType='' ,this.interactionType=''}

  ngOnInit() {
    if (this.data) {
      this.id = this.data.id;
      this.name = this.data.name;
      this.description = this.data.description;
      this.digital = this.data.digital;
      this.activityType = this.data.activityType;
      this.interactionType = this.data.interactionType;
    }


    this.innteractionTypeList = [
      'Email',
      '1 on 1 y',
      'Whatsapp Msg',
      'HR to connect ',
      'Senior leader connect',
      'Share Infographics ',
      'Hiring Manager Connect ',
      'Share company Insights',
      'Provide Benefits of joining the company ',
      'Option to add an Intervention '
    ];
  }

  dismissModal(data?) {
    this.activeModal.close(data);
    console.log(data)
  }

  saveData() {
    if (this.name) {
      this.dismissModal({ update: this.data.id ? true : false, data: { id: this.id, name: this.name, description: this.description, digital: this.digital, activityType:this.activityType,interactionType:this.interactionType } });
    }
    else {
      this.toaster.error("Please fill in the name");
    }
  }


  onChangeActivityType(){
    if(this.activityType === 'CHACT'){
      this.isInterventionType = true;
    }else{
      this.isInterventionType = false;

    }
  }


}
