import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared/shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy, OnChanges {
  chatMsg: any;
  @Input() offerId: any;
  @Input() candidateId: any;
  @Input() candidateData:any;
  getAllMsgsData: any[] = [];
  id: NodeJS.Timer;
  userEmail: any;
  tags: any[];
  constructor(
    public chatservice: ChatService,
    private toaster: ToastrService,
    public shared: SharedService,
    public userService: UserService,
    private auth: AuthService
  ) {
    this.userService.getUser();
    this.getTags();
  }

  ngOnInit() {
    // this.getMsgsData();
    // this.scrollToBottom();
    this.id = setInterval(() => {
      this.getMsgsData();
    }, 30000);
    this.getUser();
    this.chatMsg = '';
  }
  ngOnChanges(changes: any): void {
    if (changes && changes.offerId && changes.offerId.currentValue) {
      this.offerId = changes.offerId.currentValue;
      this.getMsgsData();
    }
  }
  getUser() {
    this.auth.getCurrentAuthUser().subscribe(
      res => {
        this.userEmail = res.attributes.email;
      },
      err => { }
    );
  }

  // send  msg to candidate
  sendMessage() {
    if (this.candidateData.candidateName) {
      let name = this.userService.getUserName(this.userEmail);
      const payload = {
        offerId: this.offerId,
        chatDetails: {
          dateTime: new Date().toISOString(),
          createdBy: name,
          role: 'recruiter',
          message: this.chatMsg,
          candidateId: this.candidateData.candidateName
          // candidateId: this.candidateId
        }
      };
      this.chatservice.createChat(payload).subscribe(
        res => {
          if (res.msg) {
            this.chatMsg = '';

            this.getMsgsData();
          }
        },
        err => {
          this.toaster.error('Something went wrong');
        }
      );
    } else {
      this.toaster.error(
        'Candidate did not signup in the HireLopp Application'
      );
    }
  }
  // get all msg data
  getMsgsData() {
    this.chatservice.getChat(this.offerId).subscribe(
      res => {
        if (res.body) {
          this.getAllMsgsData = res.body;
          this.scrollToBottom();
          // this.chatMsg = "";
        }
      },
      err => {
        this.toaster.error('something went wrong');
      }
    );
  }

  scrollToBottom() {
    setTimeout(() => {
      const height = document.getElementById('chat-container').scrollHeight;
      document.getElementById('chat-container').scrollBy(0, height);
    }, 0);
  }
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  getTags() {
    this.shared.getMasterAccToType('tags').subscribe(
      res => {
        this.tags = res.body[0].data[0].data.items;
      },
      error => {
        this.toaster.error('Something went wrong');
      }
    );
  }

  // add tag to text chat message
  addTagToText(index) {
    let tag = this.tags[index].title + ' : ';
    this.chatMsg = this.chatMsg.concat(` ${tag}`);
  }
}
