import { Injectable } from "@angular/core";
import { from, BehaviorSubject, Observable } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: "root"
})
export class UserService {
  apiName: string;
  path: string;
  orgHandle: string;
  userData: any[] = [];
  // userId = new BehaviorSubject(null);
  constructor(private initRequests: InitRequests,
    private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.user;
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  public getUsers(projection) {
    const request = this.initRequests.initGetUser({
      _action: "GET_ALL",
      orgHandle: this.orgHandle,
      queryBody: {
        projection
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public getUsersById(id) {
    const request = this.initRequests.initGetUserById({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        emailId: id
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public createUser(InsertBody) {
    const request = this.initRequests.initCreateUser({
      _action: "CREATE",
      orgHandle: this.orgHandle,
      insertBody: InsertBody
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  public updateUser(updatedData) {
    const request = this.initRequests.initUpdateUser({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: updatedData
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  getUser(callback?) {
    const projection = {
      emailId: "1",
      _id: "0",
      displayName: "1"
    };
    this.getUsers(projection).subscribe(res => {
      this.userData = res.body;
      if (callback) {
        callback(this.userData);
      }
    });
  }

  // get leble based on type of engagement code return the value of title
  getLabelFromMasterData(masterDataPath, key, labelKey, code) {
    let label = "";
    masterDataPath.forEach(element => {
      if (element[key] === code) {
        label = element[labelKey];
      }
    });
    return label;
  }

  getUserName(code) {
    return this.getLabelFromMasterData(
      this.userData,
      "emailId",
      "displayName",
      code
    );
  }

  getUserDetails(emailId) {
    const request = this.initRequests.initGetUserDetails({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        emailId: emailId
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

  updateUserDetails(email, updateBody) {
    const request = this.initRequests.initUpdateUserDetails({
      _action: "UPDATE",
      orgHandle: this.orgHandle,
      updateBody: {
        emailId: email,
        body: updateBody
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }

}
