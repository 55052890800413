import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { API } from "aws-amplify";
import { apis } from "../../../app-settings/aws-config";
import { InitRequests } from "../../models/requestModel";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class ChatService {
  private apiName: string;
  private path: string;
  public engFeedbackPath: string;
  orgHandle: string;
  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.apiName = apis.organizationService.organization.name;
    this.path = apis.organizationService.organization.endpoints.chat;
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  // create chat
  public createChat(data) {
    const request = this.initRequests.initCreateChat({
      _action: "SEND",
      orgHandle: this.orgHandle,
      insertBody: {
        offerId: data.offerId,
        chatDetails: data.chatDetails
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
  // Get chat data
  public getChat(data) {
    const request = this.initRequests.initGetChat({
      _action: "GET",
      orgHandle: this.orgHandle,
      queryBody: {
        offerId: data
      }
    });
    return from(this.auth.post(this.apiName, this.path, request));
  }
}
