import { Component, OnInit ,Input, Output,EventEmitter } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EngagementService } from 'src/app/services/engagement/engagement.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-digital-activity-modal',
  templateUrl: './digital-activity-modal.component.html',
  styleUrls: ['./digital-activity-modal.component.scss']
})
export class DigitalActivityModalComponent implements OnInit {

    public feedbackType:any;
    public feedbackMaster:any;

    @Input() public candidateDataFromModal;
    @Input() public feedbackModalData: any;
    @Output() submitFeedbackFormData = new EventEmitter<any>();
    @Output() feedbackFormClosed = new EventEmitter<any>();
    @Input() staticModalData: any;


    constructor( public activeModal:NgbActiveModal,public engService:EngagementService ,public loader:NgxUiLoaderService,
      private toastr: ToastrService) { }


  feedbackPayload: { offerId: any; groupName: any; itemCode: any; emailId: any; date: any;feedbackType:any, feedbackData: {}, eafeedbackData: {} };
  ngOnInit() {
    this.getFeedbackMasterConfig()
  }

  // dismissModal
  dismissModal() {
    this.activeModal.close();
  }

    //create feedback payload for client activity
    public submitEngagementFeedback() {

      this.feedbackPayload = {
        offerId:this.feedbackModalData.offerId,
        groupName:this.feedbackModalData.item,
        itemCode:this.feedbackModalData.data,
        emailId:this.feedbackModalData.assigned,
        date: new Date().toISOString(),
        feedbackType:'DIGFEED',
        feedbackData: [],
        eafeedbackData: {}
      };
      // console.log(this.feedbackPayload);

        this.submitFeedbackData(this.feedbackPayload);

    }

   // submit feedback payload
   public submitFeedbackData(feedback) {
    console.log('clientFeedback >>',this.feedbackModalData);
    let candidateFullName = this.candidateDataFromModal.candidateData.firstName+' '+this.candidateDataFromModal.candidateData.lastName;
    let recuiterEmailId = this.candidateDataFromModal.organizationData.recruiter;
    this.loader.start();
    let orgHandle = localStorage.getItem("orgHandle");
    let userName = JSON.parse(localStorage.getItem("_user_dim"));
    let engagementMainData = {
      engFedback: feedback,
      candidateName: candidateFullName,
      RecuriterId: recuiterEmailId,
      orgHandle: orgHandle,
      activityName: this.feedbackModalData.activityName,
      userName: userName.name
    }
    console.log("this is >>>", engagementMainData);

    this.engService.submitFeedback(engagementMainData).subscribe(
      res => {
        this.loader.stop();
        this.toastr.success('Feedback submitted sucessfully');
        // this.dismissModal();
        this.submitdata();
      },
      error => {
        this.loader.stop();
        this.toastr.error('something went wrong.');
      }
    );
  }


  submitdata() {
    this.submitFeedbackFormData.emit(this.feedbackModalData.value);
    this.activeModal.close();
  }



// getFeedbackData
getFeedbackData(offerData:any) {
  this.engService
    .getEngagementFeedbackTable(offerData.offerId)
    .subscribe(
      res => {
        console.log('getFeedbackData >>>',res);
        this.feedbackMaster = res.body[offerData.index].feedbackData;
        console.log('feedbackMaster >>>',this.feedbackMaster);
      }, error => {
        this.toastr.error("Something went wrong while getting data")
      })
}

 // getFeedbackMasterConfig
 getFeedbackMasterConfig() {
  this.engService.getEngFeedbackMaster().subscribe((res) => {
    this.feedbackMaster = res.body;

    console.log('feedbackMaster >>',this.feedbackMaster);
    console.log('staticModalData >>',this.staticModalData);

    if (this.staticModalData) {
      this.getFeedbackData(this.staticModalData);
    }
  }, error => {
    this.toastr.error("Error getting data");
  })

}


}
