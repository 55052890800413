import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EngagementService } from 'src/app/services/engagement/engagement.service';
import { ToastrService } from 'ngx-toastr';
import { fstat } from 'fs';


@Component({
  selector: 'app-user-activity-modal',
  templateUrl: './user-activity-modal.component.html',
  styleUrls: ['./user-activity-modal.component.scss']
})
export class UserActivityModalComponent implements OnInit {

  public clientInterDetails:any;
  public clientAction:any;
  public throwError:boolean =false;

  public validateInput:any;
  public feedbackType:any;
  public feedbackMaster:any;

  candidateFullName: any;
  recuiterEmailId: any;

  @Input() public feedbackModalData: any;
  @Input() public candidateDataFromModal;
  @Output() submitFeedbackFormData = new EventEmitter<any>();
  @Output() feedbackFormClosed = new EventEmitter<any>();
  @Input() staticModalData: any;


  constructor(
    public activeModal:NgbActiveModal,public engService:EngagementService ,public loader:NgxUiLoaderService,
    private toastr: ToastrService
    ) { }

  feedbackPayload: { offerId: any; groupName: any; itemCode: any; emailId: any; date: any;feedbackType:any, feedbackData: {} };

  ngOnInit() {

    console.log('feedbackModalData >>',this.feedbackModalData)
    this.getFeedbackMasterConfig()

    this.candidateFullName = this.candidateDataFromModal.candidateData.firstName+' '+this.candidateDataFromModal.candidateData.lastName;
    this.recuiterEmailId = this.candidateDataFromModal.organizationData.recruiter;
  }


  closeModal() {
    // this.feedbackFormClosed.emit(status);
    this.activeModal.close();
  }


   //create feedback payload for client activity
   public submitEngagementFeedback() {

    this.feedbackPayload = {
      offerId:this.feedbackModalData.offerId,
      groupName:this.feedbackModalData.item,
      itemCode:this.feedbackModalData.data,
      emailId:this.feedbackModalData.assigned,
      date: new Date().toISOString(),
      feedbackType:'CLIFEED',
      feedbackData: [{clientInterDetails:this.clientInterDetails,clientAction:this.clientAction, itemCode:this.feedbackModalData.item,}]
    };
    console.log(this.feedbackPayload);

    if (this.validateData()) {
      this.submitFeedbackData(this.feedbackPayload);
    }else{
      // this.toastr.error('all fields are required!');
      this.throwError = true;
      this.validateInput='2px solid red';

    }
  }


  // validation of clien tdata
  public validateData(){
    if(this.clientInterDetails && this.clientInterDetails.length>0){
      return true;
    }
    else{
      return false;
    }
  }

  // submit feedback payload
  public submitFeedbackData(feedback) {
    console.log('clientFeedback >>',feedback);
    this.loader.start();
    let orgHandle = localStorage.getItem("orgHandle");
    let userName = JSON.parse(localStorage.getItem("_user_dim"));
    let engagementMainData = {
      engFedback: feedback,
      candidateName: this.candidateFullName,
      RecuriterId: this.recuiterEmailId,
      orgHandle: orgHandle,
      activityName: this.feedbackModalData.activityName,
      userName: userName.name
    }
    console.log('feedbackDatas', engagementMainData);
    this.engService.submitFeedback(engagementMainData).subscribe(
      res => {
        this.loader.stop();
        this.toastr.success('Feedback submitted sucessfully');
        // this.dismissModal();
        this.submitdata();
      },
      error => {
        this.loader.stop();
        this.toastr.error('something went wrong.');
      }
    );
  }

  submitdata() {
    this.submitFeedbackFormData.emit(this.feedbackModalData.value);
    this.activeModal.close();
  }


  // getFeedbackData
  getFeedbackData(offerData:any) {
    console.log(offerData);
    this.engService
      .getEngagementFeedbackTable(offerData.offerId)
      .subscribe(
        res => {
          console.log('getFeedbackData >>>',res);
          this.feedbackMaster = res.body[offerData.index].feedbackData;
          console.log('feedbackMaster >>>',this.feedbackMaster);
        }, error => {
          this.toastr.error("Something went wrong while getting data")
        })
  }


  // getFeedbackMasterConfig
  getFeedbackMasterConfig() {
    this.engService.getEngFeedbackMaster().subscribe((res) => {
      this.feedbackMaster = res.body;

      console.log('feedbackMaster >>',this.feedbackMaster);
      console.log('staticModalData >>',this.staticModalData);

      if (this.staticModalData) {
        this.getFeedbackData(this.staticModalData);
      }
    }, error => {
      this.toastr.error("Error getting data");
    })

  }


}
