import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import { MasterTypes } from 'src/app-settings/master_types';
import { UserService } from 'src/app/services/user/user.service';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() submitedData = new EventEmitter<any>();

  roles: any;
  users: any;
  constructor(
    private sharedService: SharedService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getAllRoles();
    const projection = {
      emailId: '1',
      _id: '0',
      displayName: '1'
    };
    this.getUser(projection);
  }

  getAllRoles() {
    this.sharedService
      .getMasterAccToType(MasterTypes.orgRoles)
      .subscribe(res => {
        this.roles = res.body[0].data[0].data.items;
      });
  }

  getUser(projection) {
    this.userService.getUsers(projection).subscribe(res => {
      this.users = res.body;
    });
  }

  submitData() {
    this.submitedData.emit(this.form.value);
  }
}
