import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { EngagementService } from "src/app/services/engagement/engagement.service";
import { OfferService } from "./../../services/offer/offer.service";
import { UserService } from 'src/app/services/user/user.service';
import { ExcelService } from './../../services/excelExport/excel-services.service';

@Component({
  selector: 'app-engagement-tracker',
  templateUrl: './engagement-tracker.component.html',
  styleUrls: ['./engagement-tracker.component.scss']
})
export class EngagementTrackerComponent implements OnInit {
  currentUser: any;
  authRole: string;
  filterObj = {
    candidateName: "",
    date: "",
    offerId: "",
    businessUnit: "",
    division: "",
    department: "",
    noticePeriod: "",
    joiningDate: "",
    expiryDate: "",
    offerStatus: ""
  };
  sortObj = {};
  dashboardData: any;
  engagmentData: any;
  preProcessOffer: any[] = [];
  engagementOffer: any[] = [];
  orgHandle: any;

  constructor(
    private location: Location,
    private loader: NgxUiLoaderService,
    private offerService: OfferService,
    private engagementService: EngagementService,
    public userService: UserService,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    this.getDashboardData();
    this.orgHandle = localStorage.getItem("orgHandle");
  }

  getDashboardData() {
    this.loader.start();
    let offerPayload = {
      limit: "",
      emailId: this.currentUser,
      role: this.authRole,
      filter: this.filterObj,
      sort: this.sortObj
    }
    this.offerService.getAllDashboard(offerPayload).subscribe(
      res => {
        // console.log(res);
        this.dashboardData = res.body
        this.loader.stop();
        this.getEngagmemtData();
        // console.log(this.dashboardData);
      },
      err => {
        this.loader.stop();
      }
    );
  }

  getEngagmemtData() {
    this.loader.start();
    this.engagementService
      .getEngagementFeedbackTable("")
      .subscribe(
        res => {
          this.engagmentData = res.body;
          this.loader.stop();
          // console.log(this.engagmentData);
          this.checkTotalEngagment();
        },
        error => {
          this.loader.stop();
          // this.toaster.error('Somthing went wrong');
        }
      );
  }

  offerStatusCheck(x){
    switch (x) {
      case 'JND':
        return 'Joined';
        break;
      case 'WDN':
        return 'Not Engaged';
        break;
      case 'EEX':
        return 'DropOut';
        break
      default:
        return 'Engaging';
        break;
    }
  }

  appendEngagmentFeedback() {
    console.log(this.engagmentData, this.engagementOffer);
    this.engagementOffer.forEach((x, index) => {
      this.engagmentData.forEach(e => {
        if(x.offer_id == e.offerId && x.groupName == e.groupName){
          // console.log(x, index);
          if('eafeedbackData' in e && e.eafeedbackData.interventionSummary && x.activityStatus == "Completed"){
            // console.log(e.eafeedbackData.interventionSummary, index);
            this.engagementOffer[index]['interventionSummary'] = e.eafeedbackData.interventionSummary;
          }
        }
      })
    });
    // console.log(this.engagmentData);
  }

  async checkforEngagements() {
    console.log(this.preProcessOffer);
    let todayDate = new Date().getTime();
    await this.preProcessOffer.forEach((item, index) => {
      if('Engagement' in item){
        this.dashboardData.forEach(x => {
          if(item.offer_id == x.offerId){
            x.Engagement_data.engagementData.forEach(e => {
              e.items.forEach(y => {
                let activityDate = new Date(y.date).getTime();
                const offer = {
                  ...this.preProcessOffer[index],
                  activity: y.taskName,
                  activityType: y.activityType,
                  dueDate: new Date(y.date).toLocaleDateString("en-IN"),
                  activityStatus: ((y.completed) ? 'Completed' : 'Pending'),
                  duedateDays: (!y.completed && activityDate <= todayDate ) && Math.round((todayDate-activityDate)/ (1000 * 3600 * 24)),
                  groupName: y.code
                }
                this.engagementOffer.push(offer)
              })
            })
          }
        })
      } else {
        const offer = {
          ...this.preProcessOffer[index]
        }
        this.engagementOffer.push(offer);
      }
    })
    await this.appendEngagmentFeedback();
    console.log(this.engagementOffer);
  }

  checkTotalEngagment(){
    console.log(this.dashboardData, this.engagmentData);
    let todayDate = new Date().getTime();
    this.dashboardData.forEach(async (item, index) => {
      let joiningDate = new Date(item.offerMetaData.joiningDate).getTime();
      let revisedDate = new Date(item.offerMetaData.expiryDate).getTime();
      const Offer = {
        offer_id: item.offerId,
        candidateName: item.candidateData.firstName+' '+item.candidateData.lastName,
        recruiter: this.userService.getUserName(item.organizationData.recruiter),
        offerDate: (item.offerMetaData.offerDate) ? new Date(item.offerMetaData.offerDate).toLocaleDateString("en-IN") : '-',
        initialDOJ: (item.offerMetaData.joiningDate) ? new Date(item.offerMetaData.joiningDate).toLocaleDateString("en-IN") : '-',
        RevisedDOJ: (item.offerMetaData.expiryDate) ? new Date(item.offerMetaData.expiryDate).toLocaleDateString("en-IN") : '-',
        engagementStartDate: new Date(item.createdAt).toLocaleDateString("en-IN"),
        engagmentPeriod: Math.ceil( Math.abs((new Date(item.createdAt).getTime()-new Date((item.offerMetaData.expiryDate) ? item.offerMetaData.expiryDate : item.offerMetaData.joiningDate).getTime())/ (1000*60*60*24)))+' days',
        remainingJoiningDate: ((revisedDate) ? ( (revisedDate > todayDate) ? Math.round((revisedDate-todayDate)/ (1000 * 3600 * 24))+' days' : '-' ): (joiningDate > todayDate) ? Math.round((joiningDate-todayDate)/ (1000 * 3600 * 24))+' days' : '-'),
        candidateStatus: this.offerStatusCheck(item.latestStatus.statusCode)
      }
      if('Engagement_data' in item){
        // await item.Engagement_data.engagementData.forEach(e => {
        //   e.items.forEach(x => {
        //     Offer['activity'] = x.taskName;
        //     Offer['activityType'] = x.activityType;
        //     Offer['activityStatus'] = (x.completed) ? 'Completed' : 'Pending';
        //   })
        // })
        // console.log("there", index, item.Engagement_data.engagementData);
        Offer['Engagement'] = true;
      }
      this.preProcessOffer.push(Offer);
    })
    this.checkforEngagements();
  }

  navigateBack() {
    this.location.back();
  }

  exportToXLSX() {
    this.excelService.exportAsExcelFile(this.engagementOffer, this.orgHandle);
  }

}
