import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { OfferService } from "./../../services/offer/offer.service";
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';

@Component({
  selector: 'app-joinersand-dropout',
  templateUrl: './joinersand-dropout.component.html',
  styleUrls: ['./joinersand-dropout.component.scss']
})
export class JoinersandDropoutComponent implements OnInit {
  routeName: any;
  offers: any;
  tableName: any;
  recuriterData: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private offerService: OfferService,
    private toaster: ToastrService,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.routeName = params.roterName;
      console.log(this.routeName);
    })

    this.getAllOffers(1);
  }

  getAllOffers(pageNumber, lastId?) {
    this.loader.start();
    let offerPayload = {
      pageNumber,
      lastId: "",
      limit: "",
      emailId: "",
      role: "",
      filter: "",
      sort: ""
    }
    this.offerService.getAllOffers(offerPayload).subscribe(
      res => {
        console.log(res);
        this.offers = [];
        this.offers = res.body;
        if(this.routeName == 'recruiterWise'){
          this.tableName = 'Recruiter Name';
          this.getAllOffersBasedOnRecuiters();
        } else {
          this.tableName = 'Monthwise';
          this.getAllOffersBasedOnMothwise();
          console.log('good');
        }
        this.loader.stop();
      },
      err => {
        this.toaster.error("No Data Found.");
        this.loader.stop();
      }
    );
  }

  // Get Joined and Dropout based on the recuiter name
  getAllOffersBasedOnRecuiters() {
    this.offers.forEach(item => {
      let totalCandidatesJoined = false;
      let totalCanidateDropout = false;

      if(item.latestStatus.statusCode == 'JND'){
        totalCandidatesJoined = true;
      } else if(item.latestStatus.statusCode == 'EEX') {
        totalCanidateDropout = true;
      }

      if(this.recuriterData.length <= 0) {
        this.recuriterData.push({recuriterName: item.organizationData.recruiter, totalCandidatesJoined: ((totalCandidatesJoined) ? 1 : 0), totalCanidateDropout: ((totalCanidateDropout) ? 1 : 0 )})
      } else {
        const index = this.recuriterData.findIndex(Object => Object.recuriterName === item.organizationData.recruiter);

        if(index === -1) {
          this.recuriterData.push({recuriterName: item.organizationData.recruiter, totalCandidatesJoined: ((totalCandidatesJoined) ? 1 : 0), totalCanidateDropout: ((totalCanidateDropout) ? 1 : 0 )})
        } else {
          this.recuriterData.forEach((element, index) => {
            if(element.recuriterName === item.organizationData.recruiter){
              if(totalCandidatesJoined){
                element.totalCandidatesJoined++;
              } else if(totalCanidateDropout) {
                element.totalCanidateDropout++;
              }
            }
          });
        }
      }
    });
    // console.log(this.recuriterData);
  }

  getAllOffersBasedOnMothwise() {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    this.offers.forEach(item => {
      let JoiningDate = (item.offerMetaData.expiryDate) ? item.offerMetaData.expiryDate : item.offerMetaData.joiningDate;
      let monthAndYear = month[new Date(JoiningDate).getMonth()]+' '+new Date(JoiningDate).getFullYear();
      let checkDateValid = monthAndYear.split(" ");
      let totalCandidatesJoined = false;
      let totalCanidateDropout = false;

      console.log(checkDateValid[1]);

      if(item.latestStatus.statusCode == 'JND'){
        totalCandidatesJoined = true;
      } else if(item.latestStatus.statusCode == 'EEX') {
        totalCanidateDropout = true;
      }

      if(this.recuriterData.length <= 0 && (checkDateValid[0] != 'undefined')) {
        this.recuriterData.push({mothAndYear: monthAndYear, totalCandidatesJoined: ((totalCandidatesJoined) ? 1 : 0), totalCanidateDropout: ((totalCanidateDropout) ? 1 : 0 )})
      } else {
        const index = this.recuriterData.findIndex(Object => Object.mothAndYear === monthAndYear);

        if(index === -1 && (checkDateValid[0] != 'undefined')){
          this.recuriterData.push({mothAndYear: monthAndYear, totalCandidatesJoined: ((totalCandidatesJoined) ? 1 : 0), totalCanidateDropout: ((totalCanidateDropout) ? 1 : 0)})
        } else {
          this.recuriterData.forEach((element, index) => {
            if(element.mothAndYear === monthAndYear){
              if(totalCandidatesJoined){
                element.totalCandidatesJoined++;
              } else if(totalCanidateDropout) {
                element.totalCanidateDropout++;
              }
            }
          });
        }
      }
      // console.log(new Date(item.offerMetaData.joiningDate).getMonth(), new Date(item.offerMetaData.joiningDate).getFullYear());
    })
    console.log(this.recuriterData);
    this.recuriterData.sort((a, b) => {
      let x = a.mothAndYear.split(" ");
      let y = b.mothAndYear.split(" ");
      if(x[1] !== y[1]){
        console.log("here >>>", a.year - b.year)
        return x[1] - y[1];
      }else{
        return month.indexOf(x[0]) - month.indexOf(y[0]);
      }
    });
    console.log(this.recuriterData);
  }

//   sorter(a, b) {
//     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//     console.log(a, b);
//     // let a = a.split()
//     // if(a.year !== b.year){
//     //   console.log("here >>>", a.year - b.year)
//     //   return a.year - b.year;
//     // }else{
//     //   return months.indexOf(a.month) - months.indexOf(b.month);
//     // };
//  };

  navigateBack() {
    this.location.back();
  }



}
