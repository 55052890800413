import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-candidate-engage-modal',
  templateUrl: './candidate-engage-modal.component.html',
  styleUrls: ['./candidate-engage-modal.component.scss']
})
export class CandidateEngageModalComponent implements OnInit {
  @Input() public candidateDataFromModal;
  @Input() public action;
  @Output() editEngagementResponse = new EventEmitter();
  offerData: any;
  actionData: any;
  public show: boolean;
  templateId: any = "";
  previouslySelectedTemplate: any = "";

  constructor(public activeModal: NgbActiveModal) {
    this.show = true;
  }
  ngOnInit() {
    this.offerData = this.candidateDataFromModal;
    this.actionData = this.action;  //action for start or edit
    this.show = this.actionData === 'edit' ? false : true;
  }

  onChange(event) {
    this.editEngagementResponse.emit(event);
  }

  public onNextButtonClick(value): void {
    this.show = value;
  }

  public handleBackButtonFromEngagement(event) {
    this.previouslySelectedTemplate = event;
    this.show = event ? true : false;
  }
  public handleSelectedTemplate(templateId) {
    this.templateId = templateId;
  }
}
