import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { apis } from '../../../app-settings/aws-config';
import { InitRequests } from '../../models/requestModel';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private candidateApiName: string;
  private candidatePath: string;
  orgHandle: string;
  constructor(private initRequests: InitRequests, private auth: AuthService) {
    this.candidateApiName = apis.hireloopService.hireloop.name; // candidate endpoints
    this.candidatePath = apis.hireloopService.hireloop.endpoints.organization; // canidate endpoints
  }

  // get notifications
  public getOrgDetails(orgHandleCode) {
    const request = this.initRequests.initGetOrgDetails({
      _action: 'GET',
      queryBody: {
        orgHandle: orgHandleCode
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }

  public getOrgConfigDetails(orgHandleCode) {
    const request = this.initRequests.initGetOrgConfigDetails({
      _action: 'GET_FOR_ORG',
      queryBody: {
        orgHandle: orgHandleCode
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }

  public updateOrgConfigDetails(orgHandleCode, data) {
    const request = this.initRequests.initUpdateOrgConfigDetails({
      _action: 'UPDATE_ORG',
      updateBody: {
        orgHandle: orgHandleCode,
        body: data
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }

  uploadOrgConfigData(type, format, data) {
    const request = this.initRequests.inituploadOrgConfigData({
      _action: 'UPLOAD',
      uploadBody: {
        dataType: type,
        fileFormat: format,
        fileData: data
      }
    });
    return from(this.auth.post(this.candidateApiName, this.candidatePath, request));
  }
}
