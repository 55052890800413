import {
  MasterTypes,
  templateCodes
} from "./../../../../app-settings/master_types";
import { SharedService } from "./../../../services/shared/shared.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class GenerateOfferServiceService {
  offerActivityMaster: any;
  offerStatusmaster: any;
  orgEntitiesMaster: any;
  locations: any;
  roles: any;
  offerStatus: any[];
  resumeSources: any;
  constructor(private shared: SharedService) {
    this.orgEntitiesMaster = [];
    this.offerActivityMaster = [];
    this.offerStatusmaster = [];
    this.locations = [];
    this.roles = [];
    this.offerStatus = [];
    this.resumeSources = [];
  }

  //get masters for offer page

  public getMasters(callback) {
    this.shared
      .getMasterAccToType(MasterTypes.offerActivity)
      .subscribe((res: any) => {
        this.offerActivityMaster = res.body[0].data;
        this.shared
          .getMasterAccToType(MasterTypes.orgEntities)
          .subscribe((res: any) => {
            this.orgEntitiesMaster = res.body[0].data;
            this.shared
              .getMasterAccToType(MasterTypes.locations)
              .subscribe((res: any) => {
                this.locations = res.body[0].data;
                this.shared
                  .getMasterAccToType(MasterTypes.orgRoles)
                  .subscribe((res: any) => {
                    this.roles = res.body[0].data;
                    this.shared
                      .getMasterAccToType(MasterTypes.resumeSources)
                      .subscribe((res: any) => {
                        this.resumeSources = res.body[0].data;
                        this.shared
                          .getMasterAccToType(MasterTypes.offerStatus)
                          .subscribe((res: any) => {
                            this.offerStatus = res.body[0].data;
                            return callback({
                              activity: this.offerActivityMaster,
                              entities: this.orgEntitiesMaster,
                              locations: this.locations,
                              roles: this.roles,
                              resumeSources: this.resumeSources,
                              offerStatus: this.offerStatus
                            });
                          });
                      });
                  });
              });
          });
      });
  }

  getOfferTemplate(callback) {
    this.shared.getMailTemplates(templateCodes.offerTemplate).subscribe(res => {
      callback(res.body);
    });
  }
  getRevisedOfferTemplate(callback) {
    this.shared.getMailTemplates(templateCodes.revisedOffer).subscribe(res => {
      callback(res.body);
    });
  }
}
